import {
  useGetCheckoutDataQuery,
  useCreateCycleToWorkQuoteMutation,
  useGetQuotePageDataQuery,
  // Supplier
  Bike,
  BrochureGroup,
} from "../../../graphqGenaretedTypes"
import { FormikValue, initialValues } from "./initialValues"
import { useParams } from "react-router"
import { BreadcrumbsType } from "../../shared/CustomBreadcrumbs"
import { useTranslation } from "react-i18next"

export const CreateCycleToWorkQuote = (formikData: FormikValue) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams()

  const bikesAmount = formikData.bikes.reduce((total, current) => {
    if (current.bikeValue && Number(current.bikeValue) > 0) {
      return (total += Number(current.bikeValue))
    }
    return (total += 0)
  }, 0)

  const bikeQuoteAmount = Number(
    (bikesAmount + Number(formikData.totalAccessoriesValue)).toFixed(2)
  )

  function getBikeQuoteDescription() {
    // if typeOfBike has no value, that is an accessories only order
    if (formikData.bikes.every((bike) => !bike.typeOfBike)) {
      return "Accessories & Extras"
    } else {
      return formikData.bikes.reduce((bikeString, current) => {
        return (bikeString +=
          current.brandOfBike + ", " + current.typeOfBike + "\n")
      }, "")
    }
  }

  const bikeQuoteDescription = getBikeQuoteDescription()

  const bikes = formikData.bikes
    .map((bike) => {
      if (bike.bikeValue && bike.typeOfBike) {
        return {
          brand: bike.brandOfBike ?? "",
          description: String(bike.descriptionOfBike),
          value: Number(bike.bikeValue),
          bikeType: String(bike.typeOfBike),
        } as Bike
      }
      return undefined
    })
    .filter((bike): bike is Bike => bike !== undefined)

  // ugly hack so that there's no need to deal with bikes is possibly undefined
  const bikesToSubmit = bikes.every((bike) => bike === undefined)
    ? undefined
    : bikes

  const termsAcknowledged =
    formikData.checkboxes.filter(
      (checkbox) => checkbox.name === "termsAcknowledged"
    )[0]?.checked || null

  const salarySacrificeArrangementAcknowledged =
    formikData.checkboxes.filter(
      (checkbox) => checkbox.name === "salarySacrificeArrangementAcknowledged"
    )[0]?.checked || null

  const hireAgreementAcknowledged =
    formikData.checkboxes.filter(
      (checkbox) => checkbox.name === "hireAgreementAcknowledged"
    )[0]?.checked || null

  const niNumber = formikData.niNumber?.toUpperCase() ?? ""
  const postCode = formikData.postCode?.toUpperCase() ?? ""

  return useCreateCycleToWorkQuoteMutation({
    variables: {
      relativeUrl: params.schemeType ?? "",
      organisationId: params.organisationId ?? "",
      brochureGroupId: formikData.brochureGroup,
      bikeQuoteAmount: bikeQuoteAmount,
      bikeQuoteDescription: bikeQuoteDescription,
      bikes: bikesToSubmit,
      dateOfBirth: new Date(formikData.dateOfBirth).toDateString(),
      deliveryAddress1: formikData.lineOne,
      deliveryAddress2: formikData.lineTwo,
      deliveryCounty: formikData.county,
      deliveryPostcode: postCode,
      deliveryTown: formikData.town,
      documents: formikData.documents,
      email: formikData.email,
      employeeNumber: formikData.employeeNumber,
      firstName: formikData.firstName,
      hireAgreementAcknowledged: hireAgreementAcknowledged,
      lastName: formikData.lastName,
      mobileNumber: formikData.mobileNumber,
      niNumber: niNumber,
      placeOfWork: formikData.placeOfWork,
      salarySacrificeArrangementAcknowledged:
        salarySacrificeArrangementAcknowledged,
      supplierId: formikData.bikeShop.supplierId,
      telephoneNumber: formikData.phoneNumber,
      termsAcknowledged: termsAcknowledged,
      title: formikData.title,
    },
  })
}

let state = JSON.parse(JSON.stringify(initialValues))

export const useDefaultUserData: () => {
  userData: FormikValue
} = function () {
  const params = useParams()
  const { i18n } = useTranslation()
  const userData = useGetCheckoutDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: params.schemeType ?? "",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })
  if (!userData.loading) {
    const placeOfWork = userData.data?.currentUser?.placeOfWork ?? ""
    const employeeNumber =
      userData.data?.employeeOrganisation?.employee?.employeeNumber ?? ""
    const niNumber = userData.data?.currentUser?.niNumber ?? ""
    const title = userData.data?.currentUser?.title ?? ""
    // shouldn't fill a default value?
    const dateOfBirth = userData.data?.currentUser?.dateOfBirth ?? ""
    const firstName = userData.data?.currentUser?.firstName ?? ""
    const lastName = userData.data?.currentUser?.lastName ?? ""
    const mobileNumber = userData.data?.currentUser?.mobileNumber ?? ""
    const phoneNumber = userData.data?.currentUser?.homePhone ?? ""
    const email = userData.data?.currentUser?.email ?? ""
    const loading = userData.loading ?? false
    const lineOne = userData.data?.currentUser?.address1 ?? ""
    const lineTwo = userData.data?.currentUser?.address2 ?? ""
    const town = userData.data?.currentUser?.town ?? ""
    const county = userData.data?.currentUser?.county ?? ""
    const postCode = userData.data?.currentUser?.postcode ?? ""
    let checkboxes = userData.data?.employeeOrganisation?.scheme?.checkboxes
    const quoteLimit = userData.data?.employeeOrganisation?.scheme?.quoteLimit

    checkboxes = checkboxes?.map((checkbox) => {
      return { ...checkbox, checked: false }
    })

    state = {
      ...initialValues,
      placeOfWork,
      employeeNumber,
      niNumber,
      title,
      dateOfBirth,
      firstName,
      lastName,
      mobileNumber,
      phoneNumber,
      email,
      loading,
      lineOne,
      lineTwo,
      town,
      county,
      postCode,
      checkboxes,
      quoteLimit,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    userData: state,
  }
}

// TODO: remove the following later
export const GetDataForQuoteFormFields = () => {
  const { organisationId = "", schemeType = "" } = useParams()

  const variables = {
    organisationId,
    schemeType,
  }

  const skip = organisationId === "" || schemeType === ""

  return useGetQuotePageDataQuery({
    variables,
    skip,
    errorPolicy: "all",
  })
}

type QuotePageBikeSupplier = {
  supplierId: string
  label: string
  bikeShopQuoteInformation: string
}

type FormDataStateType = {
  loading: boolean
  suppliers: QuotePageBikeSupplier[]
  bikeTypes: Bike["bikeType"][]
  brochureGroups: BrochureGroup[]
  breadcrumbs: Array<BreadcrumbsType>
}

// TODO: define some proper init state
let formDataState: FormDataStateType = {
  loading: true,
  suppliers: [],
  bikeTypes: [],
  brochureGroups: [],
  breadcrumbs: [],
}

export const useFormData = () => {
  const params = useParams()
  const { i18n } = useTranslation()
  const formData = useGetQuotePageDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: params.schemeType ?? "",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  if (!formData.loading) {
    const loading = formData.loading ?? false
    const suppliers =
      formData.data?.employeeOrganisation?.scheme?.suppliers ?? []
    const bikeTypes =
      formData?.data?.employeeOrganisation?.scheme?.bikeTypes ?? []
    const brochureGroups: BrochureGroup[] =
      formData?.data?.employeeOrganisation?.scheme?.brochureGroups ?? []
    const breadcrumbs =
      formData?.data?.employeeOrganisation?.scheme?.quotePageBreadcrumbs ?? []

    const reformatSupplier =
      suppliers &&
      suppliers.map(({ id, name, bikeShopQuoteInformation }) => {
        return {
          supplierId: id,
          label: name ?? "",
          bikeShopQuoteInformation: bikeShopQuoteInformation ?? "",
        }
      })

    formDataState = {
      ...formDataState,
      loading,
      // TODO: deal with type error
      suppliers: reformatSupplier,
      bikeTypes,
      brochureGroups,
      breadcrumbs,
    }
  } else {
    formDataState = {
      ...formDataState,
      loading: true,
    }
  }
  return {
    formData: formDataState,
  }
}
