import { Box, Button, Typography, css, styled } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import Modal from "../../shared/Modal"
import { Basket } from "../../../graphqGenaretedTypes"

type ExceedLimitModal = {
  open: boolean
  handleClose: () => void
  headroom?: number
  limitationMessage: Basket["limitationMessage"]
}

const ExceedLimitModal = ({
  open,
  handleClose,
  headroom,
  limitationMessage,
}: ExceedLimitModal) => {
  const { t, ready } = useTranslation("basket")

  const allowLimit = parseFloat(
    limitationMessage.values?.allowLimit
  ).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  const isNegativeHeadroom = headroom && headroom < 0 ? true : false

  const headroomValue =
    headroom && headroom > 0
      ? headroom.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "0"

  if (!ready) {
    return null
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      sx={{
        ".modal-close-window-button": {
          fontSize: {
            xs: "0.75rem",
            md: "0.875rem",
          },
        },
        "& #modal-main-box": {
          width: {
            md: "34.8125rem",
          },
        },
        "& #close-window-btn": {
          marginBottom: "0.75rem",
        },
      }}
    >
      <>
        <Header>{t("basket.exceedLimitModal.header")}</Header>
        <Wrapper>
          {limitationMessage.translation ===
            "totalBenefitOrderLimitExceeded" && (
            <PoundLimitExceed
              handleClose={handleClose}
              headroom={headroomValue}
              allowLimit={allowLimit}
              isNegativeHeadroom={isNegativeHeadroom}
            />
          )}
          {/* both exceeded case returns this too */}
          {limitationMessage.translation === "benefitActiveOrdersExceeded" && (
            <CountLimitExceeded
              countLimit={limitationMessage.values.countLimit}
            />
          )}
        </Wrapper>
      </>
    </Modal>
  )
}

export default ExceedLimitModal
import { useNavigate } from "react-router-dom"
const CountLimitExceeded = ({ countLimit }: { countLimit: number }) => {
  const { t, ready } = useTranslation("basket")
  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/users/1/orders")
  }
  if (!ready) {
    return null
  }
  return (
    <>
      <Paragraph>
        <Trans
          i18nKey="basket:basket.exceedLimitModal.benefitActiveOrdersExceeded.paraOne"
          values={{
            countLimit,
            orderText: countLimit === 1 ? "order" : "orders",
          }}
        />
      </Paragraph>
      <Paragraph>
        <Trans
          i18nKey="basket:basket.exceedLimitModal.benefitActiveOrdersExceeded.paraTwo"
          components={{
            red: (
              <Box
                component="span"
                sx={(theme) => ({
                  color: theme.palette.error.main,
                })}
              />
            ),
          }}
        />
      </Paragraph>
      <AmendButton variant="gradient" onClick={handleClick}>
        {t(
          "basket.exceedLimitModal.benefitActiveOrdersExceeded.viewActiveOrder"
        )}
      </AmendButton>
    </>
  )
}

const PoundLimitExceed = ({
  handleClose,
  headroom,
  allowLimit,
  isNegativeHeadroom,
}: {
  handleClose: () => void
  headroom: string
  allowLimit: string
  isNegativeHeadroom: boolean
}) => {
  const { t, ready } = useTranslation("basket")

  if (!ready) {
    return null
  }
  return (
    <>
      <Paragraph>
        <Trans
          i18nKey={
            "basket:basket.exceedLimitModal.totalBenefitOrderLimitExceeded.paraOne"
          }
          values={{ valueLimit: allowLimit }}
        />
      </Paragraph>
      <Paragraph>
        <Trans
          i18nKey={
            "basket:basket.exceedLimitModal.totalBenefitOrderLimitExceeded.paraTwo"
          }
          values={{ headroom }}
          components={
            isNegativeHeadroom
              ? { coloredText: <RedText /> }
              : { coloredText: <GreenText /> }
          }
        />
      </Paragraph>
      <Paragraph>
        {t("basket.exceedLimitModal.totalBenefitOrderLimitExceeded.paraThree")}
      </Paragraph>
      <AmendButton variant="gradient" onClick={handleClose}>
        {t(
          "basket.exceedLimitModal.totalBenefitOrderLimitExceeded.amendBasket"
        )}
      </AmendButton>
      <FootNote>
        {t("basket.exceedLimitModal.totalBenefitOrderLimitExceeded.footNote")}
      </FootNote>
    </>
  )
}

const Wrapper = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    margin: 0 auto;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      width: 90%;
    }
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 85%; // countLimit model use 90%, need to sort out the diff
    }
  `}
`

const Header = styled(Typography)`
  ${({ theme }) => css`
    font-size: 1.75rem;
    line-height: 1.75rem;
    text-align: center;
    color: ${theme.palette.primary.main};
    font-weight: bold;
    margin-bottom: 2rem;
  `}
`

const Paragraph = styled(Typography)`
  ${({ theme }) => css`
    font-size: 1rem;
    color: ${theme.palette.primary.main};
    margin-bottom: 2rem;
    text-align: left;
  `}
`

const GreenText = styled("span")`
  ${({ theme }) => css`
    color: ${theme.palette.success.main};
    font-weight: bold;
  `}
`

const RedText = styled("span")`
  ${({ theme }) => css`
    color: ${theme.palette.error.main};
    font-weight: bold;
  `}
`

const AmendButton = styled(Button)`
  margin: 0 auto 2rem auto;
  width: 100%;
  max-width: 280px;
  display: block;
`

const FootNote = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 0.75rem;
    line-height: 0.875rem;
    margin-bottom: 2rem;
    text-align: left;
  `}
`
