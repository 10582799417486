import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import FooterLogoSection from "./sections/FooterLogoSection"
import FooterLowerSection from "./sections/FooterLowerSection"
import FooterLinksSection from "./sections/FooterLinksSection"
import { useTheme } from "@mui/material/styles"

const Footer = ({
  privacyNoticeUrl,
  setShouldShowCookiePopup,
  organisationPrivacyNotice = false,
  logo,
  isLockBenefit = false,
  isValidBenefitType = false,
  showAccessibility,
  showContactUs,
  showCookiePolicy,
  showManageCookies,
  showOrganisationPrivacyNotice,
  showPrivacyPolicy,
  showPromotionalTermsAndConditions,
  showTermsAndConditions,
  showVivupPrivacyNotice,
  customFooterLinks,
}: {
  privacyNoticeUrl?: string
  organisationPrivacyNotice?: boolean
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
  logo?: string
  isLockBenefit?: boolean
  isValidBenefitType?: boolean
  showAccessibility?: boolean
  showContactUs?: boolean
  showCookiePolicy?: boolean
  showManageCookies?: boolean
  showOrganisationPrivacyNotice?: boolean
  showPrivacyPolicy?: boolean
  showPromotionalTermsAndConditions?: boolean
  showTermsAndConditions?: boolean
  showVivupPrivacyNotice?: boolean
  customFooterLinks?: {
    url: string
    label: string
    id: string
  }[]
}) => {
  const theme = useTheme()
  if (
    navigator.userAgent.includes("wv") ||
    navigator.userAgent.includes("WebView") ||
    (navigator.userAgent.includes("AppleWebKit") &&
      !navigator.userAgent.includes("Safari"))
  ) {
    return null
  } else {
    return (
      <footer>
        <Box
          className="Footer"
          sx={{
            backgroundColor: theme.palette.primary.main,
            paddingTop: "7px",
            paddingBottom: "7px",
            paddingLeft: { xs: 2, sm: 5, md: 2 },
            paddingRight: { xs: 2, sm: 5, md: 2 },
            color: theme.palette.white.main,
          }}
          data-cy="footer"
        >
          <Container>
            <Grid
              item
              container
              xs={12}
              lg={10}
              sx={{
                marginX: "auto",
                width: "90%",
              }}
            >
              <FooterLogoSection
                logo={logo}
                isLockBenefit={isLockBenefit}
                isValidBenefitType={isValidBenefitType}
              />

              <FooterLinksSection
                privacyNoticeUrl={privacyNoticeUrl}
                organisationPrivacyNotice={organisationPrivacyNotice}
                setShouldShowCookiePopup={setShouldShowCookiePopup}
                showAccessibility={showAccessibility}
                showContactUs={showContactUs}
                showCookiePolicy={showCookiePolicy}
                showManageCookies={showManageCookies}
                showOrganisationPrivacyNotice={showOrganisationPrivacyNotice}
                showPrivacyPolicy={showPrivacyPolicy}
                showPromotionalTermsAndConditions={
                  showPromotionalTermsAndConditions
                }
                showTermsAndConditions={showTermsAndConditions}
                showVivupPrivacyNotice={showVivupPrivacyNotice}
                customFooterLinks={customFooterLinks}
              />

              <FooterLowerSection />
            </Grid>
          </Container>
        </Box>
      </footer>
    )
  }
}

export default Footer
