import { useSearchParams } from "react-router-dom"
import CheckboxItem from "../../store/filters/CheckboxItem"
import updatedSearchParams from "../../../utils/updatedSearchParams"
import { useState } from "react"
import { Button } from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"

type CheckboxItemType = {
  index: number
  id: string
  name: string
  numberOfDiscounts: number
}

export type CheckboxListProps = {
  items: CheckboxItemType[]
  paramName: string
  initialExpandedState?: boolean
  showCounts?: boolean
}

export default function Item({
  items,
  paramName,
  initialExpandedState = false,
  showCounts = false,
}: CheckboxListProps) {
  const { t, ready } = useTranslation("lifestyleSavings")
  const [searchParams, setSearchParams] = useSearchParams()
  const [expanded, setExpanded] = useState(initialExpandedState)
  const activeItems = searchParams.getAll(paramName)
  const initialItems = !initialExpandedState ? items.slice(0, 5) : items
  const createdCheckboxItem = (item: CheckboxItemType, index: number) => (
    <CheckboxItem
      text={showCounts ? `${item.name} (${item.numberOfDiscounts})` : item.name}
      value={item.id}
      checked={activeItems.includes(item.id)}
      key={index}
      isLifestylingStore={true}
      originalText={item.name}
      onChange={(state, value) => {
        if (state === true) {
          setSearchParams(
            updatedSearchParams(searchParams, [
              { paramName, value: [value, ...activeItems] },
            ])
          )
        } else {
          setSearchParams(
            updatedSearchParams(searchParams, [
              {
                paramName,
                value: activeItems.filter((item) => item !== value),
              },
            ])
          )
        }
      }}
    />
  )

  if (!ready) {
    return null
  }

  return (
    <div style={{ marginBottom: 0 }}>
      {initialItems.map((item: CheckboxItemType, index: number) =>
        createdCheckboxItem(item, index)
      )}
      {!initialExpandedState && (
        <>
          {items.length > 5 && expanded ? (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              transition={{
                type: "spring",
                bounce: 0,
                duration: 0.3,
              }}
            >
              {items
                .slice(5)
                .map((item: CheckboxItemType, index: number) =>
                  createdCheckboxItem(item, index)
                )}
            </motion.div>
          ) : (
            <>
              {items.length > 5 && (
                <div className="more-button-container">
                  <Button
                    color="secondary"
                    onClick={() => setExpanded(true)}
                    sx={{ ":hover": { backgroundColor: "inherit" } }}
                  >
                    <ArrowDropDownIcon />
                    {t("store.filters.more")}
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
