import React, { KeyboardEvent, useEffect, useRef, useState } from "react"
import classNames from "classnames"

import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos"
import { gtmNavigationClick } from "../gtm-events/CustomGtmEvents"
import { Link } from "@mui/material"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

type SubcategoryInformation = {
  id: string
  name: string
  link: string
}

type CategoryInformation = {
  id: string
  name: string
  link: string
  subcategories: ReadonlyArray<SubcategoryInformation>
}

export type MegaMenuTabProps = {
  name: string
  link: string
  categories: ReadonlyArray<CategoryInformation>
  imageUrl: string
  active?: boolean
  setActive: (tabActive: boolean) => void
}

function MegaMenuTab({
  name,
  link,
  categories,
  imageUrl,
  active = false,
  setActive,
}: MegaMenuTabProps) {
  const [activeCategories, setActiveCategories] = useState(
    Array(Object.keys(categories).length).fill(false)
  )
  const categoryRefs = useRef<(HTMLAnchorElement | null)[]>([])
  const subcategoryRefs = useRef<(HTMLAnchorElement | null)[]>([])
  const { t, ready } = useTranslation("layout")

  useEffect(() => {
    categoryRefs.current = categoryRefs.current.slice(
      0,
      Object.keys(categories).length
    )
    subcategoryRefs.current = subcategoryRefs.current.slice(
      0,
      Object.keys(categories).length
    )
  }, [categories])

  const triggerCategory = (e: KeyboardEvent, index: number) => {
    if (e.key == "ArrowRight") {
      e.preventDefault()
      setActiveCategories(
        activeCategories.map((_, i) => {
          return index === i ? true : false
        })
      )
      subcategoryRefs.current[index]?.focus()
    } else if (e.key == "ArrowLeft") {
      e.preventDefault()
      setActiveCategories(Array(Object.keys(categories).length).fill(false))
    }
  }

  const resetRef = (e: KeyboardEvent, index: number) => {
    if (e.key == "ArrowLeft") {
      e.preventDefault()
      setActiveCategories(Array(Object.keys(categories).length).fill(false))
      categoryRefs.current[index]?.focus()
    }
  }

  const params = useParams()
  const store = params.schemeType

  if (!ready) {
    return null
  }

  return (
    <>
      <div
        className={classNames("mega-menu-tab", { "d-none": !active })}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => {
          setActive(false)
          setActiveCategories(Array(Object.keys(categories).length).fill(false))
        }}
      >
        <div className="mega-menu-tab__categories">
          <Link
            className="mega-menu-tab__category mega-menu-tab__title"
            onMouseEnter={() =>
              setActiveCategories(
                Array(Object.keys(categories).length).fill(false)
              )
            }
            href={link}
            onClick={() => setActive(false)}
          >
            {t("menu.all") + " " + name}
            <ArrowForwardIcon
              fontSize="inherit"
              className="mega-menu-tab__forward-arrow"
              color="inherit"
            />
          </Link>
          {categories.map((category, index) => (
            <React.Fragment key={category["id"]}>
              <Link
                className={classNames("mega-menu-tab__category", {
                  "mega-menu-tab__category--active": activeCategories[index],
                })}
                onMouseEnter={() =>
                  setActiveCategories(
                    activeCategories.map((_, i) => {
                      return index === i ? true : false
                    })
                  )
                }
                ref={(el) => (categoryRefs.current[index] = el)}
                onKeyDown={(e) => triggerCategory(e, index)}
                href={category.link}
                onClick={() => setActive(false)}
              >
                {category["name"]}
                <ArrowForwardIcon
                  fontSize="inherit"
                  className="mega-menu-tab__forward-arrow"
                  color="inherit"
                />
              </Link>
            </React.Fragment>
          ))}
        </div>
        {categories.map((category, index) => (
          <React.Fragment key={category["id"]}>
            <div
              className={classNames("mega-menu-tab__subcategories", {
                "d-none": !activeCategories[index],
              })}
            >
              <Link
                className="mega-menu-tab__subcategory mega-menu-tab__title"
                href={category.link}
                ref={(el) => (subcategoryRefs.current[index] = el)}
                onKeyDown={(e) => resetRef(e, index)}
                sx={{ display: "block" }}
                onClick={() => setActive(false)}
              >
                {t("menu.all") + " " + category["name"]}
              </Link>
              {category["subcategories"].map((subcategory) => (
                <Link
                  key={subcategory["id"]}
                  className="mega-menu-tab__subcategory"
                  href={subcategory.link}
                  onKeyDown={(e) => resetRef(e, index)}
                  onClick={() => {
                    gtmNavigationClick({
                      menu: name,
                      category: category["name"],
                      subcategory: subcategory.name,
                      store: store,
                    })
                    setActive(false)
                  }}
                  sx={{ display: "block" }}
                >
                  {subcategory["name"]}
                </Link>
              ))}
            </div>
          </React.Fragment>
        ))}
        {imageUrl && (
          <img
            src={imageUrl}
            alt="departmentImage"
            className="mega-menu-tab__image"
          />
        )}
      </div>
      <div
        className={classNames("mega-menu-tab__background", {
          "d-none": !active,
        })}
      />
    </>
  )
}

export default MegaMenuTab
