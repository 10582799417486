import { styled, Button, css } from "@mui/material"
import { ExploreButton } from "./../../lifestyle-savings/shared-styles"

import useIsMobileView from "../../../utils/useIsMobileView"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"

const BikeShopHardCodeComponent = ({
  bikeDepartmentId,
}: {
  bikeDepartmentId?: number
}) => {
  const isDesktop = !useIsMobileView()
  const params = useParams()
  const { t, ready } = useTranslation("bikeShop")
  const { i18n } = useTranslation()
  const your_cycle_to_work_benefits_desktop = useBuildAwsImageUrl(
    i18n.language,
    "your_cycle_to_work_benefits_desktop.png"
  )

  const your_cycle_to_work_benefits_mobile = useBuildAwsImageUrl(
    i18n.language,
    "your_cycle_to_work_benefits_mobile.png"
  )
  const cycleSolutions = useBuildAwsImageUrl(
    i18n.language,
    "cycleToWork/CycleSolutions.png"
  )
  const evansLogo = useBuildAwsImageUrl(
    i18n.language,
    "cycleToWork/EvansLogo.png"
  )
  const manWithHelmetDesktop = useBuildAwsImageUrl(
    i18n.language,
    "cycleToWork/ManWithHelmetDesktop.png"
  )
  const manWithHelmetMobile = useBuildAwsImageUrl(
    i18n.language,
    "cycleToWork/ManWithHelmetMobile.png"
  )

  if (!ready) {
    return null
  }
  return (
    <div>
      <Container>
        <div>
          <HeadingText>{t("landing.mainHeader")}</HeadingText>
          <TextContainer>
            <Paragraph>{t("landing.firstPara")}</Paragraph>
            <Paragraph>{t("landing.secondPara")}</Paragraph>
            <Paragraph>{t("landing.thirdPara")}</Paragraph>
            <Paragraph>{t("landing.fourthPara")}</Paragraph>
          </TextContainer>
          <ButtonContainer>
            <ButtonStyle
              href={`/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/departments/${bikeDepartmentId}`}
            >
              {t("landing.browseBikes")}
            </ButtonStyle>
          </ButtonContainer>
        </div>
        <PictureContainer>
          <CompanyLogoContainer>
            <img
              src={evansLogo}
              alt={t("landing.evansLogo")}
              height="89px"
              width="145px"
              style={{ marginRight: "16px" }}
            />
            <img
              src={cycleSolutions}
              alt={t("landing.cycleSolutions")}
              height="89px"
              width="145px"
            />
          </CompanyLogoContainer>
          <div style={{ textAlign: "center" }}>
            <ImageContainer
              src={isDesktop ? manWithHelmetDesktop : manWithHelmetMobile}
              alt=""
            />
          </div>
        </PictureContainer>
      </Container>
      <div style={{ textAlign: "center" }}>
        <CycleToWorkBenifitBannerLink
          href={`/organisations/${params.organisationId}/employee/benefits/cycle_to_work/landing`}
        >
          <CycleToWorkImage
            alt={t("landing.mainImage")}
            src={
              isDesktop
                ? your_cycle_to_work_benefits_desktop
                : your_cycle_to_work_benefits_mobile
            }
          />
        </CycleToWorkBenifitBannerLink>
      </div>
    </div>
  )
}

const Container = styled("div")`
  ${({ theme }) => css`
    display: flex;
    max-width: 1043px;
    margin: 0 auto;
    justify-content: center;
    column-gap: 2.93rem;
    margin-top: 56px;
    padding: 1rem;

    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      flex-direction: column-reverse;
    }
  `}
`

const HeadingText = styled("p")`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-family: ${theme.typography.fontFamily};
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      text-align: center;
      padding: 0px 19px;
      font-family: "Raleway";
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
    }
  `}
`

const TextContainer = styled("div")`
  ${({ theme }) => css`
    font-size: 16px;
    color: ${theme.palette.primary.main};
    margin-bottom: 35px;
    font-weight: 500;
    font-style: normal;
    font-family: "Raleway", sans-serif;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      text-align: center;
      font-size: 14px;
      padding: 0px 19px;
    }
  `}
`

const Paragraph = styled("p")`
  margin-bottom: 15px;
`

const ButtonContainer = styled("div")`
  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      display: flex;
      justify-content: center;
    }
  `}
`

export const ButtonStyle = styled(ExploreButton)`
  ${({ theme }) => css`
    && {
      background: transparent
        linear-gradient(90deg, var(--tertiary) 0%, var(--primary) 100%) 0% 0%
        no-repeat padding-box;
      background: transparent linear-gradient(90deg, #92237f 0%, #35185f 100%)
        0% 0% no-repeat padding-box;
      color: ${theme.palette.white.main};
      display: flex;
      margin: 0rem;
      max-width: 246px;
      width: 100%;
      height: 50px;

      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        max-width: 220px;
        width: 100%;
        text-align: center;
      }

      /* add hover effect */
      &:hover {
        opacity: 0.75;
      }
    }
  `}
` as typeof Button

const PictureContainer = styled("div")`
  ${({ theme }) => css`
    flex: 0 0 55%;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      order: 3;
      display: flex;
      flex-direction: column-reverse;
    }
  `}
`

const CompanyLogoContainer = styled("div")`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    margin-bottom: 33px;

    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-top: 33px;
      margin-bottom: 10px;
    }
  `}
`

export const ImageContainer = styled("img")`
  ${({ theme }) => css`
    max-width: 570px;
    width: 100%;
    height: 380px;
    color: white;
    border-radius: 16px;

    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      max-width: 326px;
      width: 100%;
      height: 217px;
    }
  `}
`

const CycleToWorkBenifitBannerLink = styled(Button)`
  ${({ theme }) => css`
    max-width: 1082px;
    width: 100%;
    margin-top: 94px;
    height: 81px;
    margin-bottom: 49.56px;
    flex: 2 0;
    padding: 0px;

    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin-top: 31px;
      margin-bottom: 46.56px;
    }

    @media screen and (max-width: ${theme.breakpoints.values.lg}px) {
      width: 94.4%;
      overflow: hidden;
      border-radius: 1rem;
    }
  `}
`
const CycleToWorkImage = styled("img")`
  background-position: center;
  border-radius: 1rem;
`

export default BikeShopHardCodeComponent
