import { Grid, useMediaQuery, useTheme, Box, styled } from "@mui/material"
import { SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import { Pagination, Autoplay, Keyboard } from "swiper/modules"
import BannerCarousel, { BannerCarouselProps } from "./BannerCarousel"
import { useParams } from "react-router-dom"
import { StyledSwiper } from "../../portal-homepage/PortalHomePageBanner"

export type BannerCarouselGridProps = {
  banners: BannerCarouselProps[]
  showGrayDots?: boolean
}

export default function BannerCarouselGrid({
  banners,
  showGrayDots,
}: BannerCarouselGridProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const params = useParams()

  const customDotColor =
    params.schemeType === "cycle_to_work" ||
    params.schemeType === "bike_shop" ||
    showGrayDots
      ? "#EAE9EE"
      : ""

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledSwiper
            modules={[Pagination, Keyboard, Autoplay]}
            pagination={{
              clickable: true,
              el: ".custom-pagination",
            }}
            autoplay={{
              delay: 300000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            keyboard={{ enabled: true }}
            spaceBetween={30}
            slidesPerView={1}
            className="carouselBanner"
            style={{
              height: isDesktop ? "13.125rem" : "9.5rem",
            }}
          >
            {banners.map((banner, index) => (
              <SwiperSlide key={index}>
                <BannerCarousel
                  desktopImageUrl={banner.desktopImageUrl}
                  mobileImageUrl={banner.mobileImageUrl}
                  link={banner.link}
                  title={banner.title}
                  subtitle={banner.subtitle}
                />
              </SwiperSlide>
            ))}
          </StyledSwiper>
          <PaginationContainer
            className="custom-pagination"
            sx={{ textAlign: "center" }}
            customDotColor={customDotColor}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export const PaginationContainer = styled("div")<{
  customDotColor?: string
}>`
  margin-top: 0.7rem;
  .swiper-pagination-bullet {
    width: 0.7rem;
    height: 0.7rem;
    background-color: ${(props) => props.customDotColor || "white"};
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: #35158f !important;
  }
`
