import { createContext } from "react"
import { useParams } from "react-router"
import { BasketItem } from "../../../graphqGenaretedTypes"
import { BannerType } from "../BottomBanner"
import { CheckboxType } from "./Checkboxes"
import {
  GetCheckoutDataQueryHookResult,
  useGetCheckoutDataQuery,
  useCreateStoreOrderMutation,
  useGetAddressSuggestionsDataQuery,
  useGetFullAddressDataQuery,
  Maybe,
} from "../../../graphqGenaretedTypes"
import { FormContentType, defaultFormContent } from "./defaultFormContent"
import { useTranslation } from "react-i18next"

export type CheckoutBasketItem = Omit<BasketItem, "brochureGroupId" | "status">

export type CheckoutContextData = {
  monthlyPrice: number
  term: number
  monthlyGrossAmount: number
  products: CheckoutBasketItem[]
  supplierBanner: BannerType
  formContent: FormContentType
  showDateOfBirth: boolean
  checkboxes: CheckboxType[]
  storeName: string
  totalSavings: number
  loading: boolean
  currentActiveOrderValue: number
  percentageLimit?: number
}

const defaultSupplierBanner = {
  desktopBannerUrl: undefined,
  mobileBannerUrl: undefined,
}

const defaultValue: CheckoutContextData = {
  monthlyPrice: 0,
  term: 0,
  monthlyGrossAmount: 0,
  products: [],
  supplierBanner: defaultSupplierBanner,
  formContent: defaultFormContent(),
  showDateOfBirth: true,
  checkboxes: [],
  storeName: "",
  totalSavings: 0,
  loading: true,
  currentActiveOrderValue: 0,
  percentageLimit: undefined,
}

let state = JSON.parse(JSON.stringify(defaultValue))

const queryDataToProducts: (
  queryData: GetCheckoutDataQueryHookResult
) => CheckoutBasketItem[] = function (queryData) {
  let result: CheckoutBasketItem[] = []
  const data = queryData.data

  if (data?.employeeOrganisation?.scheme?.basket?.basketItems) {
    result = data.employeeOrganisation.scheme.basket.basketItems
  }

  return result
}

const queryDataToCheckboxes: (
  queryData: GetCheckoutDataQueryHookResult
) => CheckboxType[] = function (queryData) {
  let result: CheckboxType[] = []
  const data = queryData.data

  if (data?.employeeOrganisation?.scheme?.checkboxes) {
    result = data.employeeOrganisation.scheme.checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: false,
    }))
  }

  return result
}

const queryDataToFormContent: (
  queryData: GetCheckoutDataQueryHookResult,
  percentageLimit: number | undefined | null
) => FormContentType = function (queryData, percentageLimit) {
  let result: FormContentType = defaultFormContent()
  const data = queryData.data

  if (data?.currentUser && data.employeeOrganisation) {
    const user = data.currentUser
    const employee = data.employeeOrganisation.employee
    const detailsFields = defaultFormContent(percentageLimit).detailsFields
    const deliveryFields = defaultFormContent().deliveryFields
    const dateOfBirthValidation = detailsFields.dateOfBirth.validate

    result = {
      detailsFields: {
        placeOfWork: {
          ...detailsFields.placeOfWork,
          value: user.placeOfWork || "",
        },
        ...(detailsFields.yourSalary && {
          yourSalary: {
            ...detailsFields.yourSalary,
            value: "", // there's no salary value from the backend
          },
        }),
        employeeNumber: {
          ...detailsFields.employeeNumber,
          value: employee.employeeNumber || "",
        },
        niNumber: {
          ...detailsFields.niNumber,
          value: user.niNumber || "",
        },
        title: {
          ...detailsFields.title,
          value: user.title || "",
        },
        dateOfBirth: {
          ...detailsFields.dateOfBirth,
          value: user.dateOfBirth || null,
          validate: data.employeeOrganisation.scheme?.showDateOfBirth
            ? dateOfBirthValidation
            : () => ({ isValid: true }),
        },
        firstName: {
          ...detailsFields.firstName,
          value: user.firstName || "",
        },
        lastName: {
          ...detailsFields.lastName,
          value: user.lastName || "",
        },
        mobileNumber: {
          ...detailsFields.mobileNumber,
          value: user.mobileNumber || "",
        },
        phoneNumber: {
          ...detailsFields.phoneNumber,
          value: user.homePhone || "",
        },
        emailAddress: {
          ...detailsFields.emailAddress,
          value: user.email,
        },
      },
      deliveryFields: {
        lineOne: {
          ...deliveryFields.lineOne,
          value: user.address1 || "",
        },
        lineTwo: {
          ...deliveryFields.lineTwo,
          value: user.address2 || "",
        },
        town: {
          ...deliveryFields.town,
          value: user.town || "",
        },
        county: {
          ...deliveryFields.county,
          value: user.county || "",
        },
        postCode: {
          ...deliveryFields.postCode,
          value: user.postcode || "",
        },
      },
    }
  }

  return result
}

export const createStoreOrder = (
  organisationId: string,
  schemeType: string,
  placeOfWork: string,
  employeeNumber: string,
  niNumber: string,
  dateOfBirth: string,
  title: string,
  firstName: string,
  lastName: string,
  telephoneNumber: string,
  mobileNumber: string,
  email: string,
  deliveryAddress1: string,
  deliveryAddress2: string,
  deliveryTown: string,
  deliveryCounty: string,
  deliveryPostcode: string,
  termsAcknowledged: Maybe<boolean>,
  hireAgreementAcknowledged: Maybe<boolean>,
  salarySacrificeArrangementAcknowledged: Maybe<boolean>
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCreateStoreOrderMutation({
    variables: {
      organisationId: organisationId,
      schemeType: schemeType,
      placeOfWork: placeOfWork,
      employeeNumber: employeeNumber,
      niNumber: niNumber,
      title: title,
      dateOfBirth: state.showDateOfBirth ? dateOfBirth : "",
      firstName: firstName,
      lastName: lastName,
      telephoneNumber: telephoneNumber,
      mobileNumber: mobileNumber,
      email: email,
      deliveryAddress1: deliveryAddress1,
      deliveryAddress2: deliveryAddress2,
      deliveryTown: deliveryTown,
      deliveryCounty: deliveryCounty,
      deliveryPostcode: deliveryPostcode,
      termsAcknowledged: termsAcknowledged,
      hireAgreementAcknowledged: hireAgreementAcknowledged,
      salarySacrificeArrangementAcknowledged:
        salarySacrificeArrangementAcknowledged,
    },
  })

// TODO - fix eslint warnings/errors
export const getAddressSuggestions = (searchTerm: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { i18n } = useTranslation()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useGetAddressSuggestionsDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      searchTerm: searchTerm,
      locale: i18n.language,
    },
    skip: searchTerm.length == 0,
    errorPolicy: "all",
  })
}

// TODO - fix eslint warnings/errors
export const getFullAddress = (addressId: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { i18n } = useTranslation()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useGetFullAddressDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      id: addressId,
      locale: i18n.language,
    },
    skip: addressId.length == 0,
    errorPolicy: "all",
  })
}

export const CheckoutContext = createContext(defaultValue)

export const useCheckoutData: () => {
  data: CheckoutContextData
} = function () {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetCheckoutDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: params.schemeType ?? "",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  if (!data.loading) {
    const percentageLimit =
      data.data?.employeeOrganisation?.scheme?.basket?.percentageLimit
    const products = queryDataToProducts(data)
    const formContent = queryDataToFormContent(data, percentageLimit)
    const checkboxes = queryDataToCheckboxes(data)
    const showDateOfBirth =
      data.data?.employeeOrganisation?.scheme?.showDateOfBirth
    const monthlyPrice =
      data.data?.employeeOrganisation?.scheme?.basket?.monthlyPrice || 0
    const term = data.data?.employeeOrganisation?.scheme?.basket?.term || 0
    const monthlyGrossAmount =
      data.data?.employeeOrganisation?.scheme?.basket
        ?.totalMonthlyGrossAmount || 0
    const supplierBanner =
      data.data?.employeeOrganisation?.scheme?.basket?.supplierBanner ||
      defaultSupplierBanner
    const storeName = data.data?.employeeOrganisation?.scheme?.storeName || ""
    const totalSavings =
      data.data?.employeeOrganisation?.scheme?.basket?.totalSavings || 0
    const loading = data.loading

    state = {
      ...defaultValue,
      products,
      formContent,
      showDateOfBirth,
      checkboxes,
      monthlyPrice,
      term,
      monthlyGrossAmount,
      supplierBanner,
      storeName,
      totalSavings,
      percentageLimit,
      loading,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
