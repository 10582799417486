import Footer from "../footer/Footer"
import Header from "../header/Header"
import BottomBanner from "../../store/BottomBanner"
import { StoreLayoutContext, useStoreLayoutData } from "./StoreLayoutContext"
import {
  StoreDepartmentContext,
  useDefaultDepartmentLayoutData,
} from "./StoreDepartmentContext"
import { Outlet, useParams } from "react-router"
import { ThemeProvider } from "@mui/material"
import DefaultTheme from "../../../theme/mui-theme"
import C2WTheme from "../../../theme/mui-c2w-theme"
import { useEffect } from "react"

export type globalHeaderData = {
  supplierBanner?: {
    mobile?: string
    desktop?: string
  }
  loading?: boolean
  setShouldShowCookiePopup: React.Dispatch<React.SetStateAction<boolean>>
}

export const CheckoutStoreLayout = function ({
  supplierBanner,
  setShouldShowCookiePopup,
}: globalHeaderData) {
  const contextValue = useStoreLayoutData(supplierBanner)
  const contextDepartmentValue = useDefaultDepartmentLayoutData()

  const {
    currentUserId,
    logo,
    condensedLogo,
    privacyNoticeUrl,
    organisationPrivacyNotice,
    availableLanguages,
    defaultLanguage,
    signOutLabel,
  } = contextValue
  const params = useParams()

  useEffect(() => {
    const url = location.href
    let subdomain = url.slice(url.indexOf("//") + 2, url.indexOf("."))
    if (["vivup", "www"].includes(subdomain) || url.indexOf(".") === -1) {
      subdomain = "Vivup"
    }

    let sanitisedSchemeType = params.schemeType?.replaceAll("_", " ") || ""
    sanitisedSchemeType =
      sanitisedSchemeType.charAt(0).toUpperCase() + sanitisedSchemeType.slice(1)
    document.title = `${subdomain}: ${sanitisedSchemeType}`
  }, [params.schemeType])

  return (
    <ThemeProvider
      theme={
        ["cycle_to_work", "bike_shop"].includes(params.schemeType || "")
          ? C2WTheme
          : DefaultTheme
      }
    >
      <StoreDepartmentContext.Provider value={contextDepartmentValue}>
        <StoreLayoutContext.Provider value={contextValue}>
          {currentUserId && (
            <>
              <Header
                logo={logo}
                condensedLogo={condensedLogo}
                currentUserId={currentUserId}
                showBasket={true}
                availableLanguages={availableLanguages}
                defaultLanguage={defaultLanguage}
                isLockBenefit={contextValue.isLockBenefit}
                isValidBenefitType={contextValue.isValidBenefitType}
                signOutLabel={signOutLabel}
                showNoticeboard={[]}
              />
              <div id="main-content" className="checkout-main-container">
                <Outlet />
              </div>
              {(contextValue.supplierMobileBanner ||
                contextValue.supplierDesktopBanner) && (
                <BottomBanner
                  mobileBannerUrl={contextValue.supplierMobileBanner}
                  desktopBannerUrl={contextValue.supplierDesktopBanner}
                />
              )}
              <Footer
                organisationPrivacyNotice={organisationPrivacyNotice}
                privacyNoticeUrl={privacyNoticeUrl}
                setShouldShowCookiePopup={setShouldShowCookiePopup}
                showAccessibility={contextValue.showAccessibility}
                showContactUs={contextValue.showContactUs}
                showCookiePolicy={contextValue.showCookiePolicy}
                showManageCookies={contextValue.showManageCookies}
                showOrganisationPrivacyNotice={
                  contextValue.showOrganisationPrivacyNotice
                }
                showPrivacyPolicy={contextValue.showPrivacyPolicy}
                showPromotionalTermsAndConditions={
                  contextValue.showPromotionalTermsAndConditions
                }
                showTermsAndConditions={contextValue.showTermsAndConditions}
                showVivupPrivacyNotice={contextValue.showVivupPrivacyNotice}
                customFooterLinks={contextValue.customFooterLinks}
                logo={contextValue.footerLogo}
              />
            </>
          )}
        </StoreLayoutContext.Provider>
      </StoreDepartmentContext.Provider>
    </ThemeProvider>
  )
}
