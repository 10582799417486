import {
  Box,
  styled,
  Typography,
  Button,
  FormHelperText,
  css,
} from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import { FormikProps } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { NewBenefitTileValue } from "../formikUtils"
import InfoTooltip from "../../shared/InfoTooltip"
import ImageUploadModal from "./ImageUploadModal"
import { SelectedCardProps } from "./StoredImagePopUp"
export type valueProps = {
  imageUrl: string
  size: number | null
}
const UploadTileImage = ({
  formikValue,
  fileUrl,
}: {
  formikValue: FormikProps<NewBenefitTileValue>
  fileUrl?: string
}) => {
  const formik = formikValue
  const { t, ready } = useTranslation("newBenefitTile")
  const [openModal, setOpenModal] = useState(false)
  const [selectedFileUrl, setSelectedFileUrl] = useState<string>(fileUrl ?? "")
  const [selectedStoredFile, setSelectedStoredFile] = useState("")
  // useEffect(() => {
  //   if (formik.isSubmitting) {
  //     setSelectedFileUrl("")
  //     setSelectedStoredFile("")
  //     formik.setFieldValue("document", "")
  //   }
  //   // eslint-disable-next-line
  // }, [formik.isSubmitting])

  const handleDeleteFile = () => {
    setSelectedFileUrl("")
    setSelectedStoredFile("")
    formik.setFieldValue("document", "")
  }

  const handleImageUploadModalOpen = () => {
    setOpenModal(true)
    // setSelectedFileUrl("")
    // setSelectedStoredFile("")
  }
  const handleImageUploadModalClose = () => {
    setOpenModal(false)
  }
  const handleFileSelected = (
    selectedFileUrl: string,
    selectedFile: File | null
  ) => {
    setSelectedStoredFile("")
    setSelectedFileUrl(selectedFileUrl)
    // setSelectedFile(selectedFile)
    formik.setFieldValue("document", selectedFile)
  }
  const handleStoredFileSelected = (selectedCard: SelectedCardProps) => {
    if (selectedCard) {
      const storedValueFromVivupModal = selectedCard.image
      setSelectedStoredFile(storedValueFromVivupModal)
      setSelectedFileUrl("")
    }
  }
  if (!ready) {
    return null
  }

  return (
    <Container>
      <SecondRow>
        {<MainText>{t("benefitImage")}</MainText>}
        {selectedFileUrl && (
          <div>
            <CardImage src={selectedFileUrl} alt="Uploaded File" />
            <StyledCancelIcon
              onClick={handleDeleteFile}
              aria-label="Remove file"
            />
          </div>
        )}
        {selectedStoredFile && (
          <div>
            <CardImage src={selectedStoredFile} alt="Uploaded File" />
            <StyledCancelIcon
              onClick={handleDeleteFile}
              aria-label="Remove file"
            />
          </div>
        )}
        <ButtonAndToolTipContainer>
          <UploadButton onClick={handleImageUploadModalOpen}>
            {selectedFileUrl || selectedStoredFile
              ? "Change file"
              : t("choosefile")}
          </UploadButton>
          {/* </label> */}
          <InfoTooltip content={t("imageTooltip")} />
        </ButtonAndToolTipContainer>
        <div>
          <SupportText>{t("fileSupport")}</SupportText>
          <SupportText>{t("formatSupport")}</SupportText>
        </div>
      </SecondRow>

      {formik.errors.document &&
        formik.touched.document &&
        !selectedFileUrl && (
          <FormHelperTextStyle error>
            {formik.errors.document}
          </FormHelperTextStyle>
        )}
      <ImageUploadModal
        openModal={openModal}
        handleClose={handleImageUploadModalClose}
        onFileSelected={handleFileSelected}
        onStoredFileSelected={handleStoredFileSelected}
      />
    </Container>
  )
}

export default UploadTileImage
const FormHelperTextStyle = styled(FormHelperText)`
  ${({ theme }) => css`
    margin-top: 0.5625rem;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin-top: 0.1875rem;
    }
  `}
`

const ButtonAndToolTipContainer = styled("div")`
  ${({ theme }) => css`
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
    }
  `}
`
const CardImage = styled("img")`
  ${({ theme }) => css`
    width: 4.8125rem;
    height: 4.25rem;
    border-radius: 1rem;
    position: relative;
    margin-left: 3rem;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin-left: 0rem;
    }
  `}
`

const Container = styled(Box)`
  ${({ theme }) => css`
    margin-top: 2.4375rem;
    display: block;
    flex-direction: column;
    row-gap: 0.75rem;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin-bottom: 2.5625rem;
      display: flex;
    }
  `}
`

const StyledCancelIcon = styled(CancelOutlinedIcon)`
  color: black;
  font-size: 1rem;
  position: relative;
  bottom: 2.8rem;
  right: 1.4rem;
  background-color: white;
  border-radius: 50%; /* Optional: Adds a circular background */
`

const SecondRow = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    height: 5.8125rem;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      height: 1.8125rem;
      margin-bottom: 1rem;
    }
  `}
`

const MainText = styled(Typography)`
  ${({ theme }) => css`
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${theme.palette.grey[700]};
    word-wrap: break-word;
    max-width: 90%;
    padding-right: 1.2rem;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      padding-right: 1.2rem;
    }
  `}
`

const SupportText = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const UploadButton = styled(Button)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: ${({ theme }) => theme.palette.secondary.main[300]};
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
  padding: 0 0.75rem;
  border-radius: 1rem;
  height: 1.875rem;
  background: aliceblue;
  &:hover {
    background-color: unset;
    opacity: 0.75;
  }
`
