import { Grid, useTheme, useMediaQuery } from "@mui/material"
import { useState, useEffect, useCallback, useRef } from "react"
import {
  MainBox,
  BoxLayout,
  MainText,
  TrustPilotImage,
  Card,
  TextContainer,
} from "./TrustPilotStyle"

import Slider from "react-slick"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"
export type TrustPilotImage = {
  image: string
}

export type TrustPilotImageProps = {
  Comments: TrustPilotImage[]
}
const TrustPilot = ({ Comments }: TrustPilotImageProps) => {
  const { t, i18n, ready } = useTranslation("portalHomepage")
  const trustpilotstarImage = useBuildAwsImageUrl(
    i18n.language,
    "trustPilot/Trustpilotstar.svg"
  )
  const trustpilot5starImage = useBuildAwsImageUrl(
    i18n.language,
    "trustPilot/Trustpilot5star.svg"
  )

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [, setActiveChild] = useState(0)
  const [slider, setSlider] = useState<Slider | null>(null)
  const sliderRef = useRef<Slider | null>(null)

  useEffect(() => {
    sliderRef.current && setSlider(sliderRef.current as Slider)
  }, [])
  const changeChild = useCallback(
    (e: KeyboardEvent) => {
      if (!slider) return

      if (e.key === "ArrowLeft") {
        setActiveChild((a) => (a - 1 < 0 ? Comments.length - 1 : a - 1))
        slider.slickPrev()
      } else if (e.key === "ArrowRight") {
        setActiveChild((a) => (a + 1 > Comments.length - 1 ? 0 : a + 1))
        slider.slickNext()
      }
    },
    [slider, Comments]
  )
  useEffect(() => {
    document.addEventListener("keydown", changeChild)
    return function cleanup() {
      document.removeEventListener("keydown", changeChild)
    }
  }, [changeChild])
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 2.1,
    centerMode: true,
    centerPadding: "50px",

    responsive: [
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.08,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.08,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 710,
        settings: {
          slidesToShow: 1.66,
          slidesToScroll: 1,
          centerMode: false,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1035,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "50px",
        },
      },
    ],
  }

  if (!ready) {
    return null
  }
  return (
    <MainBox data-cy="portal-trust-pilot">
      <BoxLayout sx={{ flexGrow: 1 }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: isDesktop ? "0px" : "2rem",
          }}
        >
          <Grid item xs={11} lg={3.8} md={4} sm={5} className="main-grid">
            <TextContainer>
              <MainText>
                {t("trustPilot.lineOne")}
                <br />
                {t("trustPilot.lineTwo")}
              </MainText>
              <TrustPilotImage sx={{ marginRight: isDesktop ? "0.2" : "1rem" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <img
                      src={trustpilotstarImage}
                      alt={t("trustPilot.images.logo")}
                      style={{ paddingBottom: "0.5rem" }}
                    />

                    <img
                      src={trustpilot5starImage}
                      alt={t("trustPilot.images.logoFiveStar")}
                    />
                  </div>
                  <div
                    style={{
                      paddingTop: "3.3rem",
                      paddingLeft: "1rem",
                      color: "white",
                    }}
                  >
                    4.7
                  </div>
                </div>
              </TrustPilotImage>
            </TextContainer>
          </Grid>

          <Grid item xs={12} md={8} lg={8.2} sm={12} className="grid-slider">
            <Slider
              {...settings}
              autoplay={true}
              pauseOnHover
              arrows={false}
              className="carouselSlider"
              ref={sliderRef}
            >
              {Comments.map((Images, index) => {
                return (
                  <div key={index}>
                    <Card
                      style={{
                        marginLeft: isDesktop ? "0px" : "2rem",
                        marginRight: isDesktop ? "0px" : "1rem",
                      }}
                    >
                      <img
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        src={useBuildAwsImageUrl(i18n.language, Images.image)}
                        alt={t("trustPilot.images.comment")}
                        style={{
                          width: "285px",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Card>
                  </div>
                )
              })}
            </Slider>
          </Grid>
        </Grid>
      </BoxLayout>
    </MainBox>
  )
}
export default TrustPilot
