import { Box } from "@mui/system"
import { styled, Link, css } from "@mui/material"
import { useContext } from "react"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import { useEffect } from "react"
import {
  gtmEcommSuccess,
  gtmCycleToWorkEcommSuccess,
} from "../../shared/gtm-events/CustomGtmEvents"
import { Background } from "./Background"
import { Content } from "./Content"
import { MyOrderButton } from "./MyOrderButton"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { OrderProduct } from "../../../graphqGenaretedTypes"

export type ThankYouProps = {
  orderId: string
  products?: OrderProduct[]
  storeName: string
  supplier: string
  productsRevenue: string
}

export default function ThankYou({
  orderId,
  products,
  storeName,
  supplier,
  productsRevenue,
}: ThankYouProps) {
  const { currentUserId, loading } = useContext(StoreLayoutContext)
  const params = useParams()
  const isCycleToWork = params.schemeType === "cycle_to_work"
  const { t, ready } = useTranslation("checkoutPage")
  useEffect(() => {
    if (isCycleToWork) {
      gtmCycleToWorkEcommSuccess(
        orderId,
        storeName,
        supplier,
        productsRevenue,
        products
      )
    } else {
      gtmEcommSuccess(orderId, storeName, supplier, productsRevenue, products)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!ready) {
    return null
  }
  return (
    <Background storeName={storeName}>
      <CenterContainer data-cy="thank-you-container">
        <MessageContainer>
          <MessageHeader>{t("checkoutPage.thankYou.title")}</MessageHeader>
          <Content storeName={storeName} />
        </MessageContainer>

        <OrderId>
          {t("checkoutPage.thankYou.orderId")} {orderId}
        </OrderId>
        <div style={{ display: "none" }} data-revenue={productsRevenue}></div>
        {
          // Keep these informations for the GTM and GA goal.
          products &&
            products.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ display: "none" }}
                  data-id={item.id}
                  data-brand={item.brand}
                  data-category={item.category}
                  data-name={item.name}
                  data-price={item.price}
                  data-quantity={item.quantity}
                  data-variant={item.variant}
                ></div>
              )
            })
        }

        <ButtonsContainer className="content-center">
          <MyOrderButton
            storeName={storeName}
            href={loading ? "#" : `/users/${currentUserId}/orders`}
          />
          <HomeButton component={"a"} href="/">
            {t("checkoutPage.thankYou.takeMeHome")}
          </HomeButton>
        </ButtonsContainer>
      </CenterContainer>
    </Background>
  )
}

const OrderId = styled("h3")`
  text-align: center;
  margin: 2.2rem;
`

const MessageHeader = styled("h3")`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 15rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: none;
    }
  `}
`

const HomeButton = styled(Link)`
  text-align: center;
  cursor: pointer;
  margin-top: 1.25rem;
` as typeof Link

const ButtonsContainer = styled(Box)`
  justify-content: center;
  flex-direction: column;
`

const CenterContainer = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.palette.white.main};
    border-radius: 0.9375rem;
    margin-top: 4.6875rem;
    margin-bottom: 3rem;
    box-shadow: ${theme.boxShadow};
    width: 21.5rem;
    height: 28rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 40.9375rem;
      height: 23.3125rem;
    }
  `}
`
const MessageContainer = styled(Box)`
  margin-top: 2.5rem;
  text-align: center;
`
