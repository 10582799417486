import { useMediaQuery, useTheme } from "@mui/material"
import Modal from "./Modal"
import { useState } from "react"
import StickyButton from "../StickyButton"
import hexToRgba from "../../../utils/hexToRgba"
import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"

export type StickyACButtonProps = {
  showOnMobile?: boolean
}

export default function StickyACButton({ showOnMobile }: StickyACButtonProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [modalOpen, setModalOpen] = useState(false)
  const { t, ready, i18n } = useTranslation("productDetail")

  const affordabilityCalculatorIcon = useBuildAwsImageUrl(
    i18n.language,
    "affordability-calculator-white.svg"
  )

  if (!ready) {
    return null
  }
  return (
    <div className="affordability-calculator-sticky-button">
      {isDesktop ? (
        <StickyButton
          onClick={() => setModalOpen(true)}
          showBelow={0}
          buttonLabel={t("productDetail.StickyACButton.buttonLabel")}
          sx={{
            background: (theme) =>
              `linear-gradient(90deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
            borderRadius: "2.5rem",
            padding: "0.5rem 1.5rem",
            right: "7rem",
            ":hover": {
              background: `linear-gradient(90deg, ${hexToRgba(
                theme.palette.secondary.main,
                "0.7"
              )}, ${hexToRgba(theme.palette.primary.main, "0.7")})`,
            },
          }}
        >
          <>
            <img
              src={affordabilityCalculatorIcon}
              alt={t("productDetail.StickyACButton.Imgalt")}
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
            <div className="affordability-calculator-sticky-button__text body-1">
              {t("productDetail.StickyACButton.label")}
            </div>
          </>
        </StickyButton>
      ) : (
        <>
          {showOnMobile && (
            <StickyButton
              buttonLabel={t("productDetail.StickyACButton.buttonLabel")}
              onClick={() => setModalOpen(true)}
              showBelow={0}
              sx={{
                background: (theme) =>
                  `linear-gradient(90deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
              }}
            >
              <img
                src={affordabilityCalculatorIcon}
                alt={t("productDetail.StickyACButton.Imgalt")}
                style={{ width: "1.5rem", height: "1.5rem" }}
              />
            </StickyButton>
          )}
        </>
      )}

      <Modal open={modalOpen} setOpen={setModalOpen} />
    </div>
  )
}
