import { Grid, styled, useMediaQuery, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import { Pagination, Autoplay, Keyboard } from "swiper/modules"
import { PortalBanner, Banner } from "../../graphqGenaretedTypes"
import BannerCarousel from "../shared/banner-carousel/BannerCarousel"

export type BannerCarouselGridProps = {
  banners: PortalBanner
  hifiveBanner: Banner
  show: boolean
  hi5SsoUrl?: string
}

export default function PortalHomePageBanner({
  banners,
  show,
  hi5SsoUrl,
  hifiveBanner,
}: BannerCarouselGridProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  function renderSwiperSlides() {
    const slides = []
    if (hifiveBanner && hifiveBanner.__typename === "Banner") {
      slides.push(
        <SwiperSlide key="hifiveBanner">
          <Box
            sx={{
              height: isDesktop ? "13.125rem" : "9.5rem",
            }}
          >
            <BannerCarousel
              desktopImageUrl={hifiveBanner.desktopBannerUrl || ""}
              mobileImageUrl={hifiveBanner.mobileBannerUrl}
              link={null}
              showWelcomeText={false}
              showButton={true}
              hi5SsoUrl={hi5SsoUrl}
            />
          </Box>
        </SwiperSlide>
      )
    }
    slides.push(
      <SwiperSlide key="banners1">
        <Box
          sx={{
            height: isDesktop ? "13.125rem" : "9.5rem",
          }}
        >
          <BannerCarousel
            desktopImageUrl={banners.desktopUrl || ""}
            mobileImageUrl={banners.mobileUrl}
            welcomeText={banners.welcomeText}
            link={null}
            showWelcomeText={show}
          />
        </Box>
      </SwiperSlide>
    )

    if (banners.text || banners.logos) {
      slides.push(
        <SwiperSlide key="banners2">
          <Box
            sx={{
              height: isDesktop ? "13.125rem" : "9.5rem",
            }}
          >
            <BannerCarousel
              link={banners.link}
              text={banners.text}
              logos={banners.logos}
              showWelcomeText={show}
            />
          </Box>
        </SwiperSlide>
      )
    }
    return slides
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "#EAE9EE !important",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <StyledSwiper
            modules={[Pagination, Autoplay, Keyboard]}
            pagination={{
              clickable: true,
              el: ".custom-pagination",
              renderBullet: (index: number, className: string) =>
                `<button class="${className}" tabindex="0" aria-label="Go to slide ${
                  index + 1
                }"></button>`,
            }}
            loop={true}
            keyboard={{ enabled: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            spaceBetween={30}
            slidesPerView={1}
            className="carouselBanner"
            style={{ height: isDesktop ? "13.125rem" : "9.5rem" }}
          >
            {renderSwiperSlides()}
          </StyledSwiper>
          <PaginationContainer
            className="custom-pagination"
            sx={{ textAlign: "center" }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
export const StyledSwiper = styled(Swiper)({
  "& .swiper-pagination": {
    marginTop: "1rem",
    position: "absolute",
    bottom: "-2rem",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  "& .swiper-pagination-bullets": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})
export const PaginationContainer = styled("div")`
  margin-top: 0.7rem;

  .swiper-pagination-bullet {
    width: 0.7rem;
    height: 0.7rem;
    background-color: white;
    opacity: 1;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }

  .swiper-pagination-bullet:focus {
    outline: none;
    box-shadow: 0 0 0 3px #007aff;
  }

  .swiper-pagination-bullet-active {
    background-color: #35158f;
  }
`
