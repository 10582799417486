import React, { KeyboardEvent, useEffect, useRef, useState } from "react"
import classNames from "classnames"

import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos"
import { DepartmentType } from "../../layout/formatMegaMenuData"
import Column from "./Column"
import { Link } from "@mui/material"
import { useTranslation } from "react-i18next"

export type MegaMenuTabProps = {
  name: string
  link: string
  categories: DepartmentType[]
  imageUrl?: string | null
  active?: boolean
  extraOptions?: {
    label: string
    link: string
  }[]
  setActive: (tabActive: boolean) => void
}

function MegaMenuTab({
  name,
  link,
  categories,
  imageUrl,
  active = false,
  setActive,
  extraOptions,
}: MegaMenuTabProps) {
  const { t, ready } = useTranslation("layout")
  const numberOfCategories = Object.keys(categories).length
  const [activeCategories, setActiveCategories] = useState(
    Array(numberOfCategories).fill(false)
  )

  const categoryRefs = useRef<(HTMLAnchorElement | null)[]>([])
  const subcategoryRefs = useRef<(HTMLAnchorElement | null)[]>([])

  useEffect(() => {
    categoryRefs.current = categoryRefs.current.slice(0, numberOfCategories)
    subcategoryRefs.current = subcategoryRefs.current.slice(
      0,
      numberOfCategories
    )
  }, [numberOfCategories])

  const triggerCategory = (e: KeyboardEvent, index: number) => {
    if (e.key == "ArrowRight") {
      e.preventDefault()
      setActiveCategories(
        activeCategories.map((_, i) => {
          return index === i ? true : false
        })
      )
      subcategoryRefs.current[index]?.focus()
    } else if (e.key == "ArrowLeft") {
      e.preventDefault()
      setActiveCategories(Array(numberOfCategories).fill(false))
    }
  }

  const resetRef = (e: KeyboardEvent, index: number) => {
    if (e.key == "ArrowLeft") {
      e.preventDefault()
      setActiveCategories(Array(numberOfCategories).fill(false))
      categoryRefs.current[index]?.focus()
    }
  }

  if (!ready) {
    return null
  }

  return (
    <>
      <div
        className={classNames("mega-menu-tab", { "d-none": !active })}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => {
          setActive(false)
          setActiveCategories(Array(numberOfCategories).fill(false))
        }}
      >
        <div className="mega-menu-tab__categories">
          <Link
            href={link}
            className="mega-menu-tab__category mega-menu-tab__title"
            onMouseEnter={() =>
              setActiveCategories(Array(numberOfCategories).fill(false))
            }
          >
            {t("menu.explore") + " " + t("menu." + name)}
            <ArrowForwardIcon
              fontSize="inherit"
              className="mega-menu-tab__forward-arrow"
              color="inherit"
            />
          </Link>
          {categories &&
            categories.map((category, index) => (
              <React.Fragment key={category.label}>
                <Link
                  className={classNames("mega-menu-tab__category", {
                    "mega-menu-tab__category--active": activeCategories[index],
                  })}
                  onMouseEnter={() =>
                    setActiveCategories(
                      activeCategories.map((_, i) => {
                        return index === i ? true : false
                      })
                    )
                  }
                  ref={(el) => (categoryRefs.current[index] = el)}
                  onKeyDown={(e) => triggerCategory(e, index)}
                  href={category.link}
                >
                  {category.label}
                  <ArrowForwardIcon
                    fontSize="inherit"
                    className="mega-menu-tab__forward-arrow"
                    color="inherit"
                  />
                </Link>
              </React.Fragment>
            ))}
          {extraOptions && (
            <div className="mega-menu-tab__section">
              {extraOptions.map((option) => (
                <React.Fragment key={option.label}>
                  <Link
                    className="mega-menu-tab__category mega-menu-tab__category--extra"
                    href={option.link}
                    onMouseEnter={() =>
                      setActiveCategories(activeCategories.map(() => false))
                    }
                  >
                    {option.label}
                    <ArrowForwardIcon
                      fontSize="inherit"
                      className="mega-menu-tab__forward-arrow"
                      color="inherit"
                    />
                  </Link>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
        {categories &&
          categories.map((category, index) => (
            <React.Fragment key={category.label}>
              {category.categories && category.categories.length > 0 && (
                <React.Fragment key={category.label}>
                  <div
                    className={classNames("mega-menu-tab__subcategories", {
                      "d-none": !activeCategories[index],
                    })}
                  >
                    <Link
                      className="mega-menu-tab__subcategory mega-menu-tab__title"
                      href={category.link || ""}
                      sx={{ display: "block" }}
                      ref={(el) => (subcategoryRefs.current[index] = el)}
                      onKeyDown={(e) => resetRef(e, index)}
                    >
                      {t("menu.all") + " " + category.label}
                    </Link>
                    <Column
                      items={
                        imageUrl || category.categories.length < 12
                          ? category.categories
                          : category.categories.slice(
                              0,
                              Math.ceil(category.categories.length / 2)
                            )
                      }
                      resetRef={(e) => resetRef(e, index)}
                    />
                  </div>
                  {!imageUrl && (
                    <div
                      className={classNames("mega-menu-tab__subcategories", {
                        "d-none": !activeCategories[index],
                      })}
                    >
                      {!(category.categories.length < 12) && (
                        <Column
                          items={category.categories.slice(
                            Math.ceil(category.categories.length / 2)
                          )}
                          resetRef={(e) => resetRef(e, index)}
                        />
                      )}
                    </div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        {imageUrl && (
          <img
            src={imageUrl}
            alt="departmentImage"
            className="mega-menu-tab__image"
          />
        )}
      </div>
      <div
        className={classNames("mega-menu-tab__background", {
          "d-none": !active,
        })}
      />
    </>
  )
}

export default MegaMenuTab
