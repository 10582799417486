import { styled, Box, Button, css } from "@mui/material"
import DoneIcon from "@mui/icons-material/Done"
import QuestionMarkIcon from "@mui/icons-material/QuestionMark"
import { useState } from "react"
import BenefitCardModal from "./BenefitCardModal"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { gtmPortalHomepageExploreBenefitTileClick } from "../shared/gtm-events/CustomGtmEvents"
import { useTranslation } from "react-i18next"
import snakeToCamel from "../../utils/snakeToCamel"

export type CardData = {
  image?: string | null
  status?: string | null
  label?: string | null
  link?: string | null
  relativeUrl?: string | null
  popUpContent?: string | null
  kind?: string | null
}
export type CardDataProp = {
  cardItem: CardData
}

const Card = ({ cardItem }: CardDataProp) => {
  const { t, ready } = useTranslation("portalHomepage")
  const ModalData = t("exploreYourBenefit.modalData", {
    returnObjects: true,
    useSuspense: false,
  })

  const modelData =
    ModalData[
      snakeToCamel(cardItem.relativeUrl || "") as keyof typeof ModalData
    ]

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [openModal, setOpenModal] = useState(false)
  const Available =
    cardItem.status === "Open" || cardItem.status?.includes("Open ")
  const GtmEventAvailable = Available ? "yes" : "no"
  const renderCardPopup = () => {
    return (
      <>
        <CardPopup
          style={{
            background:
              "transparent linear-gradient(180deg, #35185f00 0%, #35185f 100%) 0% 0% no-repeat padding-box",
            width: "100%",
            height: "100%",
          }}
        >
          <CardBackground>
            <CardLabel
              style={{ fontSize: "17px", fontWeight: "bold" }}
              title={cardItem.label || ""}
            >
              {truncatedLabel}
            </CardLabel>
            <MainIconContainer>
              {Available ? (
                <IconContainer>
                  <DoneIcon
                    aria-label={t("exploreYourBenefit.ariaLabel.isAvailable")}
                    aria-hidden="false"
                    sx={{
                      color: "#fff",
                      width: "0.9rem",
                      height: "0.9rem",
                    }}
                  />
                </IconContainer>
              ) : (
                <IconContainer style={{ backgroundColor: "#ED6C02" }}>
                  <QuestionMarkIcon
                    aria-hidden="false"
                    aria-label={t("exploreYourBenefit.ariaLabel.notAvailable")}
                    sx={{
                      color: "#fff",
                      width: "0.9rem",
                      height: "0.9rem",
                    }}
                  />
                </IconContainer>
              )}
            </MainIconContainer>
          </CardBackground>
        </CardPopup>
      </>
    )
  }
  const handleModal = () => {
    if (!modelData && !cardItem.popUpContent) {
      setOpenModal(false)
    } else {
      if (openModal === false) {
        gtmPortalHomepageExploreBenefitTileClick(
          truncatedLabel,
          GtmEventAvailable
        )
      }
      setOpenModal(!openModal)
    }
  }

  const GtmHandler = (link: string) => {
    gtmPortalHomepageExploreBenefitTileClick(truncatedLabel, GtmEventAvailable)
    window.open(
      link,
      cardItem.kind === "landing_page"
        ? "_self"
        : cardItem.relativeUrl === "vivup_highfive"
        ? "_blank"
        : "_blank"
    )
  }

  const Label = cardItem.label || ""
  const truncatedLabel =
    Label.length > 32 ? Label.substring(0, 32) + "..." : Label

  if (!ready) {
    return null
  }

  return (
    <>
      {!modelData && !cardItem.popUpContent ? (
        !isDesktop ? (
          <CardContainer
            component={"a"}
            onClick={() => {
              GtmHandler(cardItem.link || "#")
            }}
            disableFocusRipple
            sx={{ ":focus": { outlineOffset: "0.25rem" } }}
            style={{
              backgroundImage: `url("${cardItem.image}")`,
              objectFit: "contain",
            }}
          >
            {renderCardPopup()}
          </CardContainer>
        ) : (
          <CardContainer
            component={"a"}
            onClick={() => {
              GtmHandler(cardItem.link || "#")
            }}
            disableFocusRipple
            sx={{ ":focus": { outlineOffset: "0.25rem" } }}
            style={{
              backgroundImage: `url("${cardItem.image}")`,
              objectFit: "contain",
            }}
          >
            {renderCardPopup()}
          </CardContainer>
        )
      ) : (
        <CardContainer
          component={"a"}
          onClick={() => handleModal()}
          disableFocusRipple
          sx={{ ":focus": { outlineOffset: "0.25rem" } }}
          style={{
            backgroundImage: `url("${cardItem.image}")`,
            objectFit: "contain",
          }}
        >
          {renderCardPopup()}
        </CardContainer>
      )}
      <BenefitCardModal
        openModal={openModal}
        handleClose={handleModal}
        label={cardItem.label}
        status={cardItem.status}
        link={cardItem.link}
        relativeUrl={cardItem.relativeUrl}
        cardData={modelData}
        content={cardItem.popUpContent}
        kind={cardItem.kind}
      />
    </>
  )
}
export default Card

const CardContainer = styled(Button)`
  ${({ theme }) => css`
    max-width: 10rem;
    width: 95%;
    height: 9.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0rem;
    margin: 0px;
    border-radius: 1rem;
    position: relative;
    box-shadow: 0px 3px 6px #00000029;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      &:hover {
        -ms-transform: scale(1.2); /* IE 9 */
        -webkit-transform: scale(1.2); /* Safari 3-8 */
        transform: scale(1.2);
        transition: transform 0.2s;
        opacity: 75%;
      }
    }
  `}
` as typeof Button

export const MainIconContainer = styled(Box)`
  && {
    text-align: end;
    margin: 3px;
    top: 4px;
    right: 6px;
    position: absolute;
  }
`
export const IconContainer = styled(Box)`
  background-color: #488200;
  border-radius: 3.125rem;
  width: 1.393rem;
  height: 1.393rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CardLabel = styled(Box)`
  & {
    text-align: center;
    color: #ffffff;
    position: absolute;
    line-height: normal;
    bottom: 1.5rem;
    left: 10px;
    right: 10px;
  }
`
const CardBackground = styled(Box)`
  width: 100%;
  background: transparent linear-gradient(180deg, #35185f00 0%, #35185f 100%) 0%
    0% no-repeat padding-box;
  z-index: 3;
`
const CardPopup = styled(Box)`
  max-width: 10rem;
  width: 95%;
  height: 9.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0px;
  border-radius: 1rem;
  position: relative;
`
