import { styled, Typography, css } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import useIsMobileView from "../../../utils/useIsMobileView"

export const Content = ({ storeName }: { storeName: string }) => {
  const isMobile = useIsMobileView()
  const { t, ready } = useTranslation("checkoutPage")
  const location = useLocation()

  const isPayrollGivingQuotePage = location.pathname.includes(
    "enter_payroll_giving_quote"
  )

  if (!ready) {
    return null
  }
  const messageLine2 = isPayrollGivingQuotePage
    ? t("checkoutPage.thankyouContent.c2wMessageLine4")
    : t("checkoutPage.thankyouContent.c2wMessageLine2")

  const C2WMessage = [
    t("checkoutPage.thankyouContent.c2wMessageLine1"),
    messageLine2,
    t("checkoutPage.thankyouContent.c2wMessageLine3"),
  ]
  if (storeName === "Home and Electronics") {
    return (
      <>
        {isMobile ? (
          <>
            <Message variant="body1">
              {t("checkoutPage.thankyouContent.defaultMessageLine1")}
            </Message>
            <Message variant="body1">
              {t("checkoutPage.thankyouContent.defaultMessageLine2")}
            </Message>
          </>
        ) : (
          <Message variant="body1">
            {t("checkoutPage.thankyouContent.defaultMessageLine1")}{" "}
            {t("checkoutPage.thankyouContent.defaultMessageLine2")}
          </Message>
        )}
      </>
    )
  }
  return isMobile ? (
    <Message variant="body1">{C2WMessage.join(" ")}</Message>
  ) : (
    <C2WMessageContainer variant="body1">
      {C2WMessage.map((line, index) => {
        return <Lines key={`message-line-${index}`}>{line}</Lines>
      })}
    </C2WMessageContainer>
  )
}
const Lines = styled("span")`
  display: inline-block;
`

const Message = styled(Typography)`
  ${({ theme }) => css`
    margin-top: 1.125rem;
    color: ${theme.palette.primary.main};
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.875rem;
    max-width: 93%;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1rem;
      max-width: 34.5rem;
    }
  `}
`
const C2WMessageContainer = styled(Message)`
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 616px;
    }
  `}
`
