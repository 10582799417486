import { useParams } from "react-router-dom"
import {
  ManageBenefitGroupsContext,
  useManageBenefitGroupsData,
} from "./ManageBenefitsGroupsContext"
import GenericReorderingList from "./GenericReorderingList"
import { useContext } from "react"
import { ClientAdminLayoutContext } from "../client-admin/ClientAdminLayoutContext"

export type ManageBenefitGroupsProps = {
  group: string
}

export default function ManageBenefitGroups({
  group,
}: ManageBenefitGroupsProps) {
  const params = useParams()
  const { data } = useManageBenefitGroupsData(group)
  const { canEditBenefits } = useContext(ClientAdminLayoutContext)

  const link =
    group === "family_care"
      ? `/organisations/${params.organisationId}/employer/benefit_management/add_a_new_family_care`
      : group === "family_pay"
      ? `/organisations/${params.organisationId}/employer/benefit_management/add_a_new_family_pay`
      : `/organisations/${params.organisationId}/employer/benefit_management/add_a_new_wellbeing`

  const translationName =
    group === "family_care"
      ? "manageFamilyCare"
      : group === "family_pay"
      ? "manageFamilyPay"
      : "manageWellbeingReordering"

  return (
    <ManageBenefitGroupsContext.Provider value={data}>
      <GenericReorderingList
        listItem={data.benefits}
        link={link}
        translationName={translationName}
        canEdit={canEditBenefits}
        group={group}
      />
    </ManageBenefitGroupsContext.Provider>
  )
}
