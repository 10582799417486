import { useTranslation } from "react-i18next"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"

type MentorPartnershipData = {
  [key: string]: {
    image: string
    heading: string
    content: string
  }
}

const useMentorPartnershipData = (): MentorPartnershipData => {
  const { t, i18n } = useTranslation("mentorPartnership")

  return {
    employee_assistance_provision_level_3: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/yourCareImg.png"
      ),
      heading: t("yourCareWellbeing.heading"),
      content: t("yourCareWellbeing.content"),
    },
    family_care: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/familyCare.png"
      ),
      heading: t("familyCare.heading"),
      content: t("familyCare.content"),
    },
    home_and_electronics: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/homeAndElectronics.png"
      ),
      heading: t("homeAndElectronics.heading"),
      content: t("homeAndElectronics.content"),
    },
    lifestyle_savings: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/lifestyleSavings.png"
      ),
      heading: t("lifestyleSavings.heading"),
      content: t("lifestyleSavings.content"),
    },
    cycle_to_work: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/cycleToWork.png"
      ),
      heading: t("cycleToWork.heading"),
      content: t("cycleToWork.content"),
    },
    car_scheme: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/careBenefit.png"
      ),
      heading: t("careBenefit.heading"),
      content: t("careBenefit.content"),
    },
    travel_and_leisure: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/travelAndLeisure.png"
      ),
      heading: t("travelAndLeisure.heading"),
      content: t("travelAndLeisure.content"),
    },
    gym_membership: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/gymMembership.png"
      ),
      heading: t("gymMembership.heading"),
      content: t("gymMembership.content"),
    },
    salaryfinance: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/salaryFinance.png"
      ),
      heading: t("salaryFinance.heading"),
      content: t("salaryFinance.content"),
    },
    onDemandPay: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/onDemandPay.png"
      ),
      heading: t("onDemandPay.heading"),
      content: t("onDemandPay.content"),
    },
    vivupHighfive: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/vivupHighfive.png"
      ),
      heading: t("vivupHighfive.heading"),
      content: t("vivupHighfive.content"),
    },
    family_pay: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/familyPay.png"
      ),
      heading: t("familyPay.heading"),
      content: t("familyPay.content"),
    },
    health_cash_plan: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/healthCashPlan.png"
      ),
      heading: t("healthCashPlan.heading"),
      content: t("healthCashPlan.content"),
    },
    customBenefitTile: {
      image: useBuildAwsImageUrl(
        i18n.language,
        "mentorPartnership/customBenefitTile.png"
      ),
      heading: t("customBenefitTile.heading"),
      content: t("customBenefitTile.content"),
    },
  }
}

export default useMentorPartnershipData
