import { Box, MenuItem, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { getTitleProps } from "../../cycle-to-work/quote-page/fields/yourDetailsFields"
import GenericSelect from "../../shared/form/GenericSelect"

export function TitleField() {
  const { t, ready } = useTranslation("constants")

  if (!ready) {
    return null
  }

  const TITLES: Record<string, string> = t("constants.TITLES", {
    returnObjects: true,
    useSuspense: false,
  })

  return (
    <SelectRow>
      <GenericSelect
        props={getTitleProps()}
        sx={{ width: { xs: "7rem", sm: "10rem" } }}
      >
        {Object.values(TITLES).map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </GenericSelect>
    </SelectRow>
  )
}
const SelectRow = styled(Box)`
  display: flex;
  column-gap: 2.375rem;
  margin-bottom: 1.875rem;

  #title-helper-text {
    width: max-content;
  }
`
