import { useTranslation } from "react-i18next"
import { SubTitleHeading, TitleHeading } from "../shared/headingStyles"
import { css, styled } from "@mui/material"
import { useEffect, useState } from "react"
import { MoreButton } from "../../portal-homepage/dynamic-propositions/MoreButton"
import useIsMobileView from "../../../utils/useIsMobileView"

export const Headers = ({
  header,
  content,
}: {
  header?: string
  content?: string
}) => {
  const { t, ready } = useTranslation(["regForm"])
  const [isExpanded, setIsExpanded] = useState(true)
  const [showMoreButton, setShowMoreButton] = useState(true)
  const isMobile = useIsMobileView()

  useEffect(() => {
    const subHeader = document.getElementById("subHeader")
    if (subHeader && subHeader.offsetHeight > 140) {
      setShowMoreButton(true)
      setIsExpanded(false)
    } else {
      setShowMoreButton(false)
    }
  }, [content, ready])

  if (!ready) {
    return null
  }

  const gradientTopLocation = isMobile
    ? isExpanded
      ? "-17px"
      : "-57px"
    : isExpanded
    ? "-37px"
    : "-77px"

  return (
    <>
      <TitleHeading variant="h1">
        {header || t("regForm:signIn.header")}
      </TitleHeading>
      <SubHeading
        id="subHeader"
        style={{
          maxHeight: isExpanded ? "unset" : "140px",
          overflow: "hidden",
          textAlign: content ? "inherit" : "center",
        }}
        dangerouslySetInnerHTML={{
          __html: content || t("regForm:signIn.subHeader"),
        }}
      />
      {showMoreButton && (
        <GradientContainer
          style={{
            top: gradientTopLocation,
            marginBottom: isExpanded ? 0 : "-40px",
          }}
        >
          {!isExpanded && <GradientBlur />}
          <MoreButton
            isExpanded={isExpanded}
            handleClick={() => setIsExpanded(!isExpanded)}
          />
        </GradientContainer>
      )}
    </>
  )
}

export const SubHeading = styled(SubTitleHeading)`
  ${({ theme }) => css`
    max-width: 80%;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: unset;
    }
  `}
`

export const GradientBlur = styled("div")`
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 40px;
`

const GradientContainer = styled("div")`
  z-index: 2;
  position: relative;
`
