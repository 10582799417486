import { useEffect, useState } from "react"
import classNames from "classnames"
import { SxProps, useMediaQuery, useTheme, Theme } from "@mui/material"
import Fab from "@mui/material/Fab"

export type StickyButtonProps = {
  showBelow?: number
  onClick: () => void
  children: JSX.Element
  sx?: SxProps<Theme> | undefined
  isPromo?: boolean
  buttonLabel?: string
}

function StickyButton({
  showBelow = 300,
  onClick,
  children,
  sx,
  isPromo,
  buttonLabel = "Sticky button",
}: StickyButtonProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  const shouldStopBeforeFooter = () => {
    const footer = document.getElementsByTagName("Footer")[0] as HTMLElement

    return (
      document.documentElement.scrollTop <
      document.documentElement.scrollHeight -
        document.documentElement.clientHeight -
        (footer?.offsetHeight || 0)
    )
  }

  const [isVisible, setIsVisible] = useState(showBelow === 0 ? true : false)
  const [stopBeforeFooter, setStopBeforeFooter] = useState(true)

  // TODO - fix eslint warnings/errors
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setStopBeforeFooter(() => {
      if (shouldStopBeforeFooter()) {
        return true
      }
      return false
    })
  })

  const toggleVisibility = () => {
    if (window.pageYOffset >= showBelow) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
    setStopBeforeFooter(() => {
      if (shouldStopBeforeFooter()) {
        return true
      }
      return false
    })
  }

  // TODO - fix eslint warnings/errors
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility)
    return () => {
      window.removeEventListener("scroll", toggleVisibility)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Fab
        aria-label={buttonLabel}
        className={classNames(
          isVisible ? "button-opacity-100" : "button-opacity-0",
          `stickybtn ${isPromo ? "promo-sticky-btn" : ""} ${
            isPromo && !isDesktop ? "promo-sticky-btn-mobile" : ""
          }`
        )}
        onClick={onClick}
        sx={{
          width: "auto",
          height: "auto",
          minWidth: "auto",
          minHeight: "auto",
          padding: isDesktop ? "1rem" : "0.65rem",
          right: isDesktop ? "2.25rem" : "0.7rem",
          backgroundColor: (theme) => theme.palette.white.main,
          ":hover": {
            backgroundColor: "rgb(224, 224, 224)",
          },
          position: stopBeforeFooter ? "fixed" : "absolute",
          ...sx,
        }}
      >
        {children}
      </Fab>
    </>
  )
}

export default StickyButton
