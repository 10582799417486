import { Modal, Box, Button, css } from "@mui/material"
import { Close } from "@mui/icons-material"
import { styled } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { SubmitButton } from "../registration/shared/mainPageStyles"
import {
  IconContainer,
  MainText,
  TextContainerBox,
} from "../explore-your-benefits/BenefitCardModal"
import { FormikProps } from "formik"
import { NewBenefitTileValue } from "./formikUtils"
import { useTranslation } from "react-i18next"
import Header from "../layout/header/Header"
import Footer from "../layout/footer/Footer"
import CustomBreadcrumbs from "../shared/CustomBreadcrumbs"
import DisableTabbing from "../shared/DisableTabbing"
import { useState } from "react"
import { ThemeProvider } from "@mui/material"
import MobileTheme from "../../theme/mui-mobile-theme"
import DefaultTheme from "../../theme/mui-theme"
import { BarChart, TableChart } from "@mui/icons-material"
import { useClientAdminLayoutData } from "./ClientAdminLayoutContext"
import i18n from "../../i18n"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
export type ConfirmationModalProps = {
  openModal: boolean
  handleClose(): void
  formikValue?: FormikProps<NewBenefitTileValue>
}

const PreviewPopUp = ({
  openModal,
  handleClose,
  formikValue,
}: ConfirmationModalProps) => {
  const contextValue = useClientAdminLayoutData()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { t, ready } = useTranslation("newBenefitTile")
  const handleModalClose = () => {
    handleClose()
  }
  const computerImage = useBuildAwsImageUrl(i18n.language, "Computer.png")
  const mobileImage = useBuildAwsImageUrl(i18n.language, "Phone.png")
  const [viewMode, setViewMode] = useState<"desktop" | "mobile">("desktop")

  const breadcrumbsItems = [
    {
      label: t("popUpModal.benefitsHomepage"),
      link: "#",
      forceLink: null,
    },
    {
      label: formikValue?.values.BenefitTitle ?? "",
      link: "#",
      forceLink: null,
    },
  ]

  if (!ready) {
    return null
  }

  return (
    <ThemeProvider theme={viewMode == "mobile" ? MobileTheme : DefaultTheme}>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        role="dialog"
        aria-labelledby="dialog_label"
        aria-modal="true"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MainContainer
          sx={{
            display: "flex",
            flexDirection: "column-reverse",
          }}
          className={viewMode}
        >
          <div>
            <TextContainerBox>
              <TextContainerBox
                sx={{ marginLeft: "2rem", maxWidth: "50%", width: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: isDesktop ? "space-between" : "end",
                    alignItems: "baseline",
                  }}
                >
                  {isDesktop ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        padding: "2px",
                        background: "#f0f0f0",
                        borderRadius: "5px",
                      }}
                    >
                      <Button onClick={() => setViewMode("desktop")}>
                        <img
                          src={computerImage}
                          alt="computer image"
                          style={{
                            width: 25,
                            height: 25,
                          }}
                        />
                      </Button>
                      <Button onClick={() => setViewMode("mobile")}>
                        <img
                          src={mobileImage}
                          alt="computer image"
                          style={{
                            width: 25,
                            height: 25,
                          }}
                        />
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}

                  <MainText id="dialog_label">
                    <span className="screen-reader-only">Opened</span>
                    {t("popUpModal.previewHeader")}
                    <span className="screen-reader-only">pop up</span>
                  </MainText>
                </div>
              </TextContainerBox>
            </TextContainerBox>
            <ContentEditorStyle tabIndex={0}>
              <DisableTabbing>
                <RelativeWrapper>
                  <Header
                    showBasket={false}
                    showNoticeboard={[]}
                    clientAdminLayout={true}
                    backendUser={contextValue.backendUser}
                    isOrganisationSettingUser={
                      contextValue.isOrganisationSettingUser
                    }
                    reporter={contextValue.reporter}
                    currentUserId={contextValue.currentUserId}
                    employeeOrganisations={contextValue.organisations}
                    isPreviewPopUp={true}
                  />

                  <StyledBanner>
                    <BannerText>
                      {formikValue?.values.BenefitTitle &&
                        formikValue?.values.BenefitTitle}
                    </BannerText>
                  </StyledBanner>
                  <BreadcrumbsContainer>
                    <CustomBreadcrumbs breadcrumbsItems={breadcrumbsItems} />
                  </BreadcrumbsContainer>
                  <BreadcrumbsContainer>
                    {formikValue?.values.BenefitSubTitle && (
                      <h4>{formikValue?.values.BenefitSubTitle}</h4>
                    )}
                  </BreadcrumbsContainer>
                  <ContentContainerBox>
                    <Content>
                      {formikValue?.values.editorContent && (
                        <div
                          className="ck-content"
                          dangerouslySetInnerHTML={{
                            __html: formikValue?.values.editorContent,
                          }}
                        />
                      )}
                    </Content>
                  </ContentContainerBox>

                  <Footer
                    privacyNoticeUrl={contextValue.customPrivacyNoticeUrl}
                    organisationPrivacyNotice={
                      contextValue.organisationPrivacyNotice
                    }
                    showAccessibility={contextValue.showAccessibility}
                    showContactUs={contextValue.showContactUs}
                    showCookiePolicy={contextValue.showCookiePolicy}
                    showManageCookies={contextValue.showManageCookies}
                    showOrganisationPrivacyNotice={
                      contextValue.showOrganisationPrivacyNotice
                    }
                    showPrivacyPolicy={contextValue.showPrivacyPolicy}
                    showPromotionalTermsAndConditions={
                      contextValue.showPromotionalTermsAndConditions
                    }
                    showTermsAndConditions={contextValue.showTermsAndConditions}
                    showVivupPrivacyNotice={contextValue.showVivupPrivacyNotice}
                    customFooterLinks={contextValue.customFooterLinks}
                    setShouldShowCookiePopup={() => {
                      // There is no cookie popup in preview, empty function to satisfy type
                    }}
                  />
                </RelativeWrapper>
              </DisableTabbing>
            </ContentEditorStyle>
            <ClosePreview
              sx={{ marginBottom: "1rem" }}
              variant="gradient"
              onClick={handleModalClose}
            >
              {t("popUpModal.closePreview")}
            </ClosePreview>
          </div>
          <IconContainer>
            <Button
              onClick={handleModalClose}
              aria-label={t("popUpModal.closeWindow")}
            >
              <CrossButton
                sx={{
                  paddingRight: isDesktop ? "1.121rem" : "1rem",
                }}
              />
            </Button>
          </IconContainer>
        </MainContainer>
      </Modal>
    </ThemeProvider>
  )
}

export default PreviewPopUp
export const BarIconButton = styled(BarChart)``
export const TableChartButton = styled(TableChart)``
export const StyledBanner = styled("div")`
  width: 100%;
  background: linear-gradient(90deg, #0b7eb0, #35185f);
  min-height: 4.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
`

export const BannerText = styled("h3")`
  color: white;
`

export const ContentContainerBox = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  max-width: 1084px;
`

export const Content = styled("div")`
  ${({ theme }) => css`
    && {
      width: 100%;
      padding: 0.2rem 0.8rem;
      @media screen and (max-width: ${theme.breakpoints.values.md}px) {
        margin-left: 0rem;
        padding: 0.2rem 0.8rem;
      }
    }
  `}
`

export const ClosePreview = styled(SubmitButton)`
  ${({ theme }) => css`
    width: 95%;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      max-width: 17.5rem;
    }
  `}
`

export const CrossButton = styled(Close)`
  ${({ theme }) => css`
    color: ${theme.palette.grey[900]};
    font-size: 1.515rem;
  `}
`

export const RelativeWrapper = styled("div")`
  &&&&&& {
    pointer-events: none;
  }
`

const ContentEditorStyle = styled(Box)`
  ${({ theme }) => css`
    color: #2f1754;
    border: 1px solid black;
    max-height: 30rem;
    overflow: auto;
    margin: 0.5rem 2rem;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
      height: 26.4375rem;
      padding-top: 0rem;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      margin: 0.4rem 1rem;
    }
    &:focus {
      outline: #0b7eb0 solid 0.2rem !important;
    }
  `}
`

export const MainContainer = styled(Box)`
  ${({ theme }) => css`
    && {
      border-radius: 0.938rem;
      background-color: white;
      padding-top: 1rem;
      margin: 0 auto;
      max-height: 52rem;
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        max-width: 75rem;
        max-height: 41rem;
        height: 100%;
        width: 100%;
      }
    }
    &.mobile {
      max-width: 37.8125rem;
      width: 100%;
    }
  `}
`

export const BreadcrumbsContainer = styled(Box)`
  ${({ theme }) => css`
    margin-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 85%;
      max-width: 75.75rem;
    }
    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1058.4px;
      width: auto;
    }
  `}
`
