import { useCheckoutData, CheckoutContext } from "./CheckoutContext"
import { Container } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material"
import OrderSummary from "./OrderSummary"
import Form from "./Form"
import ThankYou from "../thank-you/ThankYou"
import { useContext, useEffect, useState } from "react"
import Loader from "../../shared/Loader"
import { useNavigate } from "react-router"
import ButtonChat from "../../shared/button-chat/ButtonChat"
import { useTranslation } from "react-i18next"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import { ColorStrip } from "../StoreHome"
import TopBenefitWindowInfo from "../TopBenefitWindowInfo"
import { OrderProduct } from "../../../graphqGenaretedTypes"

export default function Checkout() {
  const { data } = useCheckoutData()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [successfulOrderId, setSuccessfulOrderId] = useState<
    string | undefined
  >(undefined)
  const [productsRevenue, setProductsRevenue] = useState("")
  const [storeName, setStoreName] = useState("")
  const [supplier, setSupplier] = useState("")
  const [products, setProducts] = useState<OrderProduct[] | undefined>([])
  const { windowStatus } = useContext(StoreLayoutContext)

  const setSuccessfulOrderDetails = (
    storeName: string,
    revenue: string,
    products: OrderProduct[],
    supplier: string
  ) => {
    setStoreName(storeName)
    setSupplier(supplier)
    setProductsRevenue(revenue)
    setProducts(products)
  }
  const navigate = useNavigate()
  const { ready } = useTranslation("checkoutPage")

  useEffect(() => {
    if (!data.loading && data.products.length === 0) {
      navigate("../basket")
    }
  }, [data.loading]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CheckoutContext.Provider value={data}>
      {data.products.length < 1 || !ready ? (
        <Loader />
      ) : (
        <>
          {successfulOrderId ? (
            <ThankYou
              orderId={successfulOrderId}
              storeName={storeName}
              supplier={supplier}
              productsRevenue={productsRevenue}
              products={products}
            />
          ) : (
            <>
              {windowStatus.status === "windowActive" && (
                <ColorStrip>
                  <TopBenefitWindowInfo windowStatus={windowStatus} />
                </ColorStrip>
              )}
              <Container
                className="checkout"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="form-container">
                  <Form
                    setSuccessfulOrderId={(orderId) =>
                      setSuccessfulOrderId(orderId)
                    }
                    setSuccessfulOrderDetails={(
                      storeName,
                      revenue,
                      products,
                      supplier
                    ) =>
                      setSuccessfulOrderDetails(
                        storeName,
                        revenue,
                        products,
                        supplier
                      )
                    }
                  />
                </div>
                {isDesktop && (
                  <div className="summary-container">
                    <OrderSummary
                      products={data.products}
                      monthlyReduction={data.monthlyGrossAmount}
                      term={data.term}
                      pricePerMonth={data.monthlyPrice}
                      supplierBanner={data.supplierBanner}
                      totalSavings={data.totalSavings}
                    />
                  </div>
                )}
                <ButtonChat />
              </Container>
            </>
          )}
        </>
      )}
    </CheckoutContext.Provider>
  )
}
