import { useEffect, useState } from "react"
import { useGetLocalDiscountDataQuery } from "../../../graphqGenaretedTypes"
import { NewLocalDiscountInitialValues } from "./formikUtils"

type LocalDiscountData = {
  enabled: boolean
  name: string
  typeOfLink: string
  externalLink: string
  isVisible: boolean
  position?: number
  fileName: string
  logoName: string
  savingText: string
  loading: boolean
}

export const useLocalDiscountData = (
  organisationId: string | undefined,
  localDiscountId: string | undefined
): LocalDiscountData => {
  const [state, setState] = useState<LocalDiscountData>({
    ...NewLocalDiscountInitialValues,
    enabled: false,
    loading: true,
    fileName: "",
    logoName: "",
    position: undefined,
  })

  const { data, loading } = useGetLocalDiscountDataQuery({
    variables: {
      organisationId: organisationId ?? "",
      localDiscountId: localDiscountId ?? "",
    },
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    const fetchData = async () => {
      if (!loading && data) {
        const enabled =
          data?.employerOrganisation?.localDiscount?.enabled || false
        const name = data?.employerOrganisation?.localDiscount?.name || ""
        const typeOfLink =
          data?.employerOrganisation?.localDiscount?.typeOfLink || ""
        const externalLink =
          data?.employerOrganisation?.localDiscount?.externalLink || ""
        const position = data?.employerOrganisation?.localDiscount?.position
        const savingText =
          data?.employerOrganisation?.localDiscount?.savingText || ""

        const fileName =
          data?.employerOrganisation?.localDiscount?.fileName || ""
        const logoName =
          data?.employerOrganisation?.localDiscount?.imageName || ""

        setState({
          ...NewLocalDiscountInitialValues,
          enabled,
          name,
          typeOfLink,
          externalLink,
          fileName,
          logoName,
          position,
          savingText,
          loading: false,
        })
      }
    }

    fetchData()
  }, [data, loading])

  return state
}
