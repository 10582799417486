import type { Identifier } from "dnd-core"
import { FC, useEffect, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import React, { useRef } from "react"
import { Box, Button, Grid, styled, Divider, css, Link } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import OpenWithIcon from "@mui/icons-material/OpenWith"
import { useTranslation } from "react-i18next"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import VisibilityIcon from "@mui/icons-material/Visibility"
import useIsMobileView from "../../utils/useIsMobileView"
import { useToggleVisibilityMutation } from "../../graphqGenaretedTypes"
import Alert from "../shared/Alert"

export const ItemTypes = {
  CARD: "card",
}

export interface CardProps {
  id: string | number
  index: number
  locked: boolean
  hidden: boolean
  isEditing: boolean
  text: string
  canEdit: boolean
  group: string
  moveCard: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const ListComponent: FC<CardProps> = ({
  id,
  index,
  moveCard,
  locked,
  hidden,
  isEditing,
  text,
  canEdit,
  group,
}) => {
  const cardRef = useRef<HTMLDivElement | null>(null)
  const iconRef = useRef<HTMLDivElement>(null)
  const { ready } = useTranslation("clientAdminPage")
  const [currentHidden, setCurrentHidden] = useState(hidden)
  const isMobile = useIsMobileView()
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<
    "error" | "success" | "warning" | "info"
  >("error")
  const [errorCount, setErrorCount] = useState(0)
  const [toggleVisibilityMutation] = useToggleVisibilityMutation()

  const [, drop] = useDrop<
    DragItem,
    { hoverIndex: number },
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem) {
      if (!cardRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }
    },
    drop() {
      return { hoverIndex: index }
    },
  })

  const [{ opacity }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        return
      }

      const dropResult = monitor.getDropResult<{ hoverIndex: number }>()
      if (dropResult) {
        const { hoverIndex } = dropResult
        moveCard(item.index, hoverIndex)
      }
    },
    options: {
      dropEffect: "copy",
    },
  })

  useEffect(() => {
    if (iconRef.current) {
      iconRef.current.setAttribute("title", "Edit item")
    }
  }, [])

  const getIdAndType = (id: string | number) => {
    if (typeof id === "string" && id.startsWith("id_")) {
      return {
        extractedId: id.replace("id_", ""),
        type: "OrganisationSchemeSetting",
      }
    }
    return {
      extractedId: String(id),
      type: "LocalScheme",
    }
  }

  const handleToggleView = async () => {
    const { extractedId, type } = getIdAndType(id)

    const { data } = await toggleVisibilityMutation({
      variables: { benefitId: extractedId, type },
    })

    const errors = data?.toggleVisibility?.errors

    if (errors && errors.length > 0) {
      setAlertMessage(errors[0])
      setAlertSeverity("error")
      setErrorCount(errorCount + 1)
    }

    if (data?.toggleVisibility) {
      setCurrentHidden(!data.toggleVisibility.visibility)
    }
  }
  const isWellbeingGroup = group === "help_and_support"
  const showGreyedText = !isWellbeingGroup && locked
  const showStatusBadge = currentHidden
  if (!ready) return null

  return (
    <>
      {alertMessage && (
        <Alert
          severity={alertSeverity}
          message={alertMessage}
          key={errorCount}
        />
      )}
      <ListContainer
        item
        xs={12}
        sm={12}
        md={6}
        ref={(node) => {
          cardRef.current = node
          preview(drop(node))
        }}
        style={{
          pointerEvents: locked && isEditing ? "none" : "auto",
          opacity: locked && isEditing ? "0.4" : opacity,
          paddingLeft: "15px",
          paddingRight: "25px",
        }}
        isEditing={isEditing}
      >
        <ItemBox>
          <Box
            sx={{ padding: 0 }}
            aria-label={`Label: ${text}`}
            aria-disabled={currentHidden || locked}
          >
            <Label
              isHidden={currentHidden}
              showGreyedText={showGreyedText}
              isMobile={isMobile}
            >
              {text}
            </Label>
          </Box>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              {showStatusBadge && (
                <StatusBadge role="status" aria-label={`Status: hidden`}>
                  Hidden
                </StatusBadge>
              )}
            </div>
            <div>
              {!locked && (
                <>
                  {isEditing ? (
                    <div
                      ref={iconRef}
                      {...drag(iconRef)}
                      aria-label="Drag item"
                      role="button"
                      tabIndex={0}
                      aria-pressed={isEditing}
                    >
                      <OpenWithIcon />
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <LabelButton
                        aria-label="Edit item"
                        role="button"
                        tabIndex={0}
                        aria-pressed={isEditing}
                        LinkComponent={Link}
                        href={`${id}/edit`}
                      >
                        <EditOutlinedIcon
                          aria-label="Edit item"
                          aria-hidden="false"
                        />
                      </LabelButton>
                      {canEdit && isWellbeingGroup && (
                        <LabelButton
                          aria-label="View or hide item"
                          role="button"
                          tabIndex={0}
                          onClick={handleToggleView}
                          aria-pressed={currentHidden}
                          title="Toggle visibility of item"
                          aria-describedby="Toggle visibility of item"
                        >
                          <VisibilityIcon
                            aria-label="View or hide item"
                            aria-hidden="false"
                          />
                        </LabelButton>
                      )}
                    </div>
                  )}
                </>
              )}

              {locked && (
                <>
                  {isEditing && (
                    <div tabIndex={0} aria-pressed={isEditing}>
                      <LockOutlinedIcon />
                    </div>
                  )}
                </>
              )}
              {locked && !isEditing && canEdit && isWellbeingGroup && (
                <LabelButton
                  aria-label="View or hide item"
                  role="button"
                  tabIndex={0}
                  onClick={handleToggleView}
                  aria-pressed={currentHidden}
                  title="Toggle visibility of item"
                  aria-describedby="Toggle visibility of item"
                >
                  <VisibilityIcon
                    aria-label="View or hide item"
                    aria-hidden="false"
                  />
                </LabelButton>
              )}
            </div>
          </div>
        </ItemBox>
        <Divider />
      </ListContainer>
    </>
  )
}

const ListContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isEditing",
})<{ isEditing: boolean }>`
  ${({ isEditing }) =>
    isEditing &&
    `
      &:hover {
        border: 3px solid #1ea3eb;
        cursor: pointer;
      }
    `}
`
const LabelButton = styled(Button)`
  padding: 0rem;
`

const ItemBox = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

const StatusBadge = styled(Box)`
  ${({ theme }) => css`
    && {
      background-color: ${theme.palette.grey[600]};
      font-size: 0.75rem;
      color: ${theme.palette.white.main};
      padding: 0.25rem 0.5rem;
      border-radius: 1.25rem;
      margin-right: 0.5rem;
    }
  `}
`

const Label = styled("p")<{
  isHidden: boolean
  showGreyedText: boolean
  isMobile: boolean
}>(({ isHidden, showGreyedText, isMobile }) => ({
  color: isHidden || showGreyedText ? "rgba(0, 0, 0, 0.38)" : "inherit",
  margin: "0.9rem",
  marginTop: "0.4rem",
  maxWidth: isMobile ? "9rem" : "11rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  marginRight: "1rem",
  fontSize: isMobile ? "0.75rem" : "1rem",
}))

export default ListComponent
