import { useMediaQuery, useTheme } from "@mui/material"

import MegaMenuItem, { MegaMenuItemProps } from "./mega-menu/Item"
import CustomItem from "./mega-menu/CustomItem"
import { useState } from "react"

type DepartmentInformation = {
  id: string
  name: string
  link: string
  storeLink: string
  featured?: boolean
  imageUrl: string
  categories: MegaMenuItemProps["categories"]
}

export type CustomNavigationsInformation = {
  id: string
  name: string
  link: string
}

export type MegaMenuProps = {
  departments: DepartmentInformation[]
  customNavigations: CustomNavigationsInformation[]
  isWidgetOpen: boolean
}

function MegaMenu({
  departments,
  customNavigations,
  isWidgetOpen,
}: MegaMenuProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [activeItem, setActiveItem] = useState(-1)

  const setActive = (state: boolean, index: number) => {
    if (state === true) {
      setActiveItem(index)
    } else {
      setActiveItem(-1)
    }
  }

  return (
    <>
      {isDesktop && (
        <div
          className="mega-menu"
          style={isWidgetOpen ? { pointerEvents: "none" } : undefined}
        >
          {departments.map((department: DepartmentInformation, index) => {
            return (
              <MegaMenuItem
                active={activeItem === index}
                setActive={(state: boolean) => setActive(state, index)}
                key={department["id"]}
                link={department.link}
                storeLink={department.storeLink}
                name={department["name"]}
                categories={department["categories"]}
                imageUrl={department["imageUrl"]}
                featured={department["featured"]}
              />
            )
          })}

          {customNavigations.map(
            (customNavigation: CustomNavigationsInformation) => {
              return (
                <CustomItem
                  key={customNavigation["id"]}
                  link={customNavigation["link"]}
                  name={customNavigation["name"]}
                />
              )
            }
          )}
        </div>
      )}
    </>
  )
}

export default MegaMenu
