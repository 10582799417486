import { useTranslation, Trans } from "react-i18next"
import { Box, Button, Typography, css, styled } from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"
import { Dispatch, SetStateAction } from "react"
import useIsMobileView from "../../../utils/useIsMobileView"

type LimitationMessage = {
  translation: string
  values: {
    allowLimit?: string
    valueLimitRemaining?: string
    countLimit?: number
    countLimitRemaining?: number
  }
}

type LimitationMessages = {
  messages: LimitationMessage
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

const LimitationMessages = ({ messages, setModalOpen }: LimitationMessages) => {
  const isMobileView = useIsMobileView()
  const { t, ready } = useTranslation("basket")
  const { translation, values } = messages
  const { countLimit, countLimitRemaining } = values
  let { allowLimit, valueLimitRemaining } = values

  allowLimit =
    allowLimit &&
    parseFloat(allowLimit).toLocaleString(undefined, {
      minimumFractionDigits: parseFloat(allowLimit) % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2,
    })

  valueLimitRemaining =
    valueLimitRemaining &&
    parseFloat(valueLimitRemaining).toLocaleString(undefined, {
      minimumFractionDigits: parseFloat(valueLimitRemaining) % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2,
    })

  if (!ready) {
    return null
  }

  if (translation === "totalBenefitOrderLimitNotExceeded") {
    return (
      <LimitationNotice>
        <Trans
          i18nKey="basket:basket.limitation.totalBenefitOrderLimitNotExceeded"
          values={{
            allowLimit,
            valueLimitRemaining,
          }}
          components={{
            bold: <strong />,
          }}
        />
      </LimitationNotice>
    )
  }

  if (translation === "totalBenefitOrderLimitExceeded") {
    return (
      <ExceedWarningBox>
        <StyledErrorIcon fontSize="small" />
        <div>
          <FirstLine>
            {t("basket.limitation.totalBenefitOrderLimitExceeded.firstPart")}
          </FirstLine>

          <SecondLine>
            {t("basket.limitation.totalBenefitOrderLimitExceeded.amend")}{" "}
          </SecondLine>

          <Underline onClick={() => setModalOpen(true)} as={Button}>
            {t("basket.limitation.totalBenefitOrderLimitExceeded.clickHere")}
          </Underline>
        </div>
      </ExceedWarningBox>
    )
  }

  if (translation === "benefitActiveOrdersNotExceeded") {
    return (
      <LimitationNotice>
        <Trans
          i18nKey="basket:basket.limitation.benefitActiveOrdersNotExceeded"
          values={{
            countLimit,
            countLimitRemaining,
            countLimitOrders: countLimit,
            countLimitRemainingOrders: countLimitRemaining,
            orderText: countLimit === 1 ? "order" : "orders",
            remainingOrderText: countLimitRemaining === 1 ? "order" : "orders",
          }}
          components={{
            strong: <strong />,
            lineBreak: isMobileView ? <span /> : <br />,
          }}
        />
      </LimitationNotice>
    )
  }

  // both exceeded case returns this too
  if (translation === "benefitActiveOrdersExceeded") {
    return (
      <ExceedWarningBox>
        <StyledErrorIcon fontSize="small" />
        <div>
          <FirstLine style={!isMobileView ? { display: "inline" } : undefined}>
            <Trans
              i18nKey="basket:basket.limitation.benefitActiveOrdersExceeded.mainText"
              values={{
                countLimit,
                orderText: countLimit === 1 ? "order" : "orders",
              }}
              components={{
                lineBreak: isMobileView ? <span /> : <br />,
              }}
            />
          </FirstLine>{" "}
          <Underline onClick={() => setModalOpen(true)} as={Button}>
            {t("basket.limitation.benefitActiveOrdersExceeded.clickHere")}
          </Underline>
        </div>
      </ExceedWarningBox>
    )
  }

  if (translation === "bothNotExceeded") {
    return (
      <LimitationNotice>
        <Trans
          i18nKey="basket:basket.limitation.bothNotExceeded"
          values={{
            allowLimit,
            valueLimitRemaining,
            countLimit,
            countLimitRemaining,
            orderText: countLimit === 1 ? "order" : "orders",
            remainingOrderText: countLimitRemaining === 1 ? "order" : "orders",
          }}
          components={{
            strong: <strong />,
            lineBreak: isMobileView ? <span /> : <br />,
          }}
        />
      </LimitationNotice>
    )
  }

  return null
}

export default LimitationMessages

const LimitationNotice = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.secondary.main};
    font-size: 0.75rem;
    max-width: 92.8%;
    text-align: center;
    align-self: center;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      align-self: baseline;
      max-width: 65%;
      font-size: 0.875rem;
      text-align: left;
    }
  `}
`

export const FirstLine = styled(LimitationNotice)`
  ${({ theme }) => css`
    color: ${theme.palette.error.main};
    font-weight: bold;
    text-align: left;
    line-height: 0.875rem;
    max-width: unset;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin: unset;
      max-width: unset;
      line-height: 1rem;
    }
  `}
`

const SecondLine = styled(FirstLine)`
  ${({ theme }) => css`
    display: block;
    margin-top: 0.875rem;
    margin-bottom: -0.25rem;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      display: inline;
      margin-bottom: unset;
    }
  `}
`

const ExceedWarningBox = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    max-width: 92.8%;
    margin: 2rem auto;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      margin: unset;
      margin-top: 0.75rem;
    }

    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      margin-left: -2rem;
    }
  `}
`

export const StyledErrorIcon = styled(ErrorIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.error.main};
    margin-right: 0.625rem;
  `}
`

export const Underline = styled(FirstLine)`
  text-decoration: underline;
  padding: 0;
`
