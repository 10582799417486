import { Box, styled, css } from "@mui/material"
import React from "react"
import BannerCarousel from "../shared/banner-carousel/BannerCarousel"
import CustomBreadcrumbs, { BreadcrumbsType } from "../shared/CustomBreadcrumbs"
import { BannerText } from "./BannerText"
import { useBuildAwsImageUrl } from "../../utils/useBuildAwsImageUrl"
import { useLocation } from "react-router-dom"

type C2WLayoutType = {
  breadcrumbsItems?: BreadcrumbsType[]
  bannerTitle?: string
}

const Layout: React.FC<React.PropsWithChildren<C2WLayoutType>> = ({
  breadcrumbsItems,
  bannerTitle,
  children,
}) => {
  const location = useLocation()
  const pageLocation = location.pathname.includes("enter_payroll_giving_quote")
  const desktopBanner = useBuildAwsImageUrl("en-GB", "cycleToWork/desktop.png")
  const mobileBanner = useBuildAwsImageUrl("en-GB", "cycleToWork/mobile.png")
  return (
    <React.Fragment>
      {!pageLocation && (
        <BannerContainer>
          <BannerCarousel
            desktopImageUrl={desktopBanner}
            mobileImageUrl={mobileBanner}
            link={undefined}
            showWelcomeText={false}
            BannerTitleComponent={<BannerText title={bannerTitle ?? ""} />}
          />
        </BannerContainer>
      )}

      <BreadcrumbsContainer sx={{ marginTop: pageLocation ? "8rem" : "1rem" }}>
        <CustomBreadcrumbs breadcrumbsItems={breadcrumbsItems ?? []} />
      </BreadcrumbsContainer>

      {children}
    </React.Fragment>
  )
}

export default Layout

const BannerContainer = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    height: 76px;
    background-color: ${theme.palette.primary.main};
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      height: 94px;
    }
  `
)

const BreadcrumbsContainer = styled(Box)`
  ${({ theme }) => css`
    margin-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: 85%;
      max-width: 1212px;
    }
    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      margin-left: auto;
      margin-right: auto;
    }
  `}
`
