export type SpecialPromotionType = {
  url: string
  label?: string | null | undefined
  desktopPopUpUrl: string
  mobilePopUpUrl: string
}

export function specialPromotionAvailable(
  specialPromotion: SpecialPromotionType
) {
  return specialPromotion?.url && specialPromotion?.label
}

export function promotionalItem(name: string) {
  return name === "Black Friday" || name === "Cyber Monday"
}
