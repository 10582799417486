import React from "react"
import { styled, Typography, Link, css } from "@mui/material"
import Slider from "react-slick"
import { gtmPortalHomepageNoticeboardButtonClick } from "../../shared/gtm-events/CustomGtmEvents"

const NoticeBoardChip = ({
  title,
  href,
  setSlideIndex,
  sliderRef,
  kind,
}: {
  title: string
  href: string
  setSlideIndex: React.Dispatch<React.SetStateAction<number | null>>
  sliderRef: React.RefObject<Slider> | null
  kind: string
}) => {
  // maxLength of characters appear in each carousel chip
  const maxLength = 30
  const Title =
    title.length > maxLength ? title.slice(0, maxLength - 3) + "..." : title
  const handleTabFocus = (
    e:
      | React.FocusEvent<HTMLAnchorElement, Element>
      | React.FocusEvent<HTMLSpanElement, Element>
  ) => {
    if (
      e.target.parentNode &&
      e.target.parentNode.parentNode &&
      e.target.parentNode.parentNode instanceof HTMLElement
    ) {
      const focusIndex = e.target.parentNode.parentNode.dataset.index
      if (focusIndex) {
        setSlideIndex(Number(focusIndex))
        sliderRef?.current && sliderRef.current.slickGoTo(Number(focusIndex))
      }
    }
  }

  return (
    <Container
      href={href}
      target={kind === "link" ? "_blank" : undefined}
      component="a"
      onFocus={(e) => handleTabFocus(e)}
      onClick={() => {
        gtmPortalHomepageNoticeboardButtonClick(Title)
      }}
    >
      <ChipTitle>{Title}</ChipTitle>
    </Container>
  )
}

export default NoticeBoardChip

const Container = styled(Link)`
  ${({ theme }) => css`
    display: grid;
    place-items: center;
    border-radius: 0.9375rem;
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.white.main};
    opacity: 1;
    transition: all 0.2s ease;
    text-decoration: none;

    min-width: 9.75rem; //156px;
    max-width: 23.3125rem; //373px;
    height: 3.0625rem; //49px;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: unset;
      max-width: 16.25rem; // 260px
      height: 5rem;
      margin-left: 0;

      &:hover {
        opacity: 0.6;
      }
    }
  `}
` as typeof Link

const ChipTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 400;

    text-align: center;
    max-width: 7.5rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: 500;
    }
  `}
`
