import { Box, css, styled } from "@mui/material"
import useMentorPartnershipData from "./useMentorPartnershipData"
import BenefitCard from "./BenefitCard"
import { useTranslation } from "react-i18next"
import { PayrollPayBenefit } from "../../graphqGenaretedTypes"
import Loader from "../shared/Loader"

type MentorPartnership = {
  payrollPayBenefits: PayrollPayBenefit[]
}

const MentorPartnership = ({ payrollPayBenefits }: MentorPartnership) => {
  const { ready } = useTranslation("mentorPartnership")
  const mentorPartnershipData = useMentorPartnershipData()

  if (!ready || payrollPayBenefits.length === 0) {
    return <Loader />
  }
  return (
    <MainContainer>
      {payrollPayBenefits.map((benefit) => {
        if (benefit.status.startsWith("Open") && benefit.relativeUrl) {
          const benefitType = benefit.relativeUrl
          const openedBenefit = mentorPartnershipData?.[benefitType]
          if (openedBenefit) {
            return (
              <BenefitCard
                image={openedBenefit.image}
                heading={openedBenefit.heading}
                content={openedBenefit.content}
                key={openedBenefit.heading}
                link={benefit.link ?? ""}
              />
            )
          }
        }
      })}
    </MainContainer>
  )
}

export default MentorPartnership

const MainContainer = styled(Box)`
  ${({ theme }) => css`
    background: ${theme.palette.grey[200]};
    padding-top: 2rem;
    padding-bottom: 3rem;
  `}
`
