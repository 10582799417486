import debounce from "lodash.debounce"
import React from "react"
import { useInstantSearch } from "react-instantsearch"
import { gtmInstantSearch } from "../components/shared/gtm-events/CustomGtmEvents"
function middleware() {
  const event = "page_view"
  const sendEventDebounced = debounce(() => {
    gtmInstantSearch(event)
  }, 3000)
  return {
    onStateChange() {
      sendEventDebounced()
    },
  }
}
export function Middleware() {
  const { addMiddlewares } = useInstantSearch()

  React.useLayoutEffect(() => {
    return addMiddlewares(middleware)
  }, [addMiddlewares])

  return null
}
