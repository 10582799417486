import { useState, useRef, useEffect } from "react"
import { Box, styled, css } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import FeatureChips from "../FeatureChips"
import LsFeatureCardGrid from "../feature-card/CardGrid"
import { ExploreButton } from "../shared-styles"
import PopUpButton from "./PopUpButton"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { Overlay } from "./overlay/Overlay"
import { CarouselContainer } from "./CarouselContainer"
import { WaveSvg } from "./WaveSvg"
import { TopBanner } from "./TopBanner"
import { AnimatePresence, motion } from "framer-motion"
import { useStoreHomeData, StoreHomeContext } from "./../StoreHomeContext"
import Loader from "../../shared/Loader"

import {
  gtmLifestyleExploreAllDiscountsClick,
  gtmLsProductPageLoad,
  gtmOnPageland,
  gtmPlacedStore,
} from "../../shared/gtm-events/CustomGtmEvents"
import CarouselSlider from "../../shared/carousel-slider/CarouselSlider"
import { useTranslation } from "react-i18next"
import ScrollToTop from "../../shared/ScrollToTop"
import { useBuildAwsImageUrl } from "../../../utils/useBuildAwsImageUrl"

const carouselSliderId = "carouselSlider"

const LifestyleHomepage = () => {
  const { t, ready } = useTranslation("lifestyleSavings")

  const { data } = useStoreHomeData()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const store = "lifestyle_savings"
  const { i18n } = useTranslation()
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const lifestyle_saving_desktop_banner = useBuildAwsImageUrl(
    i18n.language,
    "lifestyle_saving_desktop_banner.png"
  )
  const lifestyle_saving_mobile_banner = useBuildAwsImageUrl(
    i18n.language,
    "lifestyle_saving_mobile_banner.png"
  )
  const savings_banner_desktop = useBuildAwsImageUrl(
    i18n.language,
    "savings_banner_desktop.png"
  )
  const savings_banner_mobile = useBuildAwsImageUrl(
    i18n.language,
    "savings_banner_mobile.png"
  )
  const overlayRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!data.loading) {
      gtmPlacedStore(store)
      gtmLsProductPageLoad()
      gtmOnPageland(store)
    }
  }, [data.loading, store])
  const openOverlay = () => {
    setIsOverlayOpen(true)
    overlayRef.current && window.scrollTo(0, overlayRef.current.offsetTop)
  }

  const closeOverlay = () => {
    setIsOverlayOpen(false)
    setTimeout(() => {
      document.getElementById("whatIsLsButton")?.focus()
    }, 1200)
  }
  const dataHandler = () => {
    gtmLifestyleExploreAllDiscountsClick()
  }

  return (
    <StoreHomeContext.Provider value={data}>
      {data.loading || !ready ? (
        <Loader />
      ) : (
        <>
          <TopBanner
            desktopImageUrl={lifestyle_saving_desktop_banner}
            mobileImageUrl={lifestyle_saving_mobile_banner}
            link={null}
          />
          <FeatureChips />

          <LsFeatureCardGrid />

          <WaveBackgroundContainer>
            <WaveSvg />
            <ContentContainer id={carouselSliderId}>
              {data?.carouselCategories.length > 0 &&
                data.carouselCategories.map(
                  ({ name, url, carouselDiscounts }, index) => {
                    return (
                      carouselDiscounts && (
                        <CarouselContainer
                          title={name}
                          key={`carousel-${name}`}
                          url={url}
                          Slider={
                            <CarouselSlider
                              linearGradient={theme.palette.grey[200]}
                              slideData={carouselDiscounts}
                              position={index + 1}
                              categories={name}
                              carouselSliderId={carouselSliderId}
                              store="lifestyle-savings"
                            />
                          }
                        />
                      )
                    )
                  }
                )}
              {isDesktop && (
                <ButtonContainer>
                  <PopUpButton
                    openOverlay={openOverlay}
                    isOverlayOpen={isOverlayOpen}
                  />
                </ButtonContainer>
              )}
              {/* // Cannot add ref in Overlay component because there's a slight
              delay in rendering from motion div, needs to have a wrapper that
              renders right away to store the ref */}
              <Box ref={overlayRef}>
                <AnimatePresence>
                  {isOverlayOpen && (
                    <motion.div
                      className={isOverlayOpen ? "" : "d-none"}
                      initial={{ y: 1000 }}
                      animate={{ y: 100 }}
                      transition={{
                        type: "spring",
                        bounce: 0,
                        duration: 1,
                        delay: 0.1,
                      }}
                      exit={{ y: 1000 }}
                    >
                      <Overlay
                        closeOverlay={closeOverlay}
                        isOverlayOpen={isOverlayOpen}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </Box>
            </ContentContainer>
          </WaveBackgroundContainer>

          {/* <BeagleStreetBanner /> */}

          <SavingsBannerContainer>
            {isDesktop ? (
              <Tooltip
                title={t("landingPage.banner.title")}
                enterDelay={1500}
                enterNextDelay={1500}
              >
                <Banner
                  src={savings_banner_desktop}
                  alt={t("landingPage.banner.desktopAlt")}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={t("landingPage.banner.title")}
                enterDelay={1500}
                enterNextDelay={1500}
              >
                <Banner
                  src={savings_banner_mobile}
                  alt={t("landingPage.banner.mobileAlt")}
                />
              </Tooltip>
            )}
          </SavingsBannerContainer>

          {/* <Carousel
            title="Your Recently Viewed"
            Slider={
              <CarouselSlider linearGradient={theme.palette.white.main} />
            }
          /> */}

          <BottomExploreButton
            variant="gradient"
            href="../products"
            onClick={() => dataHandler()}
          >
            {t("landingPage.exploreButton")}
          </BottomExploreButton>
          <ScrollToTop showBelow={500} />
        </>
      )}
    </StoreHomeContext.Provider>
  )
}

export default LifestyleHomepage

const BottomExploreButton = styled(ExploreButton)`
  width: 91.8%;
  max-width: 21.5rem;
  margin-bottom: 6rem;
  display: flex;
`

const SavingsBannerContainer = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    width: 90%;
    margin: 2rem auto;

    height: 20.375rem;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      max-width: 69.125rem;
      height: 17.3125rem;
    }
  `}
`

const Banner = styled("img")`
  ${({ theme }) => css`
    width: 100%;
    object-fit: cover;
    border-radius: ${theme.boxBorderRadius};
    box-shadow: ${theme.boxShadow};
  `}
`

const ButtonContainer = styled(Box)`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
`

const ContentContainer = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    height: calc(27.875rem * 3); // single carousel height 446px, 27.875rem * 3
    overflow: hidden; // for the overlay animation

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      height: calc(
        27.875rem * 3 + 6rem
      ); // add space for the lifestyle saving button
    }
  `}
`

const WaveBackgroundContainer = styled(Box)`
  width: 100%;
  position: relative;
  margin-top: -8rem; // shift the wave up, behind the feature card
  padding-top: 9rem; // content shift down, not to get covered by feature card
`
