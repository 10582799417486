import { createContext } from "react"
import { useParams } from "react-router-dom"
import {
  GetLsMegaMenuHierarchyQueryHookResult,
  useGetLsMegaMenuHierarchyQuery,
} from "../../../graphqGenaretedTypes"
import { MegaMenuProps } from "../../shared/lifestyle-mega-menu/LifestyleMegaMenu"
import { ItemHierarchyType } from "../../shared/step-menu/StepMenu"
import { useTranslation } from "react-i18next"

export type StoreCategoryContextData = {
  categories: MegaMenuProps["categories"]
  categoryHierarchy: ItemHierarchyType[]
  currentUserId?: number
  searchSuggestions: string[]
  loading: boolean
}

const defaultValue: StoreCategoryContextData = {
  categories: [],
  categoryHierarchy: [],
  searchSuggestions: [],
  loading: true,
}

const valueFromQueryResult = function (
  result: GetLsMegaMenuHierarchyQueryHookResult
): StoreCategoryContextData {
  const data = result.data

  const loading = result.loading

  const categoryHierarchy =
    data?.employeeOrganisation?.lifestyleSavings?.categories?.map(
      (category) => ({
        key: category.name,
        name: category.name,
        link: category.link,
        isBold: category.isBold,
        hasChild: category.hasChild,
        children: category.subcategories.map((subcategory) => ({
          key: subcategory.name,
          name: subcategory.name,
          link: subcategory.link,
          isBold: subcategory.isBold,
          hasChild: category.hasChild || false,
        })),
      })
    )

  const categories =
    data?.employeeOrganisation?.lifestyleSavings?.categories?.map(
      (category) => ({
        name: category.name,
        link: category.link,
        isBold: category.isBold,
        subcategories: category.subcategories.map((subcategory) => ({
          name: subcategory.name,
          link: subcategory.link,
          isBold: subcategory.isBold || undefined,
        })),
      })
    ) || []

  return {
    currentUserId: data?.currentUser?.id,
    categories: categories,
    categoryHierarchy: categoryHierarchy || [],
    searchSuggestions:
      data?.employeeOrganisation?.lifestyleSavings?.searchSuggestions || [],
    loading: loading,
  }
}

export const useCategoryLayoutData = function (): StoreCategoryContextData {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetLsMegaMenuHierarchyQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      locale: i18n.language,
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  return valueFromQueryResult(data)
}

export const useDefaultCategoryLayoutData =
  function (): StoreCategoryContextData {
    return defaultValue
  }

export const StoreCategoryContext = createContext(defaultValue)
