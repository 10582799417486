import { createContext } from "react"
import { useParams } from "react-router-dom"
import {
  GetMegaMenuHierarchyQueryHookResult,
  useGetMegaMenuHierarchyQuery,
  SpecialPromotion,
} from "../../../graphqGenaretedTypes"
import {
  MegaMenuProps,
  CustomNavigationsInformation,
} from "../../shared/MegaMenu"
import { ItemHierarchyType } from "../../shared/step-menu/StepMenu"
import {
  SpecialPromotionType,
  specialPromotionAvailable,
} from "../../../utils/specialPromoHelper"
import { useTranslation } from "react-i18next"

export type StoreDepartmentContextData = {
  departments: MegaMenuProps["departments"]
  customNavigations: CustomNavigationsInformation[]
  departmentHierarchy?: ItemHierarchyType[]
  searchSuggestions: string[]
  specialPromotion: SpecialPromotion
  currentUserId?: number
  loading: boolean
}

const defaultSpecialPromotionValue = {
  label: "",
  url: "",
  mobilePopUpUrl: "",
  desktopPopUpUrl: "",
}

export const defaultValue: StoreDepartmentContextData = {
  departments: [],
  customNavigations: [],
  departmentHierarchy: [],
  searchSuggestions: [],
  specialPromotion: defaultSpecialPromotionValue,
  loading: true,
}

const valueFromQueryResult = function (
  result: GetMegaMenuHierarchyQueryHookResult
): StoreDepartmentContextData {
  const data = result.data

  const loading = result.loading

  const departmentHierarchy =
    data?.employeeOrganisation?.scheme?.store?.departments?.map(
      (department) => ({
        name: department.name,
        link: department.link,
        key: department.id,
        hasChild: true,
        children: department.categories.map((category) => ({
          name: category.name,
          link: category.storeLink,
          key: category.id,
          hasChild: true,
          children: category.subcategories.map((subcategory) => ({
            name: subcategory.name,
            link: subcategory.storeLink,
            key: subcategory.id,
          })),
        })),
      })
    )

  const departments =
    data?.employeeOrganisation?.scheme?.store?.departments?.map(
      (department) => ({
        name: department.name,
        id: department.id,
        imageUrl: department.imageUrl,
        link: department.link,
        storeLink: department.storeLink,
        categories: department.categories.map((category) => ({
          name: category.name,
          id: category.id,
          link: category.storeLink,
          subcategories: category.subcategories.map((subcategory) => ({
            name: subcategory.name,
            id: subcategory.id,
            link: subcategory.storeLink,
          })),
        })),
      })
    ) || []

  const defaultSpecialPromo: SpecialPromotionType = {
    label: "",
    url: "",
    mobilePopUpUrl: "",
    desktopPopUpUrl: "",
  }

  const specialPromo =
    data?.employeeOrganisation?.scheme?.specialPromotion || defaultSpecialPromo

  const customNavigations: CustomNavigationsInformation[] =
    data?.employeeOrganisation?.scheme?.store?.customNavigations || []
  const customNavHierarchy = customNavigations?.map((item) => {
    return {
      ...item,
      key: item.id,
      isBold: true,
      hasChild: false,
      children: [],
    }
  })

  if (specialPromotionAvailable(specialPromo)) {
    departments.push({
      id: specialPromo.label ?? "",
      name: specialPromo.label ?? "",
      link: specialPromo.url,
      storeLink: specialPromo.url,
      categories: [],
      imageUrl: "",
    })

    departmentHierarchy &&
      departmentHierarchy.push({
        name: specialPromo.label ?? "",
        link: specialPromo.url,
        key: specialPromo.label ?? "",
        hasChild: false,
        children: [],
      })
  }
  if (customNavigations) {
    departmentHierarchy?.push(...customNavHierarchy)
  }
  return {
    currentUserId: data?.currentUser?.id,
    departments: departments,
    departmentHierarchy: departmentHierarchy || [],
    customNavigations: customNavigations,
    searchSuggestions:
      data?.employeeOrganisation?.scheme?.searchSuggestions || [],
    specialPromotion:
      data?.employeeOrganisation?.scheme?.specialPromotion ||
      defaultSpecialPromotionValue,
    loading: loading,
  }
}

export const useDepartmentLayoutData = function (
  shouldFetch: boolean
): StoreDepartmentContextData {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetMegaMenuHierarchyQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: params.schemeType ?? "",
      locale: i18n.language,
    },
    skip: !shouldFetch,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  return valueFromQueryResult(data)
}

export const useDefaultDepartmentLayoutData =
  function (): StoreDepartmentContextData {
    return defaultValue
  }

export const StoreDepartmentContext = createContext(defaultValue)
