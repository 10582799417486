import { useState } from "react"
import classNames from "classnames"
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos"
import { DepartmentType } from "../../layout/formatMegaMenuData"
import HealthAndWellbeingColumn from "./HealthAndWellbeingColumn"
import { Link } from "@mui/material"
import FamilyPayCard from "./FamilyPayCard"
import { useTranslation } from "react-i18next"

export type MegaMenuHealthAndWellbeingProps = {
  name?: string
  link?: string
  categories: DepartmentType[]
  active?: boolean
  setActive: (tabActive: boolean) => void
}

function FamilyPayMegaMenu({
  link,
  categories,
  active = false,
  setActive,
}: MegaMenuHealthAndWellbeingProps) {
  const numberOfCategories = Object.keys(categories).length
  const [, setActiveCategories] = useState(
    Array(numberOfCategories).fill(false)
  )
  const nrOfFirstColumnCategories = Math.ceil(categories.length / 2)
  const { t } = useTranslation("portalHomepage")
  const columnStyle = {
    maxHeight: "500px",
    overflowY:
      numberOfCategories > 10 ? ("auto" as const) : ("visible" as const), // Scrollable if more than 10 items
  }
  return (
    <>
      <div
        className={classNames("mega-menu-tab", { "d-none": !active })}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => {
          setActive(false)
          setActiveCategories(Array(numberOfCategories).fill(false))
        }}
      >
        <div
          style={{
            ...columnStyle,
            display: "flex",
            width: "100%",
          }} // Set width to 100%
          className="mega-menu-tab__categories"
        >
          <div style={{ flex: 1 }}>
            <Link
              className="mega-menu-tab__category mega-menu-tab__title"
              onMouseEnter={() =>
                setActiveCategories(Array(numberOfCategories).fill(false))
              }
              href={link}
              target="_blank"
              onClick={() => {
                setActive(false)
              }}
            >
              <div>{t("familyPay.familyPayHeading")}</div>
              <ArrowForwardIcon
                fontSize="inherit"
                className="mega-menu-tab__forward-arrow"
                color="inherit"
              />
            </Link>
            <div>
              <HealthAndWellbeingColumn
                setActive={setActive}
                items={categories.slice(0, nrOfFirstColumnCategories)}
              />
            </div>
          </div>
          <div
            style={{ flex: 1, marginLeft: "20px" }} // This will also take up 50% of the width
            className="mega-menu-tab__categories"
          >
            <HealthAndWellbeingColumn
              setActive={setActive}
              items={categories.slice(
                nrOfFirstColumnCategories,
                categories.length
              )}
            />
          </div>
        </div>

        <FamilyPayCard link={link} setActive={setActive} items={categories} />
      </div>

      <div
        className={classNames("mega-menu-tab__background", {
          "d-none": !active,
        })}
      />
    </>
  )
}

export default FamilyPayMegaMenu
