import { Box, styled } from "@mui/material"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Pagination, Keyboard } from "swiper/modules"
import Roundel, { RoundelProps } from "../../../shared/Roundel"
import { arrayToSubArrays } from "../../../../utils/arrayToSubArrays"
import { useTheme } from "@mui/material/styles"
import { PaginationContainer } from "../../../lifestyle-savings/feature-card/CardGrid"

const C2WRoundel = ({
  roundelArray,
  roundelBackground,
  roundelPerPage,
  isHomeAndElectronicSection,
  isCycleToWork,
  cycleToWorkStore,
}: {
  roundelArray: RoundelProps[]
  roundelBackground: "gray"
  roundelPerPage: number
  isCycleToWork?: boolean
  isHomeAndElectronicSection?: boolean
  cycleToWorkStore?: boolean
}) => {
  const theme = useTheme()

  const subArrays = arrayToSubArrays(roundelArray, roundelPerPage)

  return (
    <ParentContainer>
      <Swiper
        modules={[Pagination, Keyboard]}
        slidesPerView={1}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
        }}
        loop={true}
        keyboard={{ enabled: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        spaceBetween={20}
        style={{
          "--swiper-pagination-color": theme.palette.primary.main,
        }}
      >
        {subArrays.map((subArray, index) => (
          <SwiperSlide key={`mobile-roundel-carousel-page-${index}`}>
            <RoundelCarouselContainer>
              {subArray.map(({ label, imageUrl, link }) => (
                <Roundel
                  key={`portal-homepage-roundel-${label}`}
                  imageUrl={imageUrl}
                  link={link}
                  background={roundelBackground}
                  label={label}
                  isHomeAndElectronicSection={isHomeAndElectronicSection}
                  isCycleToWork={isCycleToWork}
                  cycleToWorkStore={cycleToWorkStore}
                />
              ))}
            </RoundelCarouselContainer>
          </SwiperSlide>
        ))}
      </Swiper>

      <CTWWidgetPagination className="swiper-pagination" />
    </ParentContainer>
  )
}

const ParentContainer = styled(Box)`
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;
`
const RoundelCarouselContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
`
const CTWWidgetPagination = styled(PaginationContainer)`
  && {
    margin-top: 0rem;
  }
`
export default C2WRoundel
