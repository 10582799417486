import { createContext } from "react"
import { useParams } from "react-router-dom"
import {
  GetLsLayoutDataQueryHookResult,
  useGetLsLayoutDataQuery,
} from "../../../graphqGenaretedTypes"
import { SupportLinksProps } from "../../shared/store/SupportLinks"
import { useTranslation } from "react-i18next"
import {
  AvailableLanguagesType,
  DefaultLanguageType,
  fallbackLanguage,
} from "../../../utils/languageUtils"
import { getSignOutLabel } from "../GlobalLayoutContext"

export type LSStoreLayoutContextData = {
  supportLinks: SupportLinksProps["linkList"]
  organisationName: string
  privacyNoticeUrl?: string
  organisationPrivacyNotice: boolean
  currentUserId?: number
  logo?: string
  condensedLogo?: string
  loading: boolean
  // departments?: string
  // searchSuggestions?: string
  supplierDesktopBanner?: string
  supplierMobileBanner?: string
  availableLanguages: AvailableLanguagesType
  defaultLanguage: DefaultLanguageType
  partnerJourney: string | undefined
  isLockBenefit: boolean
  isValidBenefitType: boolean
  signOutLabel?: string
  showAccessibility?: boolean
  showContactUs?: boolean
  showCookiePolicy?: boolean
  showManageCookies?: boolean
  showOrganisationPrivacyNotice?: boolean
  showPrivacyPolicy?: boolean
  showPromotionalTermsAndConditions?: boolean
  showTermsAndConditions?: boolean
  showVivupPrivacyNotice?: boolean
  customFooterLinks?: {
    url: string
    label: string
    id: string
  }[]
}

const defaultValue: LSStoreLayoutContextData = {
  organisationName: "Vivup",
  privacyNoticeUrl: undefined,
  organisationPrivacyNotice: false,
  supportLinks: [],
  loading: true,
  availableLanguages: [],
  defaultLanguage: fallbackLanguage,
  partnerJourney: undefined,
  isLockBenefit: false,
  isValidBenefitType: false,
  signOutLabel: undefined,
  showAccessibility: false,
  showContactUs: false,
  showCookiePolicy: false,
  showManageCookies: false,
  showOrganisationPrivacyNotice: false,
  showPrivacyPolicy: false,
  showPromotionalTermsAndConditions: false,
  showTermsAndConditions: false,
  showVivupPrivacyNotice: false,
  customFooterLinks: [],
}

let state = JSON.parse(JSON.stringify(defaultValue))

const valueFromQueryResult = function (
  result: GetLsLayoutDataQueryHookResult,
  organisationId?: string
): LSStoreLayoutContextData {
  const data = result.data

  const loading = result.loading

  let privacyNoticeUrl = undefined
  if (organisationId) {
    privacyNoticeUrl =
      data?.employeeOrganisation?.customPrivacyNoticeUrl ??
      `/organisations/${organisationId}/privacy_notice`
  }

  if (!loading) {
    const signOutLabel = data?.employeeOrganisation?.partner?.signOutLabel
      ? getSignOutLabel(data?.employeeOrganisation?.partner?.signOutLabel)
      : undefined

    state = {
      ...defaultValue,
      privacyNoticeUrl: privacyNoticeUrl,
      organisationPrivacyNotice:
        data?.employeeOrganisation?.organisationPrivacyNotice ?? false,
      currentUserId: data?.currentUser?.id || undefined,
      logo: data?.employeeOrganisation?.logo || undefined,
      condensedLogo: data?.employeeOrganisation?.condensedLogo || undefined,
      supportLinks:
        data?.employeeOrganisation?.lifestyleSavings?.supportLinks || [],
      organisationName: data?.employeeOrganisation?.name || "Vivup",
      loading: loading,
      availableLanguages: data?.employeeOrganisation?.availableLanguages || [],
      defaultLanguage:
        data?.employeeOrganisation?.defaultLanguage || fallbackLanguage,
      partnerJourney: data?.employeeOrganisation?.partner?.platformType,
      isLockBenefit:
        data?.employeeOrganisation?.partner?.lockUserToDestinationBenefit,
      isValidBenefitType: data?.employeeOrganisation?.isValidBenefitType,
      signOutLabel,
      showAccessibility:
        data?.employeeOrganisation?.footerLinks?.showAccessibility,
      showContactUs: data?.employeeOrganisation?.footerLinks?.showContactUs,
      showCookiePolicy:
        data?.employeeOrganisation?.footerLinks?.showCookiePolicy,
      showManageCookies:
        data?.employeeOrganisation?.footerLinks?.showManageCookies,
      showOrganisationPrivacyNotice:
        data?.employeeOrganisation?.footerLinks?.showOrganisationPrivacyNotice,
      showPrivacyPolicy:
        data?.employeeOrganisation?.footerLinks?.showPrivacyPolicy,
      showPromotionalTermsAndConditions:
        data?.employeeOrganisation?.footerLinks
          ?.showPromotionalTermsAndConditions,
      showTermsAndConditions:
        data?.employeeOrganisation?.footerLinks?.showTermsAndConditions,
      showVivupPrivacyNotice:
        data?.employeeOrganisation?.footerLinks?.showVivupPrivacyNotice,
      customFooterLinks:
        data?.employeeOrganisation?.footerLinks?.customFooterLinks || [],
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }
  return state
}

export const useLsStoreLayoutData = function (): LSStoreLayoutContextData {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetLsLayoutDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      locale: i18n.language,
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  return valueFromQueryResult(data, params.organisationId)
}

export const LSStoreLayoutContext = createContext(defaultValue)
