import React, { useEffect, useState } from "react"
import { motion } from "framer-motion"

import Box from "@mui/material/Box"
import List from "@mui/material/List"

import GenerateSlide, { Child, Parent } from "./GenerateSlide"
import DrawerItem from "../drawer/DrawerItem"
import onEnter from "../../../utils/onEnter"
import { useNavigate } from "react-router"
import {
  gtmLifestyleTopNavClick,
  gtmCycleToWorkBenefitNavigationClick,
} from "../gtm-events/CustomGtmEvents"
import { useLocation } from "react-router-dom"
export type ItemHierarchyType = {
  key: string
  name?: string
  featured?: boolean
  link?: string
  hide?: boolean
  external?: boolean
  children?: Child[] | Parent[]
  isBold?: boolean
  hasChild?: boolean
  imgUrl?: string | null
  isExtraOption?: boolean
  level?: string | null
  customLink?: string
  newTab?: boolean
}

export type StepMenuProps = {
  title: string
  itemHierarchy: ItemHierarchyType[]
  levels: number
  setOpen?: (state: boolean) => void
  changeUiLanguage?: (newLanguage: "en-GB" | "cy") => void
  pendingOrdersCount?: number
  clientAdminLayout?: boolean
}

const variants = {
  hidden: { x: "-100%" },
  shown: { x: 0 },
}

function StepMenu({
  title,
  itemHierarchy,
  levels,
  setOpen,
  changeUiLanguage,
  pendingOrdersCount,
  clientAdminLayout,
}: StepMenuProps) {
  levels--
  const [hideLevels, setHideLevels] = useState(Array(levels).fill(false))
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    setHideLevels(Array(levels).fill(false))
  }, [levels])

  const handleAction = (
    url: string | undefined,
    external: boolean | undefined,
    name: string | undefined,
    newTab: boolean | undefined
  ) => {
    if (name === "Car Benefit" && url && !external) {
      // dummy burger menu doesn't have external attribute, but "Car benefit" aren't in React
      window.location.href = url
    }

    if (typeof changeUiLanguage === "function" && url === undefined) {
      changeUiLanguage(name === "English" ? "en-GB" : "cy")
    }

    if (name === "Vivup highfive") {
      window.open(url, "_blank")
      return
    }
    let navTypeSelected = url
    if (url && url.includes("/")) {
      const navType = url.split("/")?.pop()?.split("?")[0]
      navTypeSelected = navType ? navType : ""
    }

    if (name) {
      const path = location.pathname
      if (path.includes("lifestyle_savings")) {
        gtmLifestyleTopNavClick(navTypeSelected, name, name)
      } else if (path.includes("cycle_to_work")) {
        gtmCycleToWorkBenefitNavigationClick(name)
      }
    }

    if (url && newTab) {
      window.open(url, "_blank")?.focus()
    } else if (url && external) {
      window.location.href = url
    } else if (url) {
      navigate(url)
    }
    if (setOpen) {
      setOpen(false)
    }
  }

  return (
    <>
      <Box
        role="presentation"
        sx={{
          paddingX: "2rem",
          paddingY: "0.75rem",
        }}
        className="step-menu"
      >
        <motion.div
          className={hideLevels[0] ? "d-none" : ""}
          animate={hideLevels[0] ? "hidden" : "shown"}
          variants={variants}
          transition={{ type: "spring", bounce: 0 }}
        >
          <List>
            <div className="step-menu__title">{title}</div>

            {itemHierarchy.map((child, index) => {
              return (
                <React.Fragment key={index}>
                  {child.children && child.hasChild ? (
                    <GenerateSlide
                      key={index}
                      currentLevel={1}
                      link={child.link}
                      external={child.external}
                      name={child.name ? child.name : ""}
                      featured={child.featured}
                      previousLabel={title}
                      children={child.children}
                      hideLevels={hideLevels}
                      setHideLevels={setHideLevels}
                      setOpen={setOpen}
                      index={index}
                      imgUrl={child.imgUrl}
                      level={child.level}
                      customLink={child.customLink}
                    />
                  ) : (
                    !child.hide &&
                    child.name && (
                      <DrawerItem
                        text={child.name}
                        onClick={() => {
                          child.name &&
                            handleAction(
                              child.link,
                              child.external,
                              child.name,
                              child.newTab
                            )
                        }}
                        onKeyDown={(event) =>
                          onEnter(event, () => {
                            child.name &&
                              handleAction(
                                child.link,
                                child.external,
                                child.name,
                                child.newTab
                              )
                          })
                        }
                        divider
                        isBold={child.isBold}
                        isExtraOption={child.isExtraOption}
                        pendingOrdersCount={pendingOrdersCount}
                        clientAdminLayout={clientAdminLayout}
                      />
                    )
                  )}
                </React.Fragment>
              )
            })}
          </List>
        </motion.div>
        <List>
          {hideLevels.slice(1).map((hideLevel, index) => (
            <motion.div
              id={`step-menu-slide-${index + 1}`}
              className={hideLevel ? "d-none" : ""}
              initial={{ x: "-100%" }}
              animate={hideLevel ? "hidden" : "shown"}
              variants={variants}
              transition={{ type: "spring", bounce: 0 }}
              key={index}
            />
          ))}

          <div id={`step-menu-slide-${levels}`} />
        </List>
      </Box>
    </>
  )
}

export default StepMenu
