import React, { CSSProperties, FC, useRef } from "react"
import { styled, Typography, css, useTheme } from "@mui/material"
import OpenWithIcon from "@mui/icons-material/OpenWith"
import { useDrag, useDrop } from "react-dnd"
import type { Identifier } from "dnd-core"
import { useNavigate } from "react-router-dom"
import { MainIconContainer } from "../explore-your-benefits/Card"
import { IconContainer } from "./Card"
import { useTranslation } from "react-i18next"

export const ItemTypes = {
  CARD: "card",
}

export interface CardProps {
  id: string | number
  text: string
  index: number
  lock: boolean
  moveCard: (dragIndex: number, hoverIndex: number) => void
  isEditing: boolean
  enabled: boolean
  canEdit: boolean
}

interface DragItem {
  index: number
  id: string
  type: string
}

const NoticeBoardGrid: FC<CardProps> = ({
  id,
  text,
  index,
  moveCard,
  lock,
  isEditing,
  enabled,
  canEdit,
}) => {
  const theme = useTheme()
  const { t, ready } = useTranslation("clientAdminPage")
  const cardRef = useRef<HTMLDivElement | HTMLButtonElement | null>(null)
  const iconRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const maxLength = 30
  const Title = (text: string) =>
    text.length > maxLength ? text.slice(0, maxLength - 3) + "..." : text

  const [, drop] = useDrop<
    DragItem,
    { hoverIndex: number },
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem) {
      if (!cardRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }
    },
    drop() {
      return { hoverIndex: index }
    },
  })

  const [{ opacity }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        return
      }

      const dropResult = monitor.getDropResult<{ hoverIndex: number }>()
      if (dropResult) {
        const { hoverIndex } = dropResult
        moveCard(item.index, hoverIndex)
      }
    },
    options: {
      dropEffect: "copy",
    },
  })

  const link = `noticeboard/${id}/edit`
  const handleClick = () => {
    canEdit && !isEditing && navigate(link)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      canEdit && !isEditing && navigate(link)
    }
  }

  if (!ready) {
    return null
  }
  return (
    <ContainerBox
      tabIndex={0}
      role="button"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      ref={(node) => {
        cardRef.current = node
        preview(drop(node))
      }}
      style={{
        opacity: lock ? "0.7" : opacity,
        pointerEvents: lock ? "none" : "auto",
        position: "relative",
      }}
      data-handler-id={cardRef.current}
    >
      {!lock && isEditing ? (
        <div ref={iconRef} style={iconStyle} {...drag(iconRef)}>
          <OpenWithIcon />
        </div>
      ) : (
        ""
      )}
      {!lock && !enabled && !isEditing && (
        <MainIconContainer>
          <IconContainer style={{ backgroundColor: theme.palette.grey[700] }}>
            <div>
              <p
                style={{
                  fontSize: "0.75rem",
                  padding: "0.625rem",
                  fontWeight: "bold",
                }}
              >
                {t("clientAdminPage:clientAdminReordering.hidden")}
              </p>
            </div>
          </IconContainer>
        </MainIconContainer>
      )}
      <ChipTitle>{Title(text)}</ChipTitle>
    </ContainerBox>
  )
}

export default NoticeBoardGrid

const ContainerBox = styled("div")`
  ${({ theme }) => css`
    cursor: mouse;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    over-flow: hidden;
    clip-path: view-box;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #35185f;
    display: grid;
    place-items: center;
    border-radius: 0.9375rem;
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.white.main};
    opacity: 1;
    text-decoration: none;

    min-width: 9.75rem;
    max-width: 23.3125rem;
    height: 3.0625rem;
    border: 3px solid #eae9ee;
    &&&:focus {
      border: 3px solid #1ea3eb;
      cursor: pointer;
    }
    &:hover {
      border: 3px solid #1ea3eb;
      cursor: pointer;
    }
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      width: unset;
      max-width: 16.25rem;
      height: 5rem;
      margin-left: 0;
    }
    &:hover {
      opacity: 0.6;
    }
  `}
`

const ChipTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 400;

    text-align: center;
    max-width: 7.5rem;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: 500;
    }
  `}
`

const iconStyle: CSSProperties = {
  position: "absolute",
  top: 6,
  right: 6,
  cursor: "grab",
  color: "white",
}
