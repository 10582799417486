import type { Identifier } from "dnd-core"
import { FC, useState } from "react"
import { useRef } from "react"
import { ConnectDragSource, useDrag, useDrop } from "react-dnd"
import { Box, styled, useTheme } from "@mui/material"
import OpenWithIcon from "@mui/icons-material/OpenWith"
import { CSSProperties } from "react"
import { MainIconContainer } from "../explore-your-benefits/Card"
import DoneIcon from "@mui/icons-material/Done"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
export const ItemTypes = {
  CARD: "card",
}

const benefitsRequireLinks = [
  "Lifestyle Savings (Local Discounts)",
  "Family Care",
  "Health and Wellbeing",
  "Your Care Wellbeing",
  "Family Pay",
  "Annual Leave Purchase",
]

export interface CardProps {
  id: string
  text: string
  index: number
  lock: boolean
  status: string
  isEditing: boolean
  image: string
  link: string
  relativeUrl?: string
  moveCard: (dragIndex: number, hoverIndex: number) => void
}

interface CardContentProps extends CardProps {
  drag: ConnectDragSource
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const Card: FC<CardProps> = ({
  id,
  text,
  index,
  moveCard,
  lock,
  status,
  isEditing,
  image,
  link,
  relativeUrl,
}) => {
  const cardRef = useRef<HTMLDivElement | HTMLButtonElement | null>(null)
  const navigate = useNavigate()
  const [hovered, setHovered] = useState(false)
  const [focused, setFocused] = useState(false)
  const [, drop] = useDrop<
    DragItem,
    { hoverIndex: number },
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    canDrop: () => !isNaN(parseInt(id)), // Prevent drop if id is not a number
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem) {
      if (!cardRef.current || isNaN(parseInt(id))) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex || isNaN(parseInt(item.id))) {
        return
      }
    },
    drop() {
      return { hoverIndex: index }
    },
  })

  const [{ opacity }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id, index }),
    canDrag: () => !isNaN(parseInt(id)), // Prevent drag if id is not a number
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        return
      }

      const dropResult = monitor.getDropResult<{ hoverIndex: number }>()
      if (dropResult) {
        const { hoverIndex } = dropResult
        moveCard(item.index, hoverIndex)
      }
    },
  })
  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)
  const handleFocus = () => setFocused(true)
  const handleBlur = () => setFocused(false)

  if (
    benefitsRequireLinks.includes(text) ||
    (!relativeUrl && (status === "Open" || status == "Hidden"))
  ) {
    const url = relativeUrl ? link : `local_benefit/${id}/edit`
    const handleClick = () => {
      !isEditing && navigate(url)
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        !isEditing && navigate(url)
      }
    }
    return (
      <>
        <div
          tabIndex={0}
          role="button"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={(node) => {
            cardRef.current = node
            preview(drop(node))
          }}
          style={{
            ...(CardStyle as React.CSSProperties),
            opacity: lock
              ? "0.7"
              : isEditing && isNaN(parseInt(id))
              ? "0.6"
              : opacity,
            backgroundImage: `url("${image}")`,
            pointerEvents: lock ? "none" : "auto",
            border:
              (focused && !isEditing) ||
              (hovered && !isEditing) ||
              (hovered && isEditing && !isNaN(parseInt(id)))
                ? "3px solid #1ea3eb"
                : "3px solid #eae9ee",
            cursor: "pointer",
          }}
          data-handler-id={cardRef.current}
        >
          <CardContent
            key={id}
            index={index}
            id={id}
            text={text}
            lock={lock}
            status={status}
            moveCard={moveCard}
            isEditing={isEditing}
            image={image}
            link={link}
            drag={drag}
          />
        </div>
      </>
    )
  }
  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={(node) => {
          cardRef.current = node
          preview(drop(node))
        }}
        style={{
          ...(CardStyle as React.CSSProperties),
          opacity: lock
            ? "0.7"
            : isEditing && isNaN(parseInt(id))
            ? "0.6"
            : opacity,
          backgroundImage: `url("${image}")`,
          pointerEvents: lock ? "none" : "auto",
          border:
            hovered && status === "Request to open"
              ? "3px solid #1ea3eb"
              : "3px solid #eae9ee",
          cursor: status === "Request to open" ? "pointer" : undefined,
        }}
        data-handler-id={cardRef.current}
      >
        <CardContent
          key={id}
          index={index}
          id={id}
          text={text}
          lock={lock}
          status={status}
          moveCard={moveCard}
          isEditing={isEditing}
          image={image}
          link={link}
          drag={drag}
        />
      </div>
    </>
  )
}

export default Card

const CardContent = ({
  id,
  text = "",
  status,
  isEditing,
  drag,
}: CardContentProps) => {
  const theme = useTheme()
  const { t, ready } = useTranslation("clientAdminPage")
  const iconRef = useRef<HTMLDivElement>(null)
  const truncatedLabel = text.length > 32 ? text.substring(0, 32) + "..." : text

  if (!ready) {
    return null
  }
  return (
    <div
      style={{
        background:
          "transparent linear-gradient(180deg, #35185f00 0%, #35185f 100%) 0% 0% no-repeat padding-box",
        width: "100%",
        height: "100%",
      }}
    >
      {status == "Open" && !isEditing && (
        <MainIconContainer>
          <IconContainer>
            <div
              aria-disabled
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DoneIcon
                aria-label={"done"}
                aria-hidden="false"
                sx={{
                  color: "white",
                  width: "0.9rem",
                  height: "0.9rem",
                  paddingLeft: "0.3125rem",
                }}
              />
              <p
                style={{
                  fontSize: "0.75rem",
                  padding: "0.625rem",
                  paddingLeft: "0.3125rem",
                  fontWeight: "bold",
                }}
              >
                {t("clientAdminPage:clientAdminReordering.open")}
              </p>
            </div>
          </IconContainer>
        </MainIconContainer>
      )}
      {status == "Request to open" && !isEditing && (
        <MainIconContainer>
          <IconContainer
            style={{ backgroundColor: theme.palette.secondary.main }}
          >
            <div aria-disabled>
              <p
                style={{
                  fontSize: "0.75rem",
                  padding: "0.625rem",
                  fontWeight: "bold",
                }}
              >
                {t("clientAdminPage:clientAdminReordering.requestToOpen")}
              </p>
            </div>
          </IconContainer>
        </MainIconContainer>
      )}
      {status == "Hidden" && !isEditing && (
        <MainIconContainer>
          <IconContainer style={{ backgroundColor: theme.palette.grey[700] }}>
            <div aria-disabled>
              <p
                style={{
                  fontSize: "0.75rem",
                  padding: "0.625rem",
                  fontWeight: "bold",
                }}
              >
                {t("clientAdminPage:clientAdminReordering.hidden")}
              </p>
            </div>
          </IconContainer>
        </MainIconContainer>
      )}

      {isNaN(parseInt(id)) && isEditing ? (
        <div style={lockIconStyle}>
          <LockOutlinedIcon color="primary" sx={{ fontSize: "2.714286rem" }} />
        </div>
      ) : (
        ""
      )}
      {!isNaN(parseInt(id)) && isEditing ? (
        <div ref={iconRef} style={iconStyle} {...drag(iconRef)}>
          <OpenWithIcon />
        </div>
      ) : (
        ""
      )}
      <CardLabel
        style={{ fontSize: "1.0625rem", fontWeight: "bold" }}
        title={text}
      >
        {truncatedLabel}
      </CardLabel>
    </div>
  )
}

const CardLabel = styled(Box)`
  & {
    text-align: center;
    color: white;
    position: absolute;
    line-height: normal;
    bottom: 1.5rem;
    left: 0.625rem;
    right: 0.625rem;
  }
`
const CardStyle = {
  cursor: "mouse",
  position: "relative",
  maxWidth: "10rem",
  width: "95%",
  height: "9.5rem",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: "0rem",
  margin: "0px",
  borderRadius: "1rem",
  overflow: "hidden",
  clipPath: "view-box",
  boxShadow: "0px 3px 6px #00000029",
  backgroundColor: "#35185f",
}
export const IconContainer = styled(Box)`
  background-color: #488200;
  border-radius: 3.125rem;
  max-width: 8.393rem;
  width: 100%;
  height: 1.393rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`
const iconStyle: CSSProperties = {
  position: "absolute",
  top: 5,
  right: 10,
  cursor: "grab",
  color: "white",
}
const lockIconStyle: CSSProperties = {
  position: "absolute",
  top: 5,
  right: 10,
  cursor: "auto",
}
