import { Container, Grid, styled } from "@mui/material"
import ImgMediaCard, { ImgMediaCardProps } from "./Card"
import { useMediaQuery, useTheme } from "@mui/material"
import { useStoreHomeData } from "../StoreHomeContext"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Pagination, Keyboard } from "swiper/modules"

export type LsFeatureCardGridProps = {
  data: ImgMediaCardProps[]
}

export default function LsFeatureCardGrid() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  const {
    data: { featureTiles },
  } = useStoreHomeData()

  return (
    <Container>
      <div>
        {!isDesktop ? (
          <Wrapper />
        ) : (
          <Container
            sx={{
              paddingLeft: "0rem!important",
              margin: "0 auto",
              minWidth: "50rem",
              maxWidth: "69.125rem",
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="center"
              sx={{
                paddingRight: "0rem!important",
                marginLeft: "0rem!important",
              }}
            >
              <Grid
                item
                md={11}
                lg={10}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.8rem",
                  justifyContent: "center",
                  paddingRight: "0rem!important",
                }}
              >
                {featureTiles.length > 0 &&
                  featureTiles.map(
                    ({ description, image, name, url }, index) => {
                      return (
                        <Grid
                          item
                          xs={4}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            Width: "21.313rem",
                            paddingRight: "0rem!important",
                            zIndex: 1,
                          }}
                          key={`feature-tiles-${index}`}
                        >
                          <ImgMediaCard
                            index={index}
                            title={name}
                            imageUrl={image}
                            description={description}
                            url={url}
                          />
                        </Grid>
                      )
                    }
                  )}
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </Container>
  )
}

const Wrapper = () => {
  const {
    data: { featureTiles },
  } = useStoreHomeData()

  return (
    <div>
      <Swiper
        modules={[Pagination, Keyboard]}
        slidesPerView={1}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
        }}
        keyboard={{ enabled: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        style={{ height: "31rem", zIndex: "10" }}
      >
        {featureTiles.length > 0 &&
          featureTiles.map(({ description, image, name, url }, index) => (
            <SwiperSlide key={`feature-tiles-${index}`}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid item>
                  <ImgMediaCard
                    index={index}
                    title={name}
                    imageUrl={image}
                    description={description}
                    url={url}
                  />
                </Grid>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>

      <PaginationContainer className="swiper-pagination" />
    </div>
  )
}
export const PaginationContainer = styled("div")`
  margin-top: 0.5rem;
  text-align: center;
  bottom: unset !important;
  .swiper-pagination-bullet {
    width: 0.5rem;
    height: 0.5rem;
  }
`
