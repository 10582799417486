// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
// aa (algolia analytics) comes from _algolia.html.haml
const ALGOLIA_INDEX = window.ENV["ALGOLIA_INDEX"]

export const clickedObjectIDsAfterSearch = (
  queryID: string,
  objectIDs: string[],
  positions: number[]
) => {
  aa("clickedObjectIDsAfterSearch", {
    index: ALGOLIA_INDEX,
    eventName: "Object clicked",
    queryID: queryID,
    objectIDs: objectIDs,
    positions: positions,
  })
}

export const convertedObjectIDsAfterSearch = (
  queryID: string,
  eventName: string,
  objectIDs: string[]
) => {
  aa("convertedObjectIDsAfterSearch", {
    index: ALGOLIA_INDEX,
    eventName: eventName,
    queryID: queryID,
    objectIDs: objectIDs,
  })
}

export const addedToCartObjectIDsAfterSearch = (
  queryID: string,
  objectIDs: string[],
  objectData?: {
    queryID?: string
    pricePerMonth?: number
    term?: number
  }[]
) => {
  aa("addedToCartObjectIDsAfterSearch", {
    index: ALGOLIA_INDEX,
    eventName: "Object added to cart",
    queryID: queryID,
    objectIDs: objectIDs,
    objectData: objectData,
    currency: "GBP",
  })
}

export const purchasedObjectIDsAfterSearch = (
  queryID: string,
  objectIDs: string[],
  objectData?: {
    queryID?: string
    pricePerMonth?: number
    term?: number
  }[]
) => {
  aa("purchasedObjectIDsAfterSearch", {
    index: ALGOLIA_INDEX,
    eventName: "Object purchased",
    queryID: queryID,
    objectIDs: objectIDs,
    objectData: objectData,
    currency: "GBP",
  })
}

export const clickedObjectIDs = (objectIDs: string[], eventName?: string) => {
  aa("clickedObjectIDs", {
    index: ALGOLIA_INDEX,
    eventName: eventName || "Object clicked",
    objectIDs: objectIDs,
  })
}

export const convertedObjectIDs = (eventName: string, objectIDs: string[]) => {
  aa("convertedObjectIDs", {
    index: ALGOLIA_INDEX,
    eventName: eventName,
    objectIDs: objectIDs,
  })
}

export const addedToCartObjectIDs = (
  objectIDs: string[],
  objectData?: {
    queryID?: string
    pricePerMonth?: number
    term?: number
  }[]
) => {
  aa("addedToCartObjectIDs", {
    index: ALGOLIA_INDEX,
    eventName: "Object added to cart",
    objectIDs: objectIDs,
    objectData: objectData,
    currency: "GBP",
  })
}

export const purchasedObjectIDs = (
  objectIDs: string[],
  objectData?: {
    queryID?: string
    pricePerMonth?: number
    term?: number
  }[]
) => {
  aa("purchasedObjectIDs", {
    index: ALGOLIA_INDEX,
    eventName: "Object purchased",
    objectIDs: objectIDs,
    objectData: objectData,
    currency: "GBP",
  })
}

export const clickedFilters = (filters: string[]) => {
  aa("clickedFilters", {
    index: ALGOLIA_INDEX,
    eventName: "Filter clicked",
    filters: filters,
  })
}

export const viewedObjectIDs = (objectIDs: string[]) => {
  aa("viewedObjectIDs", {
    index: ALGOLIA_INDEX,
    eventName: "Viewed object",
    objectIDs: objectIDs,
  })
}
