import { StyledInnerContainer } from "../../shared/card/InnerContainer"
import { Box, styled, css } from "@mui/material"
import CardPopUp from "./CardPopUp"
import {
  gtmLifestyleCouroselClick,
  gtmLifestyleSavingTileClick,
} from "../../shared/gtm-events/CustomGtmEvents"
import {
  SharedVoucherContainer,
  Text,
  VoucherText,
  SharedSavePercentage,
  BrandName as Brand,
  SharedTextContainer,
  StyledImageContainer,
  LSProductCardImage,
} from "./SharedStyles"
import OuterContainer from "../../shared/card/OuterContainer"
import { DiscountType } from "../LSStoreContext"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import ResponsiveEllipsis from "../../../utils/ResponsiveEllipsis"

const NormalSizeCard = ({
  data,
  indexCarousel,
}: {
  data: DiscountType
  indexCarousel: number
}) => {
  const { featuredTagImage, featuredTagName } = data

  return (
    <OuterContainer
      featuredTagImage={featuredTagImage}
      featuredTagAltText={featuredTagName}
    >
      <NormalCardContent data={data} indexCarousel={indexCarousel} />
    </OuterContainer>
  )
}

export default NormalSizeCard

// Carousel calls NormalCardContent because promoTag is injected into OuterContainer, but Carousel has its own CarouselWrapper to deal with promoTag and focus.
export const NormalCardContent = ({
  data,
  position,
  categories,
  indexCarousel,
}: {
  data: DiscountType
  position?: number
  categories?: string
  indexCarousel: number
}) => {
  const {
    id,
    discountCode: promoCode,
    image,
    kind,
    name,
    savingType,
    savingsText: mainText,
    value,
    showSavingType,
    link,
    index,
  } = data
  const { t, ready } = useTranslation("lifestyleSavings")
  const [popUpOpen, setPopUpOpen] = useState(false)

  const shouldShowSavingsText = Boolean(value)
  const savingText =
    value &&
    (kind === "percent"
      ? `${value.replace(/\.?00+$/, "")}%`
      : `£${value.replace(/\.?00+$/, "")}`)
  const textValue = mainText.match(/(\d+)%/g)?.[0] || "0"
  const parseAmount = value ? value : parseInt(textValue)

  const HandleCarouselClick = () => {
    setPopUpOpen(true)
    const eventName = "lifestyle_savings_lp_carouse_" + position + "_click"

    gtmLifestyleCouroselClick(
      eventName,
      categories,
      indexCarousel + 1,
      name,
      id,
      parseAmount,
      savingType
    )
    gtmLifestyleSavingTileClick(
      id,
      savingType,
      name,
      indexCarousel + 1,
      index,
      parseAmount,
      categories
    )
  }

  if (!ready) {
    return null
  }
  return (
    <>
      <InnerContainer onClick={() => HandleCarouselClick()}>
        <StyledImageContainer>
          <LSProductCardImage src={image} alt={name} />
        </StyledImageContainer>

        <FlexContainer>
          <Box sx={{ margin: "4px 0" }} title={name}>
            <Brand as="div">
              <ResponsiveEllipsis
                style={{ width: "100%" }}
                text={name}
                maxLine={1}
                ellipsis="..."
                trimRight
                basedOn="words"
              />
            </Brand>
          </Box>
          {/* need a div/ Box to wrap around for ios 11 */}
          <Box title={mainText}>
            <Text as="div">
              <ResponsiveEllipsis
                style={{ width: "100%" }}
                text={mainText}
                maxLine={2}
                ellipsis="..."
                trimRight
                basedOn="words"
              />
            </Text>
          </Box>

          {savingText && (
            <Saving variant="h3">
              {shouldShowSavingsText &&
                `${t("landingPage.carousel.save")} ${savingText}`}
            </Saving>
          )}
        </FlexContainer>
        {showSavingType && (
          <BottomRightVoucher>
            <VoucherText variant="body2">{savingType}</VoucherText>
          </BottomRightVoucher>
        )}
      </InnerContainer>
      <CardPopUp
        parseAmount={parseAmount}
        indexCarousel={index}
        index={indexCarousel}
        categories={categories}
        name={name}
        value={value}
        savingType={savingType}
        id={id}
        title={name}
        description={mainText}
        savingText={
          shouldShowSavingsText &&
          `${t("landingPage.carousel.save")} ${savingText}`
        }
        discountCode={promoCode}
        link={link}
        open={popUpOpen}
        setOpen={setPopUpOpen}
      />
    </>
  )
}

export const BottomRightVoucher = styled(SharedVoucherContainer)`
  width: 9.25rem;
  height: 1.625rem;
  border-top-left-radius: ${({ theme }) => theme.boxBorderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.boxBorderRadius};
`
const Saving = styled(SharedSavePercentage)`
  align-self: end;
`

const InnerContainer = styled(StyledInnerContainer)`
  ${({ theme }) => css`
    width: 15.875rem;
    height: 19.6875rem;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      width: 100%;
      max-width: 15.875rem;
      height: 19.6875rem;
      align-items: center;
    }
  `}
`

const FlexContainer = styled(SharedTextContainer)`
  display: grid;
  height: 32%;
  width: 82%;
  grid-template-rows: auto auto 1fr;
  padding-top: 0;
`
