import { KeyboardEvent } from "react"
import classNames from "classnames"

import MegaMenuTab from "./Tab"
import { promotionalItem } from "../../../utils/specialPromoHelper"
import { DepartmentType } from "../../layout/formatMegaMenuData"
import { Link } from "@mui/material"
import { HealthAndWellbeing as HealthAndWellbeingType } from "../../../graphqGenaretedTypes"
import MegaMenuHealthAndWellbeingTab from "./HealthAndWellbeingTab"
import FamilyCareMegaMenu from "./FamilyCareMegaMenu"
import FamilyPayMegaMenu from "./FamilyPayMegaMenu"
import { useTranslation } from "react-i18next"
import {
  FamilyCare as FamilyCareType,
  FamilyPay as FamilyPayType,
} from "../../../graphqGenaretedTypes"

export type MegaMenuItemProps = {
  active: boolean
  setActive: (state: boolean) => void
  name: string
  featured?: boolean
  link: string
  storeLink: string
  categories?: DepartmentType[] | null
  imageUrl?: string
  extraOptions?: {
    label: string
    link: string
  }[]
  external?: boolean
  healthAndWellbeing?: HealthAndWellbeingType
  familyCareItem: FamilyCareType
  familyPayItem: FamilyPayType
}

function MegaMenuItem({
  active,
  setActive,
  name,
  link,
  storeLink,
  featured = false,
  categories,
  imageUrl,
  extraOptions,
  external = false,
  healthAndWellbeing,
  familyCareItem,
  familyPayItem,
}: MegaMenuItemProps) {
  const { t, ready } = useTranslation("layout")
  const actionKey = (e: KeyboardEvent) => {
    if (e.key == "ArrowDown") {
      e.preventDefault()
      setActive(true)
    } else if (e.key == "ArrowUp") {
      e.preventDefault()
      setActive(false)
    }
  }

  if (!ready) {
    return null
  }

  return (
    <>
      <Link
        component={external ? "a" : Link}
        href={link}
        target={name === "Vivup highfive" ? "_blank" : undefined}
        className={classNames("mega-menu-item", {
          "mega-menu-item--active": active,
          "mega-menu-item--featured": featured,
          "mega-menu-item--promo": promotionalItem(name),
        })}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        onClick={() => setActive(false)}
        onKeyDown={actionKey}
      >
        {t("menu." + name)}
      </Link>
      {categories && !promotionalItem(name) && (
        <MegaMenuTab
          name={name}
          link={storeLink}
          categories={categories}
          imageUrl={imageUrl}
          active={active}
          setActive={setActive}
          extraOptions={extraOptions}
        />
      )}
      {healthAndWellbeing?.usefulLinks && name == "healthAndWellbeing" && (
        <MegaMenuHealthAndWellbeingTab
          categories={healthAndWellbeing.usefulLinks}
          level={healthAndWellbeing.level}
          active={active}
          setActive={setActive}
          link={healthAndWellbeing.link}
        />
      )}
      {familyCareItem?.usefulLinks && name == "familyCare" && (
        <FamilyCareMegaMenu
          categories={familyCareItem.usefulLinks}
          active={active}
          setActive={setActive}
          link={link}
        />
      )}
      {familyPayItem?.usefulLinks && name == "familyPay" && (
        <FamilyPayMegaMenu
          categories={familyPayItem.usefulLinks}
          active={active}
          setActive={setActive}
          link={link}
        />
      )}
    </>
  )
}

export default MegaMenuItem
