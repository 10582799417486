import { Box, Grid, useMediaQuery, useTheme } from "@mui/material"
import SmallBenefitCard from "./benefit-card/SmallBenefitCard"
import { BenefitCard } from "./benefit-card/BenefitCard"
import { BenefitCardDataType } from "./benefit-card/BenefitCard"

const MobileGrid = ({
  benefitCardData,
}: {
  benefitCardData: BenefitCardDataType[]
}) => {
  const theme = useTheme()
  const isNarrow = useMediaQuery(theme.breakpoints.down("sm"))
  // skeleton copied from ProductCardGrid.tsx, which can't be reused directly
  // className is only used to identify the elements in DOM, should be removed when finished
  return (
    <Box
      className="mobile-card-box"
      sx={{
        flexGrow: 1,
        margin: {
          md: "1.5rem",
          xs: "0 0 1.5rem 0",
        },
        position: "relative",
      }}
    >
      <Grid container spacing={4} className="mobile-card-grid">
        {benefitCardData.map((data, index) => {
          if (isNarrow) {
            return (
              <Grid item xs={6} md={4} lg={3} key={index}>
                <SmallBenefitCard benefitCardData={data} index={index} />
              </Grid>
            )
          }
          return (
            <Grid item xs={6} md={4} lg={3} key={index}>
              <BenefitCard benefitCardData={data} index={index} />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default MobileGrid
