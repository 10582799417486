import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import { Box, css, styled } from "@mui/material"
import CarouselCardOuterContainer from "../../lifestyle-savings/product-card/CarouselWrapper"
import { NormalCardContent } from "../../lifestyle-savings/product-card/NormalCard"
import { Discount, DiscountCategory } from "../../../graphqGenaretedTypes"
import { LeftArrow } from "./LeftArrow"
import { RightArrow } from "./RightArrow"
import {
  LSS_CARDS_RESPONSIVE_BREAKPOINTS,
  C2W_CARDS_RESPONSIVE_BREAKPOINTS,
} from "./RESPONSIVE_BREAKPOINTS"
import ProductCard, { ProductType } from "../../store/product-card/ProductCard"
import { genericTypePredicate } from "../../../utils/genericTypePredicate"
import disableCarouselAriaHidden from "../../../utils/disableCarouselAriaHidden"
import { Settings } from "react-slick"
import { DiscountType } from "../../lifestyle-savings/LSStoreContext"

const numOfCardsToShow = 5
const adjustmentOnLastCard = 0.5
const adjustedNumOfCardsToShow = numOfCardsToShow - adjustmentOnLastCard // 4.5

const CarouselSlider = ({
  linearGradient,
  slideData,
  position, // for LSS GTM event, hence optional
  categories, // for LSS GTM event, hence optional
  store = "lifestyle-savings",
  isCycleToWork,
  carouselSliderId,
  isRecommendedProduct,
}: {
  linearGradient: string
  slideData: DiscountCategory[] | ProductType[] | Discount[]
  position?: number
  categories?: string
  store: "lifestyle-savings" | "cycle_to_work"
  isCycleToWork?: boolean
  carouselSliderId: string
  isRecommendedProduct?: string
}) => {
  const inputLength = slideData ? slideData.length : 0

  const [slideIndex, setSlideIndex] = useState<null | number>(
    inputLength > 0 ? 0 : null
  )
  const [tabFocusIndex, setTabFocusIndex] = useState(0)
  const sliderRef = useRef<Slider | null>(null)

  useEffect(() => {
    disableCarouselAriaHidden(carouselSliderId)
  }, [carouselSliderId])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    prevArrow: (
      <LeftArrow
        tabFocusIndex={tabFocusIndex}
        setTabFocusIndex={setTabFocusIndex}
        sliderRef={sliderRef}
      />
    ),
    nextArrow: <RightArrow />,
    afterChange: (index: number) => {
      setSlideIndex(index)
      disableCarouselAriaHidden(carouselSliderId)
    },
    rows: 1,
    slidesPerRow: 1,
    slidesToShow: adjustedNumOfCardsToShow,

    responsive:
      store === "lifestyle-savings"
        ? LSS_CARDS_RESPONSIVE_BREAKPOINTS
        : C2W_CARDS_RESPONSIVE_BREAKPOINTS,
  }

  const handleTabFocus = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const target = e?.currentTarget?.parentNode?.parentNode as HTMLDivElement
    if (typeof target.dataset.index === "string") {
      const focusIndex = parseInt(target.dataset.index)
      setTabFocusIndex && setTabFocusIndex(focusIndex)
      setSlideIndex && setSlideIndex(focusIndex)
      sliderRef?.current && sliderRef.current.slickGoTo(focusIndex)
    }
  }

  return (
    <StyledSlider
      {...settings}
      ref={sliderRef}
      slideIndex={slideIndex}
      inputLength={inputLength}
      numOfCardsToShow={numOfCardsToShow}
      adjustedSlidesToShow={adjustedNumOfCardsToShow}
      linearGradient={linearGradient}
    >
      {slideData &&
        slideData.map((data, index) => {
          if (
            store === "lifestyle-savings" &&
            genericTypePredicate<DiscountType>(data)
          ) {
            const { featuredTagImage, featuredTagName } = data
            return (
              <CarouselCardOuterContainer
                key={`carousel-slider-${index}`}
                handleTabFocus={handleTabFocus}
                featuredTagImage={featuredTagImage ?? ""}
                featuredTagAltText={featuredTagName ?? ""}
              >
                <NormalCardContent
                  data={data}
                  position={position}
                  categories={categories}
                  indexCarousel={index}
                />
              </CarouselCardOuterContainer>
            )
          }
          if (
            store === "cycle_to_work" &&
            genericTypePredicate<ProductType>(data)
          ) {
            return (
              <C2WCardContainer
                handleTabFocus={handleTabFocus}
                key={`carousel-slider-${index}`}
              >
                <ProductCard
                  index={index}
                  product={data}
                  isResponsive={false}
                  isCycleToWork={isCycleToWork}
                  isRecommendedProduct={isRecommendedProduct}
                />
              </C2WCardContainer>
            )
          }
        })}
    </StyledSlider>
  )
}

export default CarouselSlider

const C2WCardContainer = ({
  children,
  handleTabFocus,
}: {
  children?: React.ReactNode
  handleTabFocus: (e: React.KeyboardEvent<HTMLDivElement>) => void
}) => {
  /*
    A wrapper around ProductCard (from H&E) so that handleTabFocus() will work correctly
  */
  return (
    <Box
      onKeyUp={(e) => {
        if (e.key === "Tab") return handleTabFocus(e)
      }}
    >
      {children}
    </Box>
  )
}

const StyledSlider = styled(Slider as React.ComponentType<Settings>, {
  shouldForwardProp: (prop) => prop !== "inputLength",
})<{
  slideIndex: number | null
  inputLength: number
  numOfCardsToShow: number
  adjustedSlidesToShow: number
  linearGradient: string
}>(
  ({
    theme,
    slideIndex,
    inputLength,
    numOfCardsToShow,
    adjustedSlidesToShow,
    linearGradient,
  }) => css`
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      ${inputLength >= numOfCardsToShow &&
      slideIndex !== null &&
      slideIndex < inputLength - adjustedSlidesToShow &&
      css`
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: -0.125rem;
          width: 5rem;
          height: 100%;
          display: block;
          background: transparent
            linear-gradient(
              272deg,
              ${linearGradient} 0%,
              ${linearGradient.concat("00")} 100%
            );
          opacity: 1;
          pointer-events: none;
        }
      `}
      ${inputLength >= numOfCardsToShow &&
      slideIndex !== null &&
      slideIndex > 0 &&
      css`
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -0.625rem;
          width: 5rem;
          height: 100%;
          display: block;
          background: transparent
            linear-gradient(
              92deg,
              ${linearGradient} 0%,
              ${linearGradient.concat("00")} 100%
            );
          opacity: 1;
          pointer-events: none;
          z-index: 1;
        }
      `}
    }

    .slick-disabled {
      /* to hide left or right arrow when it's not needed */
      display: none !important;
    }

    .slick-track {
      padding: 2.25rem 0;
    }
  `
)
