import { createContext } from "react"
import { useParams } from "react-router"
import { RoundelProps } from "../shared/Roundel"
import { BannerCarouselProps } from "../shared/banner-carousel/BannerCarousel"
import {
  GetStoreHomeDataQueryHookResult,
  useGetStoreHomeDataQuery,
  useGetSavingsCalculatorDataQuery,
  BrochureGroup as BrochureGroupType,
} from "../../graphqGenaretedTypes"
import { ProductType } from "./product-card/ProductCard"
import { useTranslation } from "react-i18next"

export type StoreHomeContextData = {
  popularBikes: ProductType[]
  bikeDepartmentId?: number
  featuredCategories: RoundelProps[]
  allDepartments: RoundelProps[]
  banners: BannerCarouselProps[]
  brochureGroups: BrochureGroupType[]
  loading: boolean
  quoteLimit?: number
}

const defaultValue: StoreHomeContextData = {
  popularBikes: [],
  featuredCategories: [],
  allDepartments: [],
  banners: [],
  brochureGroups: [],
  loading: true,
}

type GetSavingCalculationsParams = {
  costOfQuote: string
  brochureGroupId: string
  employeeSalary?: string
}

export const GetSavingCalculations = ({
  costOfQuote,
  brochureGroupId,
  employeeSalary = "",
}: GetSavingCalculationsParams) => {
  const { organisationId = "", schemeType = "" } = useParams()
  const { i18n } = useTranslation()

  const variables = {
    organisationId,
    schemeType,
    costOfQuote: costOfQuote.toString(),
    brochureGroupId,
    employeeSalary: employeeSalary.toString(),
    locale: i18n.language,
  }

  const skip = costOfQuote === "" || brochureGroupId === ""

  return useGetSavingsCalculatorDataQuery({
    variables,
    skip,
    errorPolicy: "all",
  })
}

let state = JSON.parse(JSON.stringify(defaultValue))

const queryDataToFeaturedCategories: (
  queryData: GetStoreHomeDataQueryHookResult
) => RoundelProps[] = function (queryData) {
  let result: RoundelProps[] = []
  const data = queryData.data

  if (data?.employeeOrganisation?.scheme?.featuredCategories) {
    result = data?.employeeOrganisation?.scheme?.featuredCategories.map(
      (item) => {
        const isCycleToWork = item.link.includes("cycle_to_work")
        return {
          label: item.label || undefined,
          imageUrl: item.imageUrl,
          link: item.link,
          background: isCycleToWork ? "gray" : "white",
        }
      }
    )
  }

  return result
}

const queryDataToAllDepartments: (
  queryData: GetStoreHomeDataQueryHookResult
) => RoundelProps[] = function (queryData) {
  let result: RoundelProps[] = []
  const data = queryData.data

  if (data?.employeeOrganisation?.scheme?.store?.departments) {
    result = data.employeeOrganisation.scheme.store.departments.map(
      (department) => {
        return {
          label: department.name,
          imageUrl: department.roundel.imageUrl,
          link: department.roundel.link,
          background: "gray",
        }
      }
    )
  }

  return result
}

const queryDataToPopularBikes: (
  queryData: GetStoreHomeDataQueryHookResult
) => ProductType[] = function (queryData) {
  let result: ProductType[] = []
  const data = queryData.data

  if (data?.employeeOrganisation?.scheme?.popularBikes) {
    // the type definition says ID is a number
    // but an actual ID is something like "4336-1"
    // which causes the type error.
    // Change the type definition?
    result = data.employeeOrganisation.scheme.popularBikes.map((data) => {
      return {
        brochureGroupId: data.brochureGroupId,
        image: data.image,
        price: String(data.price),
        totalAmount: data.totalAmount,
        productTagName: data.productTagName || undefined,
        productTagImage: data.productTagImage || undefined,
        term: data.term,
        brand: data.brand,
        variant: data.variant,
        category: data.category,
        availableSizes: data.availableSizes,
        sku: data.sku ?? "",
        department: data.department,
        link: data.url,
        id: Number(data.id),
        title: data.name,
        lowertag: true,
        altnativeImgText: "",
        flashdealtag: false,
        storeName: "cycle_to_work",
      }
    })
  }
  return result
}

const queryDataToBanners: (
  queryData: GetStoreHomeDataQueryHookResult
) => BannerCarouselProps[] = function (queryData) {
  let result: BannerCarouselProps[] = []
  const data = queryData.data

  if (data?.employeeOrganisation?.scheme?.banners) {
    result = data?.employeeOrganisation?.scheme?.banners.map((item) => {
      return {
        desktopImageUrl: item.desktopImageUrl,
        mobileImageUrl: item.mobileImageUrl,
        link: item.link,
        title: item?.title || undefined,
        subtitle: item?.subtitle || undefined,
      }
    })
  }

  return result
}

export const StoreHomeContext = createContext(defaultValue)

export const useStoreHomeData: () => {
  data: StoreHomeContextData
} = function () {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetStoreHomeDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: params.schemeType ?? "",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  if (!data.loading) {
    const popularBikes = queryDataToPopularBikes(data)
    const featuredCategories = queryDataToFeaturedCategories(data)
    const allDepartments = queryDataToAllDepartments(data)
    const banners = queryDataToBanners(data)
    const brochureGroups =
      data.data?.employeeOrganisation?.scheme?.brochureGroups
    const bikeDepartmentId =
      data.data?.employeeOrganisation?.scheme?.bikeDepartmentId
    const quoteLimit = data.data?.employeeOrganisation?.scheme?.quoteLimit
    const loading = data.loading

    state = {
      ...defaultValue,
      bikeDepartmentId,
      popularBikes,
      featuredCategories,
      allDepartments,
      brochureGroups,
      banners,
      loading,
      quoteLimit,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
