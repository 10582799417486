import { useState, useEffect, useRef } from "react"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import FileUpload from "@emagtechlabs/ckeditor5-file-upload/fileupload"
import SimpleFileUploadAdapter from "@emagtechlabs/ckeditor5-file-upload/src/simplefileuploadadapter"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"

RichTextEditor.propTypes = {
  data: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  variant: PropTypes.string,
  setCharacterCount: PropTypes.func,
}

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  AutoLink,
  Autosave,
  Bold,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  FullPage,
  Heading,
  HtmlEmbed,
  ImageBlock,
  ImageCaption,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  SelectAll,
  SimpleUploadAdapter,
  SourceEditing,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
  WordCount,
} from "ckeditor5"

import "ckeditor5/ckeditor5.css"

export function RichTextEditor({
  data = "",
  fieldName,
  variant = "full",
  setCharacterCount,
}) {
  const formik = useFormikContext()
  const editorRef = useRef(null)
  const [isLayoutReady, setIsLayoutReady] = useState(false)

  let toolbar = []

  if (variant === "light") {
    toolbar = {
      items: [
        "undo",
        "redo",
        "bold",
        "underline",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
      ],
      shouldNotGroupWhenFull: false,
    }
  } else {
    toolbar = {
      items: [
        "undo",
        "redo",
        "bold",
        "underline",
        "italic",
        "|",
        "fontFamily",
        "fontSize",
        "fontColor",
        "fontBackgroundColor",
        "alignment",
        "heading",
        "findAndReplace",
        "|",
        "bulletedList",
        "numberedList",
        "outdent",
        "indent",
        "|",
        "link",
        "imageInsert",
        "fileUpload",
        "insertTable",
        "htmlEmbed",
        "mediaEmbed",
        "|",
        "sourceEditing",
        "selectAll",
        "accessibilityHelp",
      ],
      shouldNotGroupWhenFull: false,
    }
  }

  useEffect(() => {
    setIsLayoutReady(true)

    return () => setIsLayoutReady(false)
  }, [])

  const editorConfig = {
    simpleFileUpload: {
      attachmentUploadUrl: "/ckeditor/attachment_files",
      imageUploadUrl: "/ckeditor/pictures",
      fileTypes: [".pdf", ".doc", ".docx", ".xls", ".xlsx"],
    },
    toolbar: toolbar,
    plugins: [
      AccessibilityHelp,
      Alignment,
      AutoImage,
      AutoLink,
      Autosave,
      Bold,
      Essentials,
      FileUpload,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      FullPage,
      HtmlEmbed,
      Heading,
      ImageBlock,
      ImageCaption,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      MediaEmbed,
      Paragraph,
      PasteFromOffice,
      SelectAll,
      SimpleFileUploadAdapter,
      SimpleUploadAdapter,
      SourceEditing,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      Underline,
      Undo,
      WordCount,
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    image: {
      toolbar: [
        "toggleImageCaption",
        "imageTextAlternative",
        "|",
        "imageStyle:alignBlockLeft",
        "imageStyle:block",
        "imageStyle:alignBlockRight",
        "|",
        "resizeImage",
      ],
      styles: {
        options: ["alignBlockLeft", "block", "alignBlockRight"],
      },
    },
    link: {
      defaultProtocol: "https://",
      decorators: {
        openInNewTab: {
          mode: "manual",
          label: "Open in a new tab",
          defaultValue: true,
          attributes: {
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
      },
    },
    mediaEmbed: { previewsInData: true },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    wordCount: {
      onUpdate: (stats) => {
        if (setCharacterCount) {
          setCharacterCount(stats.characters)
        }
      },
    },
  }

  return (
    <div ref={editorRef}>
      {isLayoutReady && (
        <CKEditor
          id={fieldName}
          name={fieldName}
          editor={ClassicEditor}
          config={editorConfig}
          data={data}
          onChange={(_, editor) => {
            const newData = editor.getData()
            formik.setFieldValue(fieldName, newData) // Use setFieldValue instead of handleChange
          }}
        />
      )}
    </div>
  )
}
