import React, { useState } from "react"
import { Typography, Container, Box, styled, css } from "@mui/material"
import { StyledInnerContainer } from "../../../shared/card/InnerContainer"
import { BottomRightVoucher } from "../../product-card/NormalCard"
import { VoucherText } from "../../product-card/SharedStyles"
import { ImageWrapper } from "../../product-card/SmallCard"
import OuterContainer from "../../../shared/card/OuterContainer"
import CardPopUp from "../../product-card/CardPopUp"
import { gtmLifestyleSavingTileClick } from "../../../shared/gtm-events/CustomGtmEvents"
import ResponsiveEllipsis from "../../../../utils/ResponsiveEllipsis"

export type BenefitCardDataType = {
  id?: string
  image?: string
  link?: string
  name?: string
  savingText?: string
  voucher?: string
  index?: number
  value?: number
}

export const BenefitCard = ({
  benefitCardData,
  index,
}: {
  benefitCardData: BenefitCardDataType
  index: number
}) => {
  const {
    id,
    name,
    link,
    image,
    savingText,
    value,
    voucher = "Local Benefit",
  } = benefitCardData
  const [popUpOpen, setPopUpOpen] = useState(false)

  const HandleCardOpen = () => {
    setPopUpOpen(true)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    gtmLifestyleSavingTileClick(id, voucher, name, index + 1, value)
  }
  return (
    <OuterContainer>
      <StyledInnerContainer onClick={HandleCardOpen}>
        <ImageWrapper style={{ height: "60%" }}>
          <img src={image} style={{ width: "80%" }} alt={name} />
        </ImageWrapper>
        <TextContainer>
          <TextRow title={name}>
            <ContentTitleText as="div">
              <ResponsiveEllipsis
                style={{ width: "100%" }}
                text={name}
                maxLine={1}
                ellipsis="..."
                trimRight
                basedOn="words"
              />
            </ContentTitleText>
          </TextRow>

          <TextRow title={savingText}>
            <ContentText as="div">
              <ResponsiveEllipsis
                style={{ width: "100%" }}
                text={savingText}
                maxLine={2}
                ellipsis="..."
                trimRight
                basedOn="words"
              />
            </ContentText>
          </TextRow>
        </TextContainer>
        <BottomRightVoucher>
          <VoucherText variant="body2">{voucher}</VoucherText>
        </BottomRightVoucher>
      </StyledInnerContainer>
      <CardPopUp
        savingType={voucher}
        index={index}
        value={value}
        name={name}
        id={id}
        title={name}
        description={savingText}
        link={link}
        open={popUpOpen}
        setOpen={setPopUpOpen}
        disableClickCount
        indexCarousel={0}
        parseAmount={0}
      />
    </OuterContainer>
  )
}

export const TextRow = styled(Box)`
  margin: 0.25rem 0;
`

const TextContainer = styled(Container)`
  ${({ theme }) => css`
    & {
      padding-left: 2rem;
      font-family: ${theme.typography.fontFamily};
    }
  `}
`

export const ContentTitleText = styled(Typography)`
  ${({ theme }) => css`
    &&& {
      font-size: ${theme.typography.body2.fontSize};
      line-height: 0.875rem;
      text-align: left;
      color: ${theme.palette.primary.main};
      font-family: ${theme.typography.fontFamily};
      font-weight: 700;
    }
  `}
`
export const ContentText = styled(Typography)`
  ${({ theme }) => css`
    &&& {
      font-size: ${theme.typography.body2.fontSize};
      line-height: 0.875rem;
      align-self: center;
      color: ${theme.palette.primary.main};
      font-family: ${theme.typography.fontFamily};
    }
  `}
`
