import {
  Box,
  styled,
  Typography,
  Button,
  FormHelperText,
  css,
} from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import { useFormikContext, getIn } from "formik"
import { useRef, useState } from "react"
import { FormikValue } from "./initialValues"
import { useTranslation } from "react-i18next"

const MAX_UPLOAD = 3 // max number of files to upload

const UploadQuoteFiles = () => {
  const { t, ready } = useTranslation("bikeQuote")
  const formik = useFormikContext<FormikValue>()
  const initFileNames = formik.values.documents.map((document) => document.name)
  const [fileNames, setFileNames] = useState<Array<string>>(initFileNames)
  const uploadText =
    fileNames.length > 0 ? t("upload.secondBike") : t("upload.firstBike")

  const inputRef = useRef<HTMLInputElement>(null)

  const handleDeleteFile = (fileName: string) => {
    // remove from fileNames array
    setFileNames((prev) => {
      return prev.filter((n) => n !== fileName)
    })

    // remove the uploaded file
    const filterResult = formik.values.documents.filter(
      (file) => file.name !== fileName
    )
    formik.setFieldValue("documents", filterResult)
  }

  const handleUpload = (file: File) => {
    const reader = new FileReader()

    if (file) {
      reader.onloadend = () =>
        setFileNames((prev: string[]) => [...prev, file.name])
      reader.readAsDataURL(file)
      formik.setFieldValue("documents", [
        ...getIn(formik.values, "documents"),
        file,
      ])
    }
  }

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click() // which triggers onChange from input, then fire handleChange()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      handleUpload(event.currentTarget.files[0])
    }
  }

  if (!ready) {
    return null
  }

  return (
    <Container>
      {fileNames.length > 0 &&
        fileNames.map((fileName) => {
          return (
            <FirstRow key={fileName}>
              <MainText>{fileName}</MainText>
              <ButtonWrapper aria-label={`Remove ${fileName} file`}>
                <StyledCancelIcon onClick={() => handleDeleteFile(fileName)} />
              </ButtonWrapper>
            </FirstRow>
          )
        })}

      <SecondRow>
        {fileNames.length < MAX_UPLOAD && (
          <MainText data-cy="upload-text">{uploadText}</MainText>
        )}

        <div>
          <input
            ref={inputRef}
            style={{ display: "none" }}
            id="documents"
            name="documents"
            type="file"
            accept="image/png, image/jpeg, .pdf"
            onChange={(event) => handleChange(event)}
            onBlur={formik.handleBlur}
            disabled={fileNames && fileNames.length >= MAX_UPLOAD}
            onClick={(event) => {
              // event.target.value = "" deals with unable to re-select same file after delete
              const target = event.target as HTMLInputElement
              target.value = ""

              setTimeout(() => {
                formik.setFieldTouched("documents")
              }, 500)
            }}
          />
          <label htmlFor="documents">
            <UploadButton
              disabled={fileNames && fileNames.length >= MAX_UPLOAD}
              onClick={handleButtonClick}
            >
              {t("upload.chooseFile")}
            </UploadButton>
          </label>
        </div>
        <SupportText data-cy="support-file-type">
          {t("upload.supportType")}
        </SupportText>
      </SecondRow>

      {formik.errors.documents && formik.touched.documents && (
        <FormHelperText error data-cy="unsupported-format">
          {String(formik.errors.documents)}
        </FormHelperText>
      )}
    </Container>
  )
}

export default UploadQuoteFiles

const Container = styled(Box)`
  ${({ theme }) => css`
    margin-bottom: 50px; // only mobile view, adjust again
    margin-top: 39px;
    padding-left: 0.75rem;
    display: flex;
    flex-direction: column;
    row-gap: 33px;

    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      margin-bottom: 41px;
    }
  `}
`

const FirstRow = styled(Box)`
  display: flex;
  column-gap: 18px;
  align-items: center;
`

const StyledCancelIcon = styled(CancelIcon)`
  color: ${({ theme }) => theme.palette.grey[700]};
  font-size: 1.25rem;
`

const SecondRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 29px;
  padding-right: 0.75rem;
`

const MainText = styled(Typography)`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.grey[700]};
  word-wrap: break-word;
  max-width: 90%;
`

const SupportText = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const UploadButton = styled(Button)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: #6d108315;
  border: 1px solid #6d1083;
  color: #6d1083;
  cursor: pointer;
  padding: 0 12px;
  border-radius: 16px;
  height: 30px;

  &:hover {
    background-color: unset;
    opacity: 0.75;
  }
`

const ButtonWrapper = styled(Button)`
  padding: 0;

  &:hover {
    background-color: unset;
    opacity: 0.75;
  }
`
