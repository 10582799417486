const lookup = {
  // page 0
  supplier_id: { id: "bikeShop", tab: 0 },
  bikes: { id: "typeOfBike0", tab: 0 }, // use 0 index for now
  bike_quote_amount: { id: "bikeValue0", tab: 0 }, // use 0 index for now
  bike_quote_description: { id: "brandOfBike0", tab: 0 }, // use 0 index for now
  brochure_group_id: { id: "brochureGroup", tab: 0 },
  documents: { id: "documents", tab: 0 },
  // page 1
  place_of_work: { id: "  placeOfWork", tab: 1 },
  employee_number: { id: "employeeNumber", tab: 1 },
  ni_number: { id: "niNumber", tab: 1 },
  last_name: { id: "lastName", tab: 1 },
  first_name: { id: "firstName", tab: 1 },
  mobile_number: { id: "mobileNumber", tab: 1 },
  telephone_number: { id: "phoneNumber", tab: 1 },
  email: { id: "email", tab: 1 },
  title: { id: "title", tab: 1 },
  date_of_birth: { id: "dateOfBirth", tab: 1 },
  // page 2
  delivery_address1: { id: "lineOne", tab: 2 },
  delivery_address2: { id: "lineTwo", tab: 2 },
  delivery_county: { id: "country", tab: 2 },
  delivery_town: { id: "town", tab: 2 },
  delivery_postcode: { id: "postCode", tab: 2 },
}

export type ErrorsType = {
  fieldName: keyof typeof lookup
  message: string
}

export type ParsedErrorsType = {
  id: string
  tab: number
  message: string
}[]

const parseError = (errors: ErrorsType[]) => {
  const parsedErrors: ParsedErrorsType = []
  errors.forEach(({ fieldName, message }) => {
    if (fieldName in lookup) {
      parsedErrors.push({ ...lookup[fieldName], message })
    }
  })
  return parsedErrors
}

export default parseError
