import { useMediaQuery, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import CondensedLogosection from "./condensed_header/Condensed_LogoSection"
import DefaultLogosection from "./default_header/Default_LogoSection"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"
import i18n from "../../../../i18n"
import { useParams } from "react-router-dom"

export type LogoSectionType = {
  logo?: string
  condensedLogo?: string
  organisationName?: string
  isLockBenefit?: boolean
  isValidBenefitType?: boolean
}

const LogoSection = ({
  logo,
  condensedLogo,
  organisationName,
  isLockBenefit,
  isValidBenefitType,
}: LogoSectionType) => {
  const [isShrunk, setShrunk] = useState(false)
  const theme = useTheme()
  const params = useParams()
  const isDesktop = useMediaQuery(theme.breakpoints.down("md"))
  const defaultLogo = useBuildAwsImageUrl(i18n.language, "logovivup.png")
  const defaultCondensedLogo = useBuildAwsImageUrl(
    i18n.language,
    "smallLogoVivup.png"
  )

  logo = logo ?? defaultLogo
  condensedLogo = condensedLogo ?? defaultCondensedLogo

  let defaultLink = "/users/sign_in"
  let external = false
  if (location.href.includes("/employer/")) {
    defaultLink = `/organisations/${params.organisationId}/employer/benefit_management`
  } else if (location.href.includes("/employee/")) {
    defaultLink = `/organisations/${params.organisationId}/employee/dashboard`
  } else if (location.href.includes("/analytics")) {
    defaultLink = `/`
    external = true
  }

  let link
  const isEligibleBenefit = isLockBenefit && isValidBenefitType

  if (isEligibleBenefit) {
    if (params.schemeType) {
      link = `/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/landing`
    } else if (location.href.includes("lifestyle_savings")) {
      link = `/organisations/${params.organisationId}/employee/lifestyle_savings/landing`
    } else {
      link = `/organisations/${
        params.organisationId
      }/employee/benefits/${location.href.split("/").pop()}`
    }
  } else {
    link = defaultLink
  }

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 50 ||
            document.documentElement.scrollTop > 50)
        ) {
          return true
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false
        }

        return isShrunk
      })
    }

    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [])

  return (
    <>
      {(!isDesktop && isShrunk) || isDesktop ? (
        <CondensedLogosection
          logo={condensedLogo}
          organisationName={organisationName}
          link={link}
          external={external}
        />
      ) : (
        <DefaultLogosection
          logo={logo}
          organisationName={organisationName}
          link={link}
          external={external}
        />
      )}
    </>
  )
}

export default LogoSection
