import {
  Button,
  useMediaQuery,
  useTheme,
  styled,
  Typography,
  css,
} from "@mui/material"
import classNames from "classnames"
import { useState, useContext, useEffect } from "react"
import { CheckoutContext } from "./CheckoutContext"
import Delivery from "./Delivery"
import { DeliveryFieldsType, DetailsFieldsType } from "./defaultFormContent"
import Details from "./Details"
import Checkboxes from "./Checkboxes"
import { createStoreOrder } from "./CheckoutContext"
import { useParams } from "react-router"
import parseResponse from "./parseResponse"
import Alert from "../../shared/Alert"
import {
  gtmCheckout,
  gtmCycleToWorkBeginCheckout,
  gtmCycleToWorkAddShippingInfo,
} from "../../shared/gtm-events/CustomGtmEvents"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import { getDynamicCheckoutPageMessage } from "../../../utils/dynamicMessageSchemeStores"
import { useTranslation } from "react-i18next"
import {
  convertedObjectIDs,
  convertedObjectIDsAfterSearch,
} from "../../../utils/algoliaEvents"
import { useNavigate } from "react-router"
import { OrderProduct } from "../../../graphqGenaretedTypes"

export type StepType = "details" | "delivery"

export default function Form({
  setSuccessfulOrderId,
  setSuccessfulOrderDetails,
}: {
  setSuccessfulOrderId: (orderId: string) => void
  setSuccessfulOrderDetails: (
    storeName: string,
    revenue: string,
    products: OrderProduct[],
    supplier: string
  ) => void
}) {
  const { quoteType, currentUserId } = useContext(StoreLayoutContext)
  const [activeStep, setActiveStep] = useState<StepType>("details")
  const { t, ready } = useTranslation(["constants", "checkoutPage"])

  const {
    products,
    formContent,
    showDateOfBirth,
    checkboxes,
    storeName,
    loading,
  } = useContext(CheckoutContext)

  const [content, setContent] = useState(formContent)
  const [detailsFocus, setDetailsFocus] = useState({
    key: "placeOfWork",
    counter: 0,
  })
  const [deliveryFocus, setDeliveryFocus] = useState({
    key: "lineOne",
    counter: 0,
  })
  const [formCheckboxes, setFormCheckboxes] = useState(checkboxes)
  const [globalError, setGlobalError] = useState<string | false>(false)
  const [errorCount, setErrorCount] = useState(0)
  const params = useParams()
  const currentOrg = params.organisationId
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const navigate = useNavigate()
  // TODO - fix eslint warnings/errors
  useEffect(() => {
    currentUserId &&
      window.localStorage.setItem("customerUserId", String(currentUserId))
    currentOrg && window.localStorage.setItem("currentOrg", currentOrg)
    setContent(formContent)
    setFormCheckboxes(checkboxes)
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  const isCycleToWork = params.schemeType === "cycle_to_work"
  useEffect(() => {
    if (isCycleToWork) {
      activeStep === "details" &&
        gtmCycleToWorkBeginCheckout(storeName, products)
    } else {
      gtmCheckout(storeName, products, activeStep)
    }
  }, [activeStep]) // eslint-disable-line react-hooks/exhaustive-deps

  const [createStoreOrderMutation, { loading: isProcessingOrder }] =
    createStoreOrder(
      params.organisationId ?? "",
      params.schemeType ?? "",
      content.detailsFields.placeOfWork.value,
      content.detailsFields.employeeNumber.value,
      content.detailsFields.niNumber.value,
      content.detailsFields.dateOfBirth.value?.toString() || "",
      content.detailsFields.title.value,
      content.detailsFields.firstName.value,
      content.detailsFields.lastName.value,
      content.detailsFields.phoneNumber.value,
      content.detailsFields.mobileNumber.value,
      content.detailsFields.emailAddress.value,
      content.deliveryFields.lineOne.value,
      content.deliveryFields.lineTwo.value,
      content.deliveryFields.town.value,
      content.deliveryFields.county.value,
      content.deliveryFields.postCode.value,
      formCheckboxes.filter(
        (checkbox) => checkbox.name === "termsAcknowledged"
      )[0]?.checked || null,
      formCheckboxes.filter(
        (checkbox) => checkbox.name === "hireAgreementAcknowledged"
      )[0]?.checked || null,
      formCheckboxes.filter(
        (checkbox) => checkbox.name === "salarySacrificeArrangementAcknowledged"
      )[0]?.checked || null
    )

  const setDelivery = () => {
    currentUserId &&
      window.localStorage.setItem("customerUserId", String(currentUserId))
    let hasDetailsError = false
    const fieldsWithErrors: Array<{
      name: keyof DetailsFieldsType
      message: string
    }> = []
    const detailsFields = content.detailsFields
    let k: keyof DetailsFieldsType

    for (k in detailsFields) {
      let rawVal = ""
      if (k === "dateOfBirth" && detailsFields[k].inputValue) {
        rawVal = detailsFields[k].inputValue || ""
      }

      const validation = detailsFields[k]?.validate(
        detailsFields[k]?.value?.toString() || "",
        rawVal
      )

      // normal field value validation
      if (validation && !validation.isValid) {
        hasDetailsError = true
        fieldsWithErrors.push({
          name: k,
          message: validation.message || "",
        })
      }
    }

    if (hasDetailsError) {
      let newDetailsFields = content.detailsFields
      fieldsWithErrors.forEach((field) => {
        newDetailsFields = {
          ...newDetailsFields,
          [field.name]: {
            ...newDetailsFields[field.name],
            state: "error",
            errorMessage: field.message,
          },
        }
      })
      setDetailsFocus({
        key: fieldsWithErrors[0].name,
        counter: detailsFocus.counter + 1,
      })

      setContent({ ...content, detailsFields: newDetailsFields })
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
      setActiveStep("delivery")
    }
  }

  const submitOrder = () => {
    let hasDetailsError = false
    const fieldsWithErrors: Array<{
      name: keyof DeliveryFieldsType
      message: string
    }> = []
    const deliveryFields = content.deliveryFields
    let k: keyof DeliveryFieldsType
    for (k in content.deliveryFields) {
      const validation = deliveryFields[k].validate(deliveryFields[k].value)
      if (!validation.isValid) {
        hasDetailsError = true
        fieldsWithErrors.push({ name: k, message: validation.message || "" })
      }
    }

    if (hasDetailsError) {
      let newDeliveryFields = content.deliveryFields
      fieldsWithErrors.forEach((field) => {
        newDeliveryFields = {
          ...newDeliveryFields,
          [field.name]: {
            ...newDeliveryFields[field.name],
            state: "error",
            errorMessage: field.message,
          },
        }
      })

      setContent({ ...content, deliveryFields: newDeliveryFields })

      setDeliveryFocus({
        key: fieldsWithErrors[0].name,
        counter: deliveryFocus.counter + 1,
      })
    }

    let hasCheckboxError = false
    formCheckboxes.forEach((checkbox) => {
      if (checkbox.required && !checkbox.checked) {
        hasCheckboxError = true
      }
    })

    if (hasCheckboxError) {
      setGlobalError(t("checkoutPage:checkoutPage.form.acceptTermCondition"))
      setErrorCount(errorCount + 1)
    } else {
      setGlobalError(false)
    }

    if (!hasDetailsError && !hasCheckboxError) {
      createStoreOrderMutation().then(({ data }) => {
        if (
          data?.createStoreOrder?.errors &&
          data?.createStoreOrder?.errors.length > 0
        ) {
          const { detailsErrors, deliveryErrors, globalErrors, errorStep } =
            parseResponse(data.createStoreOrder.errors)
          setActiveStep(errorStep)
          let newDetailsFields = content.detailsFields
          detailsErrors.forEach((error) => {
            newDetailsFields = {
              ...newDetailsFields,
              [error.fieldName]: {
                ...newDetailsFields[error.fieldName],
                state: "error",
                errorMessage: error.message,
              },
            }
          })
          let newDeliveryFields = content.deliveryFields
          deliveryErrors.forEach((error) => {
            newDeliveryFields = {
              ...newDeliveryFields,
              [error.fieldName]: {
                ...newDeliveryFields[error.fieldName],
                state: "error",
                errorMessage: error.message,
              },
            }
          })
          setContent({
            detailsFields: newDetailsFields,
            deliveryFields: newDeliveryFields,
          })

          if (globalErrors.length > 0) {
            setGlobalError(
              globalErrors.map(({ message }) => message).join(", ")
            )
          }
        } else if (data?.createStoreOrder?.order) {
          setSuccessfulOrderDetails(
            data?.createStoreOrder.storeName ?? "",
            data?.createStoreOrder.revenue ?? "",
            data?.createStoreOrder.products ?? [],
            data?.createStoreOrder.supplier ?? ""
          )
          if (isCycleToWork) {
            gtmCycleToWorkAddShippingInfo(
              data?.createStoreOrder.storeName,
              data?.createStoreOrder.products,
              data?.createStoreOrder.supplier
            )
          }
          setSuccessfulOrderId(data.createStoreOrder.order.id)
          if (data.createStoreOrder.postUrl) {
            window.location.href = data.createStoreOrder.postUrl
          } else if (data.createStoreOrder.ssoLink) {
            navigate(data.createStoreOrder.ssoLink)
          }
        }

        const productTitles = products.map((product) => product.productId)
        const productTitleArray = Array.isArray(productTitles)
          ? productTitles
          : [productTitles]

        convertedObjectIDs("order placed", productTitleArray)
        const customerUserId = window.localStorage.getItem("customerUserId")
        const currentOrgnisation = window.localStorage.getItem("currentOrg")
        productTitleArray.forEach((key) => {
          const item = window.localStorage.getItem(
            key + "clicked" + customerUserId + currentOrg
          )
          if (item !== null) {
            convertedObjectIDsAfterSearch(item, "submit order", [key])
          }
        })

        if (
          customerUserId?.toString() === currentUserId?.toString() &&
          currentOrg === currentOrgnisation
        ) {
          const keys = []
          for (let i = 0; i < localStorage.length; i++) {
            keys.push(localStorage.key(i))
          }
          for (const i of keys) {
            if (i?.includes("clicked" + currentUserId + currentOrg)) {
              window.localStorage.removeItem(i)
            }
          }
        }
      })
    }
  }

  if (!ready) {
    return null
  }
  return (
    <div className="form">
      {globalError && (
        <Alert
          severity={"error"}
          message={globalError || ""}
          key={errorCount}
        />
      )}
      <div className="steps">
        <div
          className={classNames("steps__item", {
            "steps__item--active": activeStep === "details",
          })}
          onClick={() => setActiveStep("details")}
          onKeyDown={(e) =>
            (e.key === "Enter" || e.key === " ") && setActiveStep("details")
          }
          tabIndex={0}
          role="button"
          aria-label="Your details form tab"
        >
          <div
            className={
              activeStep === "details"
                ? "steps__item-number--active"
                : "steps__item-number"
            }
          >
            1
          </div>
          <h4
            className={
              activeStep === "details"
                ? "steps__item-label--active"
                : "steps__item-label"
            }
          >
            {t("checkoutPage:checkoutPage.form.yourDetails")}
          </h4>
        </div>
        <div
          className={classNames("steps__item", {
            "steps__item--active": activeStep === "delivery",
          })}
          onClick={() => setDelivery()}
          onKeyDown={(e) =>
            (e.key === "Enter" || e.key === " ") && setDelivery()
          }
          tabIndex={0}
          role="button"
          aria-label="Submit order form tab"
        >
          <div
            className={
              activeStep === "delivery"
                ? "steps__item-number--active"
                : "steps__item-number"
            }
          >
            2
          </div>
          <h4
            className={
              activeStep === "delivery"
                ? "steps__item-label--active"
                : "steps__item-label"
            }
          >
            {" "}
            {t("checkoutPage:checkoutPage.form.submitOrder")}
          </h4>
        </div>
      </div>
      {activeStep === "details" ? (
        <>
          <Details
            content={content.detailsFields}
            showDateOfBirth={showDateOfBirth}
            focus={detailsFocus}
            setContent={(detailsFields) =>
              setContent({ ...content, detailsFields: detailsFields })
            }
          />
          <div className="content-center">
            <Button
              variant="gradient"
              onClick={() => setDelivery()}
              sx={{
                width: "18.75rem",
                marginBottom: isDesktop ? "2rem" : "6rem",
              }}
            >
              {t("checkoutPage:checkoutPage.form.continueDelivery")}
            </Button>
          </div>
        </>
      ) : (
        <>
          <Label>{t("checkoutPage:checkoutPage.form.addressNote")}</Label>
          <Delivery
            content={content.deliveryFields}
            setContent={(deliveryFields) =>
              setContent({ ...content, deliveryFields: deliveryFields })
            }
            focus={deliveryFocus}
          />
          <Checkboxes
            checkboxes={formCheckboxes}
            setCheckboxes={(state) => setFormCheckboxes(state)}
          />
          <div className="content-center">
            <Button
              variant="gradient"
              onClick={() => submitOrder()}
              sx={{ width: "15.25rem", marginBottom: "2rem" }}
              disabled={isProcessingOrder}
            >
              {t("checkoutPage:checkoutPage.form.submitOrderButton")}
            </Button>
          </div>
          <div className="form__footnote">
            {getDynamicCheckoutPageMessage(quoteType)}
          </div>
        </>
      )}
      <div></div>
    </div>
  )
}

const Label = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    margin: -25px 0 15px 0;
    @media screen and (max-width: ${theme.breakpoints.values.md}px) {
      margin: 0;
      padding-left: 18px;
    }
  `}
`
