import { createContext } from "react"
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import {
  GetLsStoreDataQueryHookResult,
  useGetLsStoreDataQuery,
} from "../../graphqGenaretedTypes"
import { BreadcrumbsType } from "../shared/CustomBreadcrumbs"
import { useTranslation } from "react-i18next"

export type DiscountType = {
  id: string
  kind?: string
  savingType?: string
  value?: string
  savingsText: string
  discountCode: string
  image?: string
  link: string
  name?: string
  showSavingType: boolean
  featuredTagImage?: string
  featuredTagName?: string
  index: number
}

type CategoryType = {
  id: string
  name: string
  numberOfDiscounts: number
  index: number
}

type PromotionType = {
  id: string
  name: string
  numberOfDiscounts: number
  index: number
}

type SavingTypeType = {
  id: string
  name: string
  numberOfDiscounts: number
  index: number
}

type BrandType = {
  id: string
  name: string
  numberOfDiscounts: number
  index: number
}

export type LSStoreFilters = {
  brands: Array<BrandType>
  promotions: Array<PromotionType>
  trendings: Array<PromotionType>
  categories: Array<CategoryType>
  savingTypes: Array<SavingTypeType>
}

export type LSStoreContextData = {
  filters: LSStoreFilters
  discounts: ReadonlyArray<DiscountType>
  totalPages: number
  totalEntries: number
  breadcrumbs: Array<BreadcrumbsType>
  loading: boolean
}

const defaultValue: LSStoreContextData = {
  filters: {
    brands: [],
    promotions: [],
    trendings: [],
    categories: [],
    savingTypes: [],
  },
  discounts: [],
  totalPages: 0,
  totalEntries: 0,
  breadcrumbs: [],
  loading: true,
}

let state = JSON.parse(JSON.stringify(defaultValue))

const queryDataToDiscounts: (
  queryData: GetLsStoreDataQueryHookResult
) => DiscountType[] = function (queryData) {
  let result: DiscountType[] = []
  const data = queryData.data
  if (data?.employeeOrganisation?.lifestyleSavings?.discountStore?.discounts) {
    result =
      data?.employeeOrganisation?.lifestyleSavings?.discountStore?.discounts.map(
        (discount, index) => {
          return {
            id: discount.id,
            name: discount.name || "",
            image: discount.image || "",
            link: discount.link,
            kind: discount.kind || "",
            value: discount.value || "",
            discountCode: discount.discountCode || "",
            savingType: discount.savingType || "",
            savingsText: discount.savingsText,
            showSavingType: discount.showSavingType || false,
            featuredTagImage: discount.featuredTagImage || "",
            featuredTagName: discount.featuredTagName || "",
            index: index,
          }
        }
      )
  }

  return result
}

const queryDataToFilters: (
  queryData: GetLsStoreDataQueryHookResult
) => LSStoreFilters = function (queryData) {
  return {
    brands: queryDataToBrands(queryData),
    promotions: queryDataToPromotions(queryData),
    trendings: [],
    categories: queryDataToCategories(queryData),
    savingTypes: queryDataToSavingTypes(queryData),
  }
}

const queryDataToBrands: (
  queryData: GetLsStoreDataQueryHookResult
) => BrandType[] = function (queryData) {
  let result: BrandType[] = []
  const data = queryData.data

  result =
    data?.employeeOrganisation?.lifestyleSavings?.discountStore?.filters?.brands?.map(
      (brand, index) => {
        return {
          id: brand.id,
          name: brand.name,
          numberOfDiscounts: brand.numberOfDiscounts,
          index: index,
        }
      }
    ) || []
  return result
}

const queryDataToPromotions: (
  queryData: GetLsStoreDataQueryHookResult
) => PromotionType[] = function (queryData) {
  let result: PromotionType[] = []
  const data = queryData.data

  result =
    data?.employeeOrganisation?.lifestyleSavings?.discountStore?.filters?.promotions?.map(
      (promotion, index) => {
        return {
          id: promotion.id,
          name: promotion.name,
          numberOfDiscounts: promotion.numberOfDiscounts,
          index: index,
        }
      }
    ) || []
  return result
}

const queryDataToCategories: (
  queryData: GetLsStoreDataQueryHookResult
) => CategoryType[] = function (queryData) {
  let result: CategoryType[] = []
  const data = queryData.data

  result =
    data?.employeeOrganisation?.lifestyleSavings?.discountStore?.filters?.categories?.map(
      (category, index) => {
        return {
          id: category.id,
          name: category.name,
          numberOfDiscounts: category.numberOfDiscounts,
          index: index,
        }
      }
    ) || []
  return result
}

const queryDataToSavingTypes: (
  queryData: GetLsStoreDataQueryHookResult
) => SavingTypeType[] = function (queryData) {
  let result: SavingTypeType[] = []
  const data = queryData.data

  result =
    data?.employeeOrganisation?.lifestyleSavings?.discountStore?.filters?.savingTypes?.map(
      (savingType, index) => {
        return {
          id: savingType.id,
          name: savingType.name,
          numberOfDiscounts: savingType.numberOfDiscounts,
          index: index,
        }
      }
    ) || []
  return result
}

const queryDataToTotalPages: (
  queryData: GetLsStoreDataQueryHookResult
) => number = function (queryData) {
  const data = queryData.data

  return (
    data?.employeeOrganisation?.lifestyleSavings?.discountStore?.totalPages || 0
  )
}

const queryDataToTotalEntries: (
  queryData: GetLsStoreDataQueryHookResult
) => number = function (queryData) {
  const data = queryData.data

  return (
    data?.employeeOrganisation?.lifestyleSavings?.discountStore?.totalEntries ||
    0
  )
}

const queryDataToBreadcrumbs: (
  queryData: GetLsStoreDataQueryHookResult
) => BreadcrumbsType[] = function (queryData) {
  const data = queryData.data

  return (
    data?.employeeOrganisation?.lifestyleSavings?.discountStore?.breadcrumbs ||
    []
  )
}

export const LSStoreContext = createContext(defaultValue)

export const useStoreData: () => {
  data: LSStoreContextData
} = function () {
  const { organisationId } = useParams()
  const { i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const filters = {
    categories: searchParams.getAll("categories"),
    trend: searchParams.getAll("trend"),
    savingTypes: searchParams.getAll("savingTypes"),
    brands: searchParams.getAll("brands"),
    search: searchParams.get("search"),
  }

  const data = useGetLsStoreDataQuery({
    variables: {
      organisationId: organisationId || "",
      filters: filters,
      page: parseInt(searchParams.get("page") || "1"),
      discountsPerPage: parseInt(searchParams.get("results_per_page") || "0"),
      orderBy: searchParams.get("sort_by") || "",
      locale: i18n.language,
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  if (!data.loading) {
    const discounts = queryDataToDiscounts(data)
    const totalPages = queryDataToTotalPages(data)
    const totalEntries = queryDataToTotalEntries(data)
    const filters = queryDataToFilters(data)
    const breadcrumbs = queryDataToBreadcrumbs(data)
    const loading = data.loading

    state = {
      ...defaultValue,
      discounts,
      totalPages,
      totalEntries,
      filters,
      breadcrumbs,
      loading,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
