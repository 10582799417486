import { useState, useEffect } from "react"
const CLOUDFRONT_URL = window.ENV["CLOUDFRONT_URL"]

const urlCache = new Map()

export const useBuildAwsImageUrl = (locale: string, imageName: string) => {
  const [fetchValue, setFetchValue] = useState("")

  useEffect(() => {
    if (!imageName) {
      setFetchValue("")
      return
    }

    const cacheKey = `${locale}-${imageName}`

    if (urlCache.has(cacheKey)) {
      setFetchValue(urlCache.get(cacheKey))
    } else {
      validateUrl(locale, imageName).then((response) => {
        urlCache.set(cacheKey, response)
        setFetchValue(response)
      })
    }
  }, [locale, imageName])

  return fetchValue
}

const validateUrl = async (locale: string, imageName: string) => {
  let imageUrl = `${CLOUDFRONT_URL}/${locale}/${imageName}`

  try {
    const response = await fetch(imageUrl)
    if (!response.ok) {
      imageUrl = `${CLOUDFRONT_URL}/en-GB/${imageName}`
    }
  } catch {
    imageUrl = `${CLOUDFRONT_URL}/en-GB/${imageName}`
  }

  return imageUrl
}
