import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const PageTitle = ({ title }: { title: string }) => {
  const location = useLocation()

  useEffect(() => {
    // Retrieve the hostname from window.location once, since it's stable across page navigations
    const hostname = window.location.hostname
    let subdomain = hostname.slice(0, hostname.indexOf("."))

    if (["vivup", "www"].includes(subdomain) || hostname.indexOf(".") === -1) {
      subdomain = "Vivup"
    }

    // Set the document title based on the subdomain and title
    document.title = `${subdomain}: ${title}`
  }, [location, title])

  return null
}

export default PageTitle
