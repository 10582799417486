import React, { useRef, RefObject } from "react"
import { styled, TextField, Autocomplete } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { DeliveryFieldsType } from "./defaultFormContent"
import { useState, useEffect } from "react"
import { getAddressSuggestions, getFullAddress } from "./CheckoutContext"
import { useTranslation } from "react-i18next"

export type DeliveryProps = {
  content: DeliveryFieldsType
  setContent: (content: DeliveryFieldsType) => void
  focus: {
    counter: number
    key: string
  }
}

export type DeliveryAddressType = {
  id: string | undefined
}

export type AddressSuggestionType = {
  label: string | undefined
  id: string | undefined
}

interface AddressData {
  [key: string]: string
}

type FieldType = {
  label: string
  value: string
  state?: "error" | undefined
  errorMessage?: string
  validate: (value: string) => { isValid: boolean; message?: string }
}

type RefsType = {
  [K in keyof DeliveryFieldsType]: RefObject<HTMLInputElement>
}

const StyledTextField = styled(TextField)({
  marginBottom: "1.875rem",
})

const useDeliveryRefs = () => {
  const lineOne = useRef<HTMLInputElement>(null)
  const lineTwo = useRef<HTMLInputElement>(null)
  const town = useRef<HTMLInputElement>(null)
  const postCode = useRef<HTMLInputElement>(null)

  return {
    lineOne,
    lineTwo,
    town,
    postCode,
  } as RefsType
}

export default function Delivery({
  content,
  setContent,
  focus,
}: DeliveryProps) {
  const [searchTerm, setSearchTerm] = useState("")
  const [apiResponseAddresses, setApiResponseAddresses] = useState<
    AddressSuggestionType[]
  >([])
  const { refetch } = getAddressSuggestions("")
  const { t, ready } = useTranslation("checkoutPage")

  const refs = useDeliveryRefs()

  const { refetch: refetchFullAddress } = getFullAddress("")
  const triggerGetFullAddressCall = (addressId: string) => {
    refetchFullAddress({
      id: addressId,
    }).then((data) => {
      const fullAddressData = data?.data?.employeeOrganisation
        ?.getFullAddress as AddressData
      const fullAddressPopulatedFields = Object.entries(content).reduce(
        (acc, [key, field]) => {
          const fieldKey = key as keyof DeliveryFieldsType
          const validation = field.validate(fullAddressData?.[fieldKey] || "")
          acc[fieldKey] = {
            ...field,
            value: fullAddressData?.[fieldKey] || "",
            state: validation.isValid ? undefined : "error",
            errorMessage: validation.isValid ? "" : validation.message ?? "",
          }
          return acc
        },
        {} as DeliveryFieldsType
      )
      setContent({ ...content, ...fullAddressPopulatedFields })
    })
  }

  useEffect(() => {
    if (searchTerm.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        refetch({
          searchTerm: searchTerm,
        }).then((data) => {
          const result =
            data?.data?.employeeOrganisation?.addressSuggestions?.map(
              (addressData) => ({
                label: addressData.address,
                id: addressData.id,
              })
            )
          setApiResponseAddresses(result || [])
        })
      }, 300)
      return () => clearTimeout(delayDebounceFn)
    }
    return () => setApiResponseAddresses([])
  }, [searchTerm, refetch])

  useEffect(() => {
    const currentRef = refs[focus.key as keyof DeliveryFieldsType]
    if (currentRef?.current) {
      currentRef.current.focus()
    }
  }, [focus.counter]) // eslint-disable-line react-hooks/exhaustive-deps

  const finishedTextField = (
    key: keyof DeliveryFieldsType,
    field: FieldType
  ) => (
    <StyledTextField
      label={field.label}
      variant="outlined"
      value={field.value}
      inputRef={refs[key]}
      error={field.state === "error"}
      helperText={field.errorMessage}
      onChange={(e) =>
        setContent({
          ...content,
          [key]: { ...field, value: e.target.value },
        })
      }
      onBlur={(e) => {
        const validation = field.validate(e.target.value)
        setContent({
          ...content,
          [key]: {
            ...field,
            state: validation.isValid ? undefined : "error",
            errorMessage: validation.isValid ? "" : validation.message || "",
          },
        })
      }}
    />
  )

  const handleChangeTemplate = (
    event: React.SyntheticEvent,
    value: DeliveryAddressType | null
  ) => {
    if (value?.id) {
      triggerGetFullAddressCall(value.id)
    }
  }

  if (!ready) {
    return null
  }

  return (
    <div className="fields">
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        filterOptions={(x) => x}
        options={apiResponseAddresses}
        sx={{ marginBottom: "1.875rem" }}
        renderInput={(params) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <TextField
            {...params}
            variant="outlined"
            label={
              <div style={{ display: "flex", justifyContent: "center" }}>
                <SearchIcon sx={{ paddingRight: "0.5rem" }} fontSize="small" />
                {t("checkoutPage.form.typePostCode")}
              </div>
            }
          />
        )}
        onKeyUp={(e) => setSearchTerm((e.target as HTMLInputElement).value)}
        onChange={handleChangeTemplate}
      />
      {(Object.entries(content) as [keyof DeliveryFieldsType, FieldType][]).map(
        ([key, field]) => (
          <React.Fragment key={key}>
            {finishedTextField(key, field)}
          </React.Fragment>
        )
      )}
      <div className="fields__footnote">
        {t("checkoutPage.form.requiredFields")}
      </div>
    </div>
  )
}
