import { createContext } from "react"
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { ProductType } from ".././product-card/ProductCard"
import {
  BrochureGroup,
  GetProductDataQueryHookResult,
  useGetProductDataQuery,
} from "../../../graphqGenaretedTypes"
import { BreadcrumbsType } from "../../shared/CustomBreadcrumbs"
import snakeToCamel from "../../../utils/snakeToCamel"
import { useTranslation } from "react-i18next"

export type ProductContextData = {
  id: number
  brochureGroupId: number
  title: string
  description: string
  brand: string
  variant: string
  category: string
  sku: string
  storeName: string
  productTagImage: string
  summary: string
  techSpecs: string
  availableSizes: string
  price: string
  totalAmount: string
  isNhs: boolean
  image: string
  productImages: string[]
  recommendations: Array<ProductType>
  recommendationsSimilarProducts: Array<ProductType>
  breadcrumbs: Array<BreadcrumbsType>
  supplierBanner?: {
    mobile?: string
    desktop?: string
  }
  loading: boolean
  department: string
  brochureGroups: BrochureGroup[]
  savings?: number
  rrp: number
  reachedMaximumQuantity: boolean
  maximumQuantity?: number
  supplierName: string
  releaseDate?: string
  status: "preOrder" | "backOrder" | "default"
  preBackorderMessage?: string
}

const defaultValue: ProductContextData = {
  id: 0,
  brochureGroupId: 0,
  title: "",
  description: "",
  brand: "",
  variant: "",
  category: "",
  sku: "",
  storeName: "",
  productTagImage: "",
  summary: "",
  techSpecs: "",
  availableSizes: "[]",
  price: "",
  totalAmount: "",
  isNhs: false,
  image: "",
  productImages: [],
  recommendations: [],
  recommendationsSimilarProducts: [],
  breadcrumbs: [],
  loading: true,
  department: "",
  brochureGroups: [],
  savings: undefined,
  rrp: 0,
  reachedMaximumQuantity: false,
  maximumQuantity: undefined,
  supplierName: "",
  releaseDate: undefined,
  status: "default",
  preBackorderMessage: undefined,
}

const queryDataToProductTitle: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.name || ""
}
const queryDataToProductImageTag: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.productTagImage || ""
}
const queryDataToProductDescription: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.description || ""
}

const queryDataToProductBrand: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.brand || ""
}

const queryDataToProductVariant: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.variant || ""
}

const queryDataToProductDepartment: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.department || ""
}

const queryDataToProductCategory: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.category || ""
}

const queryDataToBreadcrumbs: (
  queryData: GetProductDataQueryHookResult
) => BreadcrumbsType[] = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.breadcrumbs || []
}

const queryDataToSupplierName: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.supplierName || ""
}

const queryDataToRecommendations: (
  queryData: GetProductDataQueryHookResult
) => ProductType[] = function (queryData) {
  let result: ProductType[] = []
  const data = queryData.data

  if (data?.employeeOrganisation?.scheme?.product?.recommendations) {
    result = data?.employeeOrganisation?.scheme?.product?.recommendations.map(
      (product) => {
        return {
          id: product.productId,
          title: product.name,
          image: product.image,
          lowertag: true,
          price: product.price.toString(),
          totalAmount: product.totalAmount.toString(),
          altnativeImgText: "",
          flashdealtag: false,
          link: product.url,
          productTagName: product.productTagName || undefined,
          productTagImage: product.productTagImage || undefined,
          term: product.term,
          brochureGroupId: product.brochureGroupId,
          brand: product.brand,
          category: product.category,
          variant: "Recommended Products",
          availableSizes: product.availableSizes || "[]",
          sku: product.sku || "",
          storeName: data?.employeeOrganisation?.scheme?.storeName || "",
          department: product.department || "",
          supplierName: product.supplierName || "",
        }
      }
    )
  }
  return result
}

const queryDataToRecommendationsSimilarProducts: (
  queryData: GetProductDataQueryHookResult
) => ProductType[] = function (queryData) {
  let result: ProductType[] = []
  const data = queryData.data

  if (
    data?.employeeOrganisation?.scheme?.product?.recommendationsSimilarProducts
  ) {
    result =
      data?.employeeOrganisation?.scheme?.product?.recommendationsSimilarProducts.map(
        (product) => {
          return {
            id: product.productId,
            title: product.name,
            image: product.image,
            lowertag: true,
            price: product.price.toString(),
            totalAmount: product.totalAmount.toString(),
            altnativeImgText: "",
            flashdealtag: false,
            link: product.url,
            productTagName: product.productTagName || undefined,
            productTagImage: product.productTagImage || undefined,
            term: product.term,
            brochureGroupId: product.brochureGroupId,
            brand: product.brand,
            category: product.category,
            variant: "Recommended Products",
            availableSizes: product.availableSizes || "[]",
            sku: product.sku || "",
            storeName: data?.employeeOrganisation?.scheme?.storeName || "",
            department: product.department || "",
            supplierName: product.supplierName || "",
          }
        }
      )
  }
  return result
}

const queryDataToProductSummary: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.summary || ""
}

const queryDataToProductTechSpecs: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.techSpecs || ""
}

const queryDataToProductAvailableSizes: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.availableSizes || "[]"
}

const queryDataToProductPrice: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.product?.price.toString() || ""
}

const queryDataToProductTotalAmount: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data

  return (
    data?.employeeOrganisation?.scheme?.product?.totalAmount.toString() || ""
  )
}

const queryDataToProductIsNhs: (
  queryData: GetProductDataQueryHookResult
) => boolean = function (queryData) {
  const data = queryData.data

  return data?.employeeOrganisation?.scheme?.isNhs || false
}

const queryDataToImage: (queryData: GetProductDataQueryHookResult) => string =
  function (queryData) {
    const data = queryData.data

    return data?.employeeOrganisation?.scheme?.product?.image || ""
  }

const queryDataToProductImages: (
  queryData: GetProductDataQueryHookResult
) => string[] = function (queryData) {
  const data = queryData.data
  return data?.employeeOrganisation?.scheme?.product?.productImages || []
}

const queryDataToProductId: (
  queryData: GetProductDataQueryHookResult
) => number = function (queryData) {
  const data = queryData.data
  return data?.employeeOrganisation?.scheme?.product?.productId || 0
}

const queryDataToStoreName: (
  queryData: GetProductDataQueryHookResult
) => string = function (queryData) {
  const data = queryData.data
  return data?.employeeOrganisation?.scheme?.storeName || ""
}

const queryDataToSku: (queryData: GetProductDataQueryHookResult) => string =
  function (queryData) {
    const data = queryData.data
    return data?.employeeOrganisation?.scheme?.product?.sku || ""
  }

const queryDataToBrochureGroupId: (
  queryData: GetProductDataQueryHookResult
) => number = function (queryData) {
  const data = queryData.data
  return data?.employeeOrganisation?.scheme?.product?.brochureGroupId || 0
}

const queryDataToSupplierBanner: (queryData: GetProductDataQueryHookResult) => {
  mobile?: string
  desktop?: string
} = function (queryData) {
  const data = queryData.data
  return {
    mobile:
      data?.employeeOrganisation?.scheme?.product?.supplierMobileBanner ||
      undefined,
    desktop:
      data?.employeeOrganisation?.scheme?.product?.supplierDesktopBanner ||
      undefined,
  }
}

let state = JSON.parse(JSON.stringify(defaultValue))

export const ProductContext = createContext(defaultValue)

export const useProductData: () => {
  data: ProductContextData
} = function () {
  const params = useParams()
  const { i18n } = useTranslation()
  const [searchParams] = useSearchParams()

  const data = useGetProductDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: params.schemeType ?? "",
      id: params.id ?? "",
      brochureGroupId: searchParams.get("bg") ?? "",
      locale: i18n.language,
    },
    errorPolicy: "all",
  })

  if (!data.loading) {
    const title = queryDataToProductTitle(data)
    const description = queryDataToProductDescription(data)
    const summary = queryDataToProductSummary(data)
    const techSpecs = queryDataToProductTechSpecs(data)
    const availableSizes = queryDataToProductAvailableSizes(data)
    const price = queryDataToProductPrice(data)
    const productImages = queryDataToProductImages(data)
    const recommendations = queryDataToRecommendations(data)
    const recommendationsSimilarProducts =
      queryDataToRecommendationsSimilarProducts(data)
    const productTagImage = queryDataToProductImageTag(data)
    const image = queryDataToImage(data)
    const id = queryDataToProductId(data)
    const brochureGroupId = queryDataToBrochureGroupId(data)
    const supplierBanner = queryDataToSupplierBanner(data)
    const totalAmount = queryDataToProductTotalAmount(data)
    const isNhs = queryDataToProductIsNhs(data)
    const breadcrumbs = queryDataToBreadcrumbs(data)
    const brand = queryDataToProductBrand(data)
    const category = queryDataToProductCategory(data)
    const variant = queryDataToProductVariant(data)
    const sku = queryDataToSku(data)
    const storeName = queryDataToStoreName(data)
    const department = queryDataToProductDepartment(data)
    const brochureGroups =
      data.data?.employeeOrganisation?.scheme?.brochureGroups
    const supplierName = queryDataToSupplierName(data)
    const savings = data.data?.employeeOrganisation?.scheme?.product?.savings
    const rrp =
      data.data?.employeeOrganisation?.scheme?.product?.recommendedRetailPrice
    const reachedMaximumQuantity =
      data.data?.employeeOrganisation?.scheme?.product?.reachedMaximumQuantity
    const maximumQuantity =
      data.data?.employeeOrganisation?.scheme?.product?.maximumQuantity
    const releaseDate =
      data.data?.employeeOrganisation?.scheme?.product?.releaseDate
    const status = snakeToCamel(
      data.data?.employeeOrganisation?.scheme?.product?.status || "default"
    )
    const preBackorderMessage =
      data.data?.employeeOrganisation?.scheme?.product
        ?.preBackOrderProductMessage
    const loading = data.loading

    state = {
      ...defaultValue,
      id,
      title,
      brochureGroupId,
      description,
      price,
      image,
      productImages,
      loading,
      summary,
      techSpecs,
      availableSizes,
      recommendations,
      recommendationsSimilarProducts,
      supplierBanner,
      totalAmount,
      isNhs,
      breadcrumbs,
      brand,
      category,
      variant,
      sku,
      storeName,
      supplierName,
      productTagImage,
      department,
      brochureGroups,
      savings,
      rrp,
      releaseDate,
      status,
      preBackorderMessage,
      reachedMaximumQuantity,
      maximumQuantity,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
