import { Grid, Container, styled, css } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { useCallback, useEffect, useState } from "react"
import Card from "./Card"
import { PayrollPayBenefit } from "../../graphqGenaretedTypes"
import Slider from "react-slick"
import { carouselDotStyling } from "../portal-homepage/shared/SharedStyles"
import { useTranslation } from "react-i18next"
import disableCarouselAriaHidden from "../../utils/disableCarouselAriaHidden"
import { Settings } from "react-slick"

export type BenefitsDataProps = {
  benefitCardData: PayrollPayBenefit[]
}

const benefitCardCarouselGridId = "benefitCardCarouselGrid"

const BenefitCardCarouselGrid = ({ benefitCardData }: BenefitsDataProps) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [activeChild, setActiveChild] = useState(0)
  const { t, ready } = useTranslation("portalHomepage")

  function newArrayData(arr: PayrollPayBenefit[], len: number) {
    const newArrayDataList: PayrollPayBenefit[][] = []
    let i = 0
    const n = arr.length

    while (i < n && i < 48) {
      newArrayDataList.push(arr.slice(i, (i += len)))
    }

    return newArrayDataList
  }

  const result = newArrayData(benefitCardData, 4)

  const changeChild = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        setActiveChild((a) => (a - 1 < 0 ? result.length - 1 : a - 1))
      } else if (e.key === "ArrowRight") {
        setActiveChild((a) => (a + 1 > result.length - 1 ? 0 : a + 1))
      }
    },
    [result]
  )

  useEffect(() => {
    disableCarouselAriaHidden(benefitCardCarouselGridId)
    document.addEventListener("keydown", changeChild)
    return function cleanup() {
      document.removeEventListener("keydown", changeChild)
    }
  })
  const HandleCarosel = () => {
    return (
      <StyledSlider
        initialSlide={activeChild}
        autoplay={true}
        arrows={false}
        dots={true}
        slidesToShow={4}
        slidesToScroll={1}
        speed={500}
        autoplaySpeed={10000}
        afterChange={() => disableCarouselAriaHidden(benefitCardCarouselGridId)}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {result.map((data, index) => {
          return (
            <div key={index}>
              <Grid
                container
                columns={{ xs: 4 }}
                style={{
                  paddingTop: "1.25rem",
                  paddingLeft: "0rem !important",
                  paddingRight: "0rem !important",
                  height: "384px",
                }}
              >
                {data.map((item, index) => {
                  return (
                    <Grid
                      item
                      xs={2}
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: "0rem",
                      }}
                    >
                      <Card cardItem={item} />
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          )
        })}
      </StyledSlider>
    )
  }

  if (!ready) {
    return null
  }

  return (
    <>
      {isDesktop ? (
        <Container
          className="scroll-to-container"
          id="explore_your_benefits"
          sx={{
            maxWidth: "1108px !important",
            width: "93%",
            margin: "0 auto",
            paddingLeft: "0rem !important",
            paddingRight: "0rem !important",
          }}
          data-cy="explore-your-benefits-container"
        >
          <MainText
            style={{
              paddingBottom: "2rem",
            }}
          >
            <h3>{t("exploreYourBenefit.mainHeader")}</h3>
          </MainText>
          <Grid container>
            <Grid container item md={12} lg={12} rowGap={{ md: 6, lg: 6 }}>
              {benefitCardData.map((item, index) => {
                return (
                  <Grid item xs={2} md={2} lg={2} key={index}>
                    {index <= 47 && <Card cardItem={item} />}
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Container>
      ) : (
        <>
          <MainContainer
            className="scroll-to-container"
            id="explore_your_benefits"
          >
            <Grid container sx={{ paddingLeft: "0rem", paddingRight: "0rem" }}>
              <Grid
                item
                xs={11}
                sm={7.2}
                sx={{ margin: "0 auto", paddingLeft: "0rem" }}
              >
                <MainText
                  style={{
                    paddingBottom: isDesktop ? "2rem" : "",
                  }}
                >
                  <h3 style={{ paddingLeft: "0.3rem" }}>
                    {t("exploreYourBenefit.mainHeader")}{" "}
                  </h3>
                </MainText>
                <div id={benefitCardCarouselGridId}>{HandleCarosel()}</div>
              </Grid>
            </Grid>
          </MainContainer>
        </>
      )}
    </>
  )
}

export default BenefitCardCarouselGrid
const MainContainer = styled(Container)`
  ${({ theme }) => css`
    && {
      max-width: 375px;
      width: 100%;
      padding: 0rem;
      @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
        max-width: 800px;
        width: 100%;
      }
    }
  `}
`

export const MainText = styled("div")`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: 1.5rem;
    font-family: 500;
    @media screen and (min-width: ${theme.breakpoints.values.sm}px) {
      padding-left: 2rem;
    }
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding-left: 0rem;
    }
    @media screen and (min-width: ${theme.breakpoints.values.lg}px) {
      padding-left: 0rem;
    }
  `}
`
export const StyledSlider = styled(Slider as React.ComponentType<Settings>)`
  ${({ theme }) => carouselDotStyling(theme)};
`
