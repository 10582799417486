import InputAdornment from "@mui/material/InputAdornment"
import { useEffect, useState } from "react"
import {
  Button,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@mui/material"
import { useParams, useSearchParams } from "react-router-dom"
import updatedSearchParams from "../../../utils/updatedSearchParams"
import onEnter from "../../../utils/onEnter"
import { gtmStoreFilter } from "../../shared/gtm-events/CustomGtmEvents"

type BrochureGroup = { id: number; term: number }

export default function PricePerMonth({
  brochureGroups,
}: {
  brochureGroups: BrochureGroup[]
}) {
  const [searchParams, setSearchParams] = useSearchParams()

  const [min, setMin] = useState(searchParams.get("min")?.substr(1) || "")
  const [max, setMax] = useState(searchParams.get("max")?.substr(1) || "")

  const [originalMin, setOriginalMin] = useState(searchParams.get("min"))
  const [originalMax, setOriginalMax] = useState(searchParams.get("max"))
  const params = useParams()
  const store = params.schemeType
  // TODO - fix eslint warnings/errors
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (originalMin !== searchParams.get("min")) {
      setMin(searchParams.get("min")?.substr(1) || "")
      setOriginalMin(searchParams.get("min"))
    }
    if (originalMax !== searchParams.get("max")) {
      setMax(searchParams.get("max")?.substr(1) || "")
      setOriginalMax(searchParams.get("max"))
    }
  })

  const handleGo = () => {
    if (isValid()) {
      setSearchParams(
        updatedSearchParams(searchParams, [
          { paramName: "min", value: min ? "£" + min : [] },
          { paramName: "max", value: max ? "£" + max : [] },
        ])
      )
      ;(min || max) &&
        gtmStoreFilter({
          filterType: "Price Per Month",
          selectedOption: `£${min ? min : 0} to £${max ? max : 0}`,
          store: store,
        })
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams(
      updatedSearchParams(searchParams, [
        { paramName: "bg", value: (event.target as HTMLInputElement).value },
      ])
    )
  }

  const handleReset = () => {
    setMax("")
    setMin("")
    setSearchParams(updatedSearchParams(searchParams, [], ["min", "max"]))
  }

  const isValid = () => {
    const floatMin = parseFloat(min)
    const floatMax = parseFloat(max)

    if (!isNaN(floatMax) && !isNaN(floatMin)) {
      return parseFloat(min) < parseFloat(max)
    }
    return true
  }

  const selected_brochure_group = (brochureGroups: BrochureGroup[]) => {
    return (
      brochureGroups.filter((bg) => bg.term < 36).slice(-1)[0].id ||
      brochureGroups.slice(-1)[0].id
    )
  }

  return (
    <>
      <div className="price-per-month">
        <div>
          <div className="price-per-month__inputs content-center">
            <TextField
              value={min}
              onChange={(event) => {
                if (/^\d*\.?\d*$/.test(event.target.value)) {
                  setMin(event.target.value)
                }
              }}
              error={!isValid()}
              onKeyDown={(event) => onEnter(event, () => handleGo())}
              InputProps={{
                sx: {
                  paddingLeft: "0.3rem",
                },
                className: "hidden-arrows",
                inputProps: {
                  className: "hidden-arrows",
                  "aria-label": "from",
                  sx: (theme: Theme) => ({
                    paddingRight: "0.3rem",
                    color: theme.palette.primary.main,
                    width: "100%",
                  }),
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      marginRight: "0.25rem",
                      color: (theme) => theme.palette.primary.main,
                    }}
                    disableTypography
                  >
                    £
                  </InputAdornment>
                ),
              }}
            />
            <div className="price-per-month__separator">to</div>
            <TextField
              error={!isValid()}
              value={max}
              onChange={(event) => {
                if (/^\d*\.?\d*$/.test(event.target.value)) {
                  setMax(event.target.value)
                }
              }}
              onKeyDown={(event) => onEnter(event, () => handleGo())}
              InputProps={{
                sx: {
                  paddingLeft: "0.3rem",
                },
                className: "hidden-arrows",
                inputProps: {
                  className: "hidden-arrows",
                  "aria-label": "to",
                  sx: (theme: Theme) => ({
                    paddingRight: "0.3rem",
                    color: theme.palette.primary.main,
                    width: "100%",
                  }),
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      marginRight: "0.25rem",
                      color: (theme) => theme.palette.primary.main,
                    }}
                    disableTypography
                  >
                    £
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {!isValid() && (
            <div className="price-per-month__error-text">
              'Max' price has to be higher than 'Min' price
            </div>
          )}
        </div>
        <div className="price-per-month__buttons">
          <Button
            variant="gradient"
            sx={{ padding: "0.625rem 0.9rem", margin: 0 }}
            onClick={() => handleGo()}
            disabled={!isValid()}
          >
            Go
          </Button>
          <Button
            sx={{ fontSize: "0.75rem" }}
            color="secondary"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>
      </div>
      <FormLabel id="monthly-term" className="d-none">
        Montly Term
      </FormLabel>
      {brochureGroups.length > 1 && (
        <RadioGroup
          aria-labelledby="monthly-term"
          value={
            searchParams.get("bg") || selected_brochure_group(brochureGroups)
          }
          onChange={handleChange}
        >
          {brochureGroups.map((brochureGroup) => (
            <FormControlLabel
              value={brochureGroup.id}
              control={<Radio />}
              label={brochureGroup.term + " Month Term"}
            />
          ))}
        </RadioGroup>
      )}
    </>
  )
}
