import { Container, Grid, css, styled, useTheme } from "@mui/material"
import { SavingsCalculatorResult } from "../../../graphqGenaretedTypes"
import useIsMobileView from "../../../utils/useIsMobileView"
import InfoTooltip from "../../shared/InfoTooltip"
import { useParams } from "react-router-dom"
import { END_OF_AGREEMENT_FEE_TOOLTIP } from "../../../data/constants"
import { useTranslation } from "react-i18next"

const costSaved = (calculatedResult: SavingsCalculatorResult) => {
  if (
    calculatedResult &&
    calculatedResult.costOfQuote &&
    calculatedResult.totalCost
  ) {
    const costOfQuote = Number(calculatedResult.costOfQuote)
    const totalCost = Number(calculatedResult.totalCost)
    return (costOfQuote - totalCost).toFixed(2)
  } else {
    return "0.00"
  }
}

export type SavingCalculatorCardType = {
  calculatedResult: SavingsCalculatorResult | undefined
  quoteType: string | undefined
  isQuotePage?: boolean
}

const SavingCalculatorCard = ({
  calculatedResult,
  quoteType = "",
  isQuotePage,
}: SavingCalculatorCardType) => {
  const isDesktop = !useIsMobileView()
  const { schemeType } = useParams()
  const isNetBikeShop = schemeType === "bike_shop"
  const theme = useTheme()
  const { t, ready } = useTranslation("cycleToWork")

  const netBikeInfo = (roundingError: string) => {
    if (roundingError) {
      return `${t("calculator.netBikeInfo.firstText")}${roundingError} ${t(
        "calculator.netBikeInfo.secondText"
      )}`
    } else {
      return t("calculator.netBikeInfo.secondCase")
    }
  }

  const MonthlyCostInfo = (quoteType: string) => {
    switch (quoteType) {
      case "NHS":
        return t("calculator.monthlyCostInfo.case1")
      case "Bronze NHS":
      case "Gold NHS":
      case "Platinum NHS":
        return t("calculator.monthlyCostInfo.case2")
      case "Council GPS":
        return t("calculator.monthlyCostInfo.case3")
      case "Non NHS":
        return t("calculator.monthlyCostInfo.case4")
      case "Bronze Non NHS":
      case "Gold Non NHS":
      case "Platinum Non NHS":
        return t("calculator.monthlyCostInfo.case5")
      case "NET":
        return t("calculator.monthlyCostInfo.case6")
      default:
        return ""
    }
  }

  if (!ready) {
    return null
  }
  return (
    <SavingCalculatorCardContainer isNetBikeShop={isNetBikeShop}>
      <MainGrid
        sx={{
          paddingRight: "0px !important",
          height: `${isNetBikeShop && "80% !important"}`,
        }}
        container
      >
        <GridContent
          item
          xs={6}
          sx={{
            fontWeight: "bold",
            color: "#302557",
            transform: isDesktop ? "translate(0, 15%)" : "",
            paddingBottom: "5px",
          }}
        >
          {isQuotePage
            ? t("calculator.firstRow.costOfQuote")
            : t("calculator.firstRow.totalBikePrice")}
        </GridContent>
        <CalculateValue
          item
          xs={6}
          sx={{
            paddingRight: isDesktop ? "52px" : "40px",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#92237F",
            paddingBottom: "5px",
          }}
          data-cy={isQuotePage ? "cost-of-quote" : "total-bike-price"}
        >
          <>
            £
            {calculatedResult &&
              Number(calculatedResult.costOfQuote).toFixed(2)}
          </>
        </CalculateValue>

        <Grid item xs={12} sx={{ border: "1px solid #eae9ee" }}></Grid>

        {isNetBikeShop ? (
          <>
            <GridContent
              item
              xs={7}
              sx={{
                paddingLeft: isDesktop ? "41px" : "12px",
                fontSize: isDesktop ? "1rem" : "0.875rem !important",
              }}
            >
              <Grid
                style={{
                  color: `${theme.palette.primary.main}`,
                }}
              >
                {t("calculator.firstRow.monthlyCost")}
              </Grid>
            </GridContent>
            <CalculateValue
              item
              xs={5}
              sx={{
                fontSize: isDesktop ? "16px" : "20px",
                color: "#92237F",
                paddingBottom: "13px",
              }}
            >
              <div>
                <span>
                  {calculatedResult?.salarySacrifice &&
                    `£${parseFloat(calculatedResult.salarySacrifice)?.toFixed(
                      2
                    )}`}
                </span>

                <span style={{ paddingRight: !isDesktop ? "1px" : "10px" }}>
                  <InfoTooltip
                    content={netBikeInfo(
                      calculatedResult?.roundingCharges ?? ""
                    )}
                  />
                </span>
              </div>
            </CalculateValue>
          </>
        ) : (
          <>
            <GridContent
              item
              sx={{
                paddingBottom: "0px !important",
                fontSize: isDesktop ? "1rem" : "0.875rem !important",
              }}
              xs={8}
            >
              {t("calculator.secondaryPopUp.card.salarySacrifice")}
              {isQuotePage && <Superscript>1</Superscript>}:
              <Grid item xs={12} sx={{ fontSize: "10px" }}>
                {t("calculator.secondaryPopUp.card.grossPay")}
              </Grid>
            </GridContent>
            <CalculateValue
              item
              xs={4}
              sx={{
                paddingRight: isDesktop ? "52px" : "40px",
                fontSize: isDesktop ? "16px" : "20px",
                color: "#92237F",
                paddingBottom: "10px",
              }}
              data-cy="salary-sacrifice"
            >
              {calculatedResult?.salarySacrifice &&
                `£${parseFloat(calculatedResult.salarySacrifice)?.toFixed(2)}`}
            </CalculateValue>
          </>
        )}

        {!isNetBikeShop && (
          <>
            <GridContent
              item
              xs={12}
              sx={{
                paddingLeft: isDesktop ? "41px" : "12px",
                fontWeight: "bold",
                fontSize: "16px",
                color: `${theme.palette.primary.main}`,
              }}
            >
              <div>
                {t("calculator.secondaryPopUp.card.whatThisMeansForYou")}
              </div>
            </GridContent>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              <div></div>
            </Grid>
            <GridContent
              item
              xs={7}
              sx={{
                paddingLeft: isDesktop ? "41px" : "12px",
                fontSize: isDesktop ? "1rem" : "0.875rem !important",
              }}
            >
              <Grid
                style={{
                  color: `${theme.palette.primary.main}`,
                }}
              >
                {t("calculator.secondaryPopUp.card.step4")}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  fontSize: "10px",
                  color: `${theme.palette.primary.main}`,
                }}
              >
                {t("calculator.secondaryPopUp.card.fromYourPocket")}
              </Grid>
            </GridContent>
            <CalculateValue
              item
              xs={5}
              sx={{
                fontSize: isDesktop ? "16px" : "20px",
                color: "#92237F",
                paddingBottom: "13px",
              }}
              data-cy="monthly-cost-to-you"
            >
              <div>
                <span>
                  {calculatedResult?.monthlyCostToYou &&
                    `£${parseFloat(calculatedResult.monthlyCostToYou)?.toFixed(
                      2
                    )}`}
                </span>

                <span style={{ paddingRight: !isDesktop ? "1px" : "10px" }}>
                  <InfoTooltip content={MonthlyCostInfo(quoteType)} />
                </span>
              </div>
            </CalculateValue>

            <GridContent
              item
              xs={6}
              sx={{
                paddingLeft: isDesktop ? "41px" : "12px",
                fontSize: isDesktop ? "1rem" : "0.875rem !important",
              }}
            >
              <div
                style={{
                  color: `${theme.palette.primary.main}`,
                }}
              >
                {t("calculator.secondaryPopUp.card.endOfAgreementFee")}
              </div>
              <Grid
                item
                xs={12}
                sx={{
                  fontSize: "10px",
                  color: `${theme.palette.primary.main}`,
                }}
              >
                {t("calculator.secondaryPopUp.card.definedByYourEmployer")}
              </Grid>
            </GridContent>
            <CalculateValue
              item
              xs={6}
              sx={{
                fontSize: isDesktop ? "16px" : "20px",
                color: "#92237F",
                paddingBottom: "15px",
              }}
              data-cy="end-of-agreement-fee"
            >
              <div>
                <span>
                  {calculatedResult?.endOfAgreementFee &&
                    `£${parseFloat(calculatedResult.endOfAgreementFee)?.toFixed(
                      2
                    )}`}
                </span>
                <span style={{ paddingRight: !isDesktop ? "1px" : "10px" }}>
                  <InfoTooltip content={END_OF_AGREEMENT_FEE_TOOLTIP} />
                </span>
              </div>
            </CalculateValue>

            <GridContent
              item
              xs={6}
              sx={{
                paddingLeft: isDesktop ? "41px" : "12px",
                paddingBottom: "14px",
                fontSize: isDesktop ? "1rem" : "0.875rem !important",
              }}
            >
              <div
                style={{
                  color: `${theme.palette.primary.main}`,
                }}
              >
                {t("calculator.secondaryPopUp.card.totalCost")}
                {isQuotePage && <Superscript>2</Superscript>}:
              </div>
            </GridContent>
            <Grid
              item
              xs={6}
              sx={{
                textAlign: "right",
                fontSize: isDesktop ? "24px" : "20px",
                fontWeight: "bold",
                color: "#92237F",
                paddingRight: !isDesktop ? "40px" : "50px",
                paddingBottom: "12px",
              }}
              data-cy="total-cost"
            >
              <div>
                {calculatedResult?.totalCost &&
                  `£${parseFloat(calculatedResult.totalCost)?.toFixed(2)}`}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                height: "47px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#488200",
                borderBottomLeftRadius: "16px",
                borderBottomRightRadius: "16px",
                color: "#FFFFFF",
              }}
            >
              <div style={{ paddingRight: "6px" }}>
                {t("calculator.secondaryPopUp.card.youCouldSave")}
              </div>
              <div
                style={{
                  fontSize: isDesktop ? "32px" : "20px",
                  marginLeft: "1px !important",
                }}
                data-cy="you-could-save"
              >
                £{calculatedResult && costSaved(calculatedResult)}
              </div>
            </Grid>
          </>
        )}
      </MainGrid>
    </SavingCalculatorCardContainer>
  )
}

export default SavingCalculatorCard

const SavingCalculatorCardContainer: React.FC<
  React.PropsWithChildren<{ isNetBikeShop: boolean }>
> = ({ isNetBikeShop, children }) => {
  if (isNetBikeShop) {
    return <NetBikeCardContainer>{children}</NetBikeCardContainer>
  }
  return <CardContainer>{children}</CardContainer>
}

const CardContainer = styled(Container)`
  ${({ theme }) => css`
    &&&& {
      padding: 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 495px;
      max-height: 348px;
      height: 100%;
      background-color: white;
      border-radius: 16px;
      margin-bottom: 15px;
      margin-top: 29px;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 322px;
        max-height: 364px;
        height: 364px;
      }
    }
  `}
`

const NetBikeCardContainer = styled(CardContainer)`
  &&&& {
    height: 148px;
  }
`

const MainGrid = styled(Grid)`
  ${({ theme }) => css`
    &&&& {
      padding: 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 495px;
      max-height: 348px;
      height: 100%;
      border-radius: 16px;
      margin-bottom: 15px;
      margin-top: 29px;
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        width: 322px;
        max-height: 364px;
        height: 364px;
      }
    }
  `}
`

export const GridContent = styled(Grid)`
  ${({ theme }) => css`
    &&& {
      padding-left: 41px;
      font-size: 16px;
      color: ${theme.palette.primary.main};
      @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
        padding-left: 12px;
      }
    }
  `}
`
export const CalculateValue = styled(Grid)`
  && {
    text-align: end;
  }
`

const Superscript = styled("sup")`
  font-size: 0.625rem;
`
