import { useFormikContext } from "formik"
import { SxProps, TextField, TextFieldProps } from "@mui/material"
import { CSSProperties } from "@mui/styles"
import FormikValueType from "./formikValueType"
import getErrorMessage from "./getErrorMessage"

type TitleProps = {
  props: TextFieldProps
  children?: React.ReactNode
  style?: CSSProperties // inline style props passed to TextField
  sx?: SxProps
  inlineTextFieldProps?: TextFieldProps
}

const GenericSelect = <T extends FormikValueType>({
  props,
  children,
  style,
  sx,
  inlineTextFieldProps,
}: TitleProps) => {
  const { id } = props
  const formik = useFormikContext<T>()
  if (id) {
    const touched = Object.keys(formik.touched).includes(id)
    const error = Object.keys(formik.errors).includes(id)
    const invalid = touched && error ? true : false

    return (
      <div>
        <TextField
          {...props}
          value={formik.values[id]}
          style={style && style}
          sx={sx && sx}
          error={invalid}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          SelectProps={{
            MenuProps: {
              BackdropProps: {
                invisible: true,
              },
              disableScrollLock: true,
              MenuListProps: {
                "aria-label": "select an option", // Directly adding aria-label here
              },
            },
          }}
          helperText={invalid && getErrorMessage(formik.errors[id])}
          // inputProps and InputLabelProps are needed for WAVE
          inputProps={{
            id,
          }}
          InputLabelProps={{
            htmlFor: id,
          }}
          // inlineTextFieldProps is to override everything similar to inline styling manner
          {...inlineTextFieldProps}
        >
          {children}
        </TextField>
      </div>
    )
  }
  return <></>
}

export default GenericSelect
