import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import FooterIcons from "./FooterIcons"
import { Link } from "@mui/material"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { useParams } from "react-router-dom"
import { useBuildAwsImageUrl } from "../../../../utils/useBuildAwsImageUrl"

const FooterLogoSection = ({
  logo,
  isLockBenefit = false,
  isValidBenefitType = false,
}: {
  logo?: string
  isLockBenefit?: boolean
  isValidBenefitType?: boolean
}) => {
  const isMobile = useIsMobileView()
  const params = useParams()

  let homeLink
  const isEligibleBenefit = isLockBenefit && isValidBenefitType

  if (isEligibleBenefit) {
    if (params.schemeType) {
      homeLink = `/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/landing`
    } else if (location.href.includes("lifestyle_savings")) {
      homeLink = `/organisations/${params.organisationId}/employee/lifestyle_savings/landing`
    } else {
      homeLink = `/organisations/${
        params.organisationId
      }/employee/benefits/${location.href.split("/").pop()}`
    }
  } else {
    homeLink = "/"
  }

  const defaultLogo = useBuildAwsImageUrl("en-GB", "logo.png")

  return (
    <Grid
      item
      xs={12}
      md={2}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
      data-cy="footer-logo"
    >
      {isMobile ? (
        <>
          <Grid
            item
            xs={4}
            sx={{
              padding: 1,
            }}
          >
            <Link href={homeLink}>
              <Box
                component="img"
                sx={{
                  width: "5.456rem",
                  maxWidth: "6.25rem",
                }}
                alt="Vivup-logo"
                src={logo || defaultLogo}
              ></Box>
            </Link>
          </Grid>
          <Grid
            item
            xs={8}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "row",
              padding: 1,
            }}
          >
            <FooterIcons />
          </Grid>
        </>
      ) : (
        <Link href={homeLink}>
          <Box
            component="img"
            sx={{
              width: "7.625rem",
              maxWidth: "7.625rem",
            }}
            alt="Vivup-logo"
            src={logo || defaultLogo}
          ></Box>
        </Link>
      )}
    </Grid>
  )
}

export default FooterLogoSection
